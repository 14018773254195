/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';

// import validate from 'validate.js';
// import { makeStyles } from '@mui/styles';
import {Button, Link, Stack, Typography} from '@mui/material';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AUTH_ROUTES} from '../../constants/SiteRoutes';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {Link as RouterLink, useParams} from 'react-router-dom';
// import components
import Header from '../../components/Header';
import SnackBarWithTimer from '../../components/Common/SnackBarWithTimer';
import {fetchRecord, postRecord} from "../../services/Axios";
import {AUTHENTICATION} from "../../constants/ApiEndPoint";
import {useNavigate} from "react-router";
import {PasswordValidation} from '../../constants';

function ResetPassword() {

  const theme = useTheme();
  const {code} = useParams();
    const navigate = useNavigate();
  const [valid, setValid] = useState(null);
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState({});

  async function getVerificationStatus() {
    setLoading(true);
    const response = await fetchRecord(AUTHENTICATION.VERIFY_RESET_PASSWORD_TOKEN, code);
    if (response && response.status === 200) {
      setValid(true);
    } else {
      setValid(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    getVerificationStatus().then();
  }, [code]);


  const handleSubmit = async () => {
    const body = {newPassword: password};
    setLoading(true);
    const response = await postRecord(
      AUTHENTICATION.RESET_PASSWORD + '/' + code,
      body
    );
    setMessage({type: 'error', message: response.message});
    setShowSnackbar(true);
    setLoading(false);
    if (response && response.status === 200) {
      setValid(true);
      //history.push(AUTH_ROUTES.RESET_PASSWORD_SUCCESS);
        setMessage({type: 'success', message: response.message});
        navigate(AUTH_ROUTES.RESET_PASSWORD_SUCCESS);
    } else if (response.status === 422) {
    } else {
      setValid(false);
    }
  }

    ValidatorForm.addValidationRule('isPasswordMatch', value => {
        if (value !== password) {
            return false;
        }
        return true;
    });

  return (
    <>
      {loading && (
        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={loading}>
          <CircularProgress color="inherit"/>
        </Backdrop>
      )}
      {showSnackbar && (
        <SnackBarWithTimer
          open={showSnackbar}
          timer={6000}
          message={message}
          onClose={() => setShowSnackbar(false)}
        />
      )}
      <Header type="general"/>

      <ValidatorForm onSubmit={handleSubmit}>
        <Stack direction="column" spacing={3} bgcolor={theme.palette.background.paper} px={2} pt={2.5} pb={4}>
          <Typography variant="h3" component={'div'} textAlign="center">
            Reset Password
          </Typography>
          <Stack direction="column" spacing={2.6}>
            {valid === false &&
              <Stack direction="column" spacing={0.8} textAlign="center">
                <Typography
                  gutterBottom
                  //component="h2"
                  variant="h4"
                  color={'error'}
                >
                  Password reset token has expired. Please request a new token.
                </Typography>

                <Stack direction="column" spacing={0} textAlign="center">
                  <Link component={RouterLink} to={AUTH_ROUTES.FORGOT_PASSWORD} variant="body2">
                    Request New Token
                  </Link>
                  <Link component={RouterLink} to={AUTH_ROUTES.LOGIN} variant="body2">
                    Wait, I remember by password
                  </Link>
                </Stack>
              </Stack>
            }
            {valid === true &&
              <>
                <TextValidator
                  label="Password"
                  onChange={e => setPassword(e.target.value)}
                  placeholder="Enter Password"
                  fullWidth
                  name="password"
                  type="password"
                  margin="normal"
                  size="medium"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={password}
                  validators={PasswordValidation.validators}
                  errorMessages={PasswordValidation.errorMessages}
                  variant="outlined"
                />

                <TextValidator
                  label="Confirm Password"
                  onChange={e => setConfirmPassword(e.target.value)}
                  placeholder="Enter Confirm Password"
                  helperText=""
                  fullWidth
                  name="confirmPassword"
                  type="password"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={confirmPassword}
                  validators={['required', 'isPasswordMatch']}
                  errorMessages={[
                    'Confirm Password is required.',
                    'Confirm Password does not match.'
                  ]}
                  variant="outlined"
                />
                <Stack direction="column" spacing={0.8}>
                  <Button color="primary" size="large" type="submit" variant="contained" disabled={loading}>
                    Reset Password
                  </Button>
                  <Stack direction="column" spacing={0} textAlign="center">
                    <Link component={RouterLink} to={AUTH_ROUTES.LOGIN} variant="body2">
                      Wait, I remember by password
                    </Link>
                  </Stack>
                </Stack>
              </>
            }

          </Stack>
        </Stack>
      </ValidatorForm>
    </>
  );
}

export default ResetPassword;
