/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import PhoneField from '../../components/Common/PhoneNumber';
import {Button, Link, Stack, Typography} from '@mui/material';
// import components
import Header from '../../components/Header';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {postRecord} from '../../services/Axios';
import {AUTHENTICATION} from '../../constants/ApiEndPoint';
import SnackBarWithTimer from '../../components/Common/SnackBarWithTimer';
import {BOOKING_ROUTES} from '../../constants/SiteRoutes';
import {useLocation, useNavigate} from 'react-router-dom';
import SimpleBackdrop from '../../components/Common/SimpleBackdrop';

function LoginOneTime() {
    const theme = useTheme();
    const formRef = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    let userId = location.state.userId; console.log(userId,'userId')

    const [phoneNumber, setPhoneNumber] = useState('');
    const [showSendCodeScreen, setShowSendCodeScreen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [code, setCode] = useState('');

    useEffect(() => {
        console.log('showSendCodeScreen', showSendCodeScreen);
    }, []);

    const onChange = (value) => {
        setPhoneNumber(value);
    };

    const sendCode = () => {
        formRef.current.isFormValid(false).then((isValid) => {
            if (isValid) {
                setLoading(true);
                callSendCodeApi();
            }
        });
    };

    const callSendCodeApi = async () => {
        console.log('callSendCodeApi');
        let response = await postRecord(AUTHENTICATION.SEND_PHONE_NUMBER_VERIFICATION_CODE, {
            phoneNumber: `+${phoneNumber}`,
        });
        if (response) {
            if (response.status === 200) {
                setShowSendCodeScreen(false);
            } else {
                setMessage({ type: 'error', message: response.message });
                setShowSnackbar(true);
            }
            setLoading(false);
        }
    };

    const handleCodeChange = (event) => {
        setCode(event.target.value);
    };

    const handleSubmit = async () => {
        setLoading(true);
        let response = await postRecord(AUTHENTICATION.VERIFICATION_PHONE_NUMBER_CODE, { code });
        if (response) {
            if (response.status === 200) {
                //await accountLogin(response.data);
                navigate(BOOKING_ROUTES.INDEX);
            } else {
                setMessage({ type: 'error', message: response.message });
                setShowSnackbar(true);
            }
            setLoading(false);
        }
    };

    const getHelperText = () =>{
        return `Please enter the code we just sent to +${phoneNumber}`
    }

    return (
        <React.Fragment>
            {loading && <SimpleBackdrop />}
            {showSnackbar && (
                <SnackBarWithTimer
                    open={showSnackbar}
                    message={message}
                    timer={6000}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <Header type="general" />
            <ValidatorForm onSubmit={handleSubmit} ref={formRef}>
                <Stack direction="column" spacing={3} bgcolor={theme.palette.background.paper} px={2} pt={2.5} pb={4}>
                    <Typography variant="h3" component={'div'} textAlign="center">
                        {showSendCodeScreen ? 'Enter Your Phone Number' : 'Phone Number Verification'}
                    </Typography>
                    {showSendCodeScreen ? (
                        <Stack direction="column" spacing={2}>
                            <Stack direction="column" spacing={0.5}>
                                <PhoneField
                                    // style={{ margin: '8px' }}
                                    // country={'au'}
                                    value={phoneNumber}
                                    onChange={onChange} // passed function receives the phone value
                                    specialLabel="Phone Number"
                                    size="large"
                                />
                                 <Typography variant="caption" component="span">
                                    Used for receiving SMS availability checks as well as booking requests.
                                </Typography>
                            </Stack>

                            <Stack direction="column" spacing={0.5}>
                                <Button color="primary" size="large" onClick={sendCode} variant="contained">
                                    Send Code
                                </Button>
                            </Stack>
                        </Stack>
                    ) : (
                        <Stack direction="column" spacing={2}>
                            <Stack direction="column" spacing={0.5}>
                                <TextValidator
                                    value={code}
                                    onChange={handleCodeChange}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    autoFocus
                                    size="medium"
                                    fullWidth
                                    label="Enter One Time Code"
                                    placeholder="Enter Code"
                                    validators={['required']}
                                    errorMessages={['Please enter the code.']}
                                />
                                <Typography variant="caption" component="span">
                                    {getHelperText()}
                                </Typography>
                            </Stack>

                            <Stack direction="column" spacing={0.5}>
                                <Button color="primary" size="large" type="submit" variant="contained">
                                    Enter Code
                                </Button>
                                <Link href="#" variant="body2" textAlign="center" onClick={callSendCodeApi}>
                                    Resend Code
                                </Link>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </ValidatorForm>
        </React.Fragment>
    );
}

export default LoginOneTime;
