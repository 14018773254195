import {
    Backdrop,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    Stack,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { COMPONENTS } from 'src/constants/ApiEndPoint';
import { fetchRecord } from 'src/services/Axios';
import { getLoggedInUser } from 'src/services/Storage';

function BookingRates(props) {
    const { booking, myContacts } = props;
    const [rateCard, setRateCard] = useState();
    // console.log('foo booking', booking);
    // console.log('foo production', props);
    const user = getLoggedInUser();
    console.log('foo production booking', booking.selectedRateCard);
    // console.log('foo production myContacts', myContacts);
    // console.log('foo production user', user);
    const rateCards = myContacts.find((item) => item.userId === user._id)?.rateCards;
    console.log('foo production rateCards', rateCards);

    useEffect(() => {
        console.log('foundRateCard=========>b', booking);
        const foundRateCard = booking.bookerContactsDetails[0]?.rateCards?.find(
            (item) => item._id == booking.selectedRateCard,
        );
        console.log('foundRateCard=========>b', foundRateCard);
        if (foundRateCard) setRateCard(foundRateCard);
    }, [rateCards]);

    useEffect(() => {
        console.log('foo production selected', rateCard);
    }, [rateCard]);

    const [loading, setLoading] = useState(false);
    const FlexRow = styled('div')(({ theme, ownerState }) => ({
        padding: '9px 9px 4px',
        display: 'grid',
        gridTemplateColumns: 'auto 27% 27%',
        alignItems: 'flex-start',
        gap: '20px',
        '&:not(:last-of-type)': {
            borderBottom: '1px solid #eee',
        },
    }));
    const FlexRow1 = styled('div')(({ theme, ownerState }) => ({
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '2px',
        marginTop: '-2px',
    }));
    // const FringeLayout = styled((props) => <Box {...props} />)`
    //     width: 100%;
    //     display: grid;
    //     grid-template-columns: 50% 50%;
    //     align-items: center;
    //     '&:not(:last-of-type)': {
    //         border-bottom: 1px solid #eee;
    //     }
    // `;

    const FringeLayout = styled(Box)`
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;

        &:not(:last-of-type) {
            border-bottom: 1px solid #eee;
        }
    `;

    const GearsLayout = styled('div')(({ theme, ownerState }) => ({
        width: '730px',
        display: 'grid',
        gridTemplateColumns: '18% 31% 10% 10% 10% auto',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        '&:not(:last-of-type)': {
            borderBottom: '1px solid #eee',
        },
    }));

    const RateItem = ({ title, value, fontSize = '14px', lineHeight = '20px', type = 'text' }) => {
        return (
            <Stack>
                <Typography variant="body1">{title}</Typography>

                <FlexRow1>
                    {type === 'rate' && (
                        <Typography variant="body1" sx={{ mt: '1px' }}>
                            $
                        </Typography>
                    )}

                    {type === 'rate' ? (
                        <Typography variant="h1" sx={{ fontSize: '24px' }}>
                            {new Intl.NumberFormat('en-US').format(value || 0)}
                        </Typography>
                    ) : (
                        <Typography variant="body1">{value || 'N/A'}</Typography>
                    )}
                </FlexRow1>
            </Stack>
        );
    };

    return (
        <Box sx={{ width: '100%' }}>
            {loading && (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <Typography variant="h3" sx={{ padding: '0 16px', mb: '11px', mt: '-3px' }}>
                {rateCard?.rateCardName}
            </Typography>
            <Card variant="outlined" sx={{ borderRadius: '0px', border: 'none', padding: '1px 8px 8px' }}>
                <CardContent>
                    {rateCard?.rates?.map((item) => (
                        <FlexRow>
                            <RateItem
                                title={'TASK TYPE'}
                                value={item.taskType.title ? item.taskType.title : item.taskType}
                            />
                            <RateItem title={'RATE'} value={item.rate} type={'rate'} fontSize={'18px'} />
                            <RateItem title={'PER'} value={item.unit} />
                        </FlexRow>
                    ))}
                </CardContent>
            </Card>
            <Typography variant="h3" sx={{ padding: '10px 16px 11px' }}>
                Fringes
            </Typography>
            <Card variant="outlined" sx={{ borderRadius: '0px', border: 'none', mb: '16px' }}>
                <CardContent>
                    <FringeLayout>
                        <Box
                            sx={{
                                padding: '9px 0 6px 17px',
                                borderRight: '1px solid #E6E6E6',
                            }}
                        >
                            <Typography
                                variant="body1"
                                style={{
                                    fontSize: '13px',
                                    color: '#6C6C6C',
                                    fontWeight: 'bold',
                                }}
                            >
                                Overtime
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                padding: '9px 0 6px 16px',
                            }}
                        >
                            <Typography
                                variant="body1"
                                style={{
                                    fontSize: '13px',
                                    color: '#6C6C6C',
                                    fontWeight: 'bold',
                                }}
                            >
                                After
                            </Typography>
                        </Box>
                    </FringeLayout>
                    {rateCard?.fringe?.overtime?.map((item) => (
                        <FringeLayout>
                            <Box
                                sx={{
                                    padding: '6px 0 8px 17px',
                                    borderRight: '1px solid #E6E6E6',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    style={{
                                        fontSize: '13px',
                                        color: '#6C6C6C',
                                    }}
                                >
                                    {item.overTime}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    padding: '6px 0 8px 16px',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    style={{
                                        fontSize: '13px',
                                        color: '#6C6C6C',
                                    }}
                                >
                                    {item.afterUnit}hrs
                                </Typography>
                            </Box>
                        </FringeLayout>
                    ))}
                </CardContent>
            </Card>

            <Card variant="outlined" sx={{ borderRadius: '0px', border: 'none' }}>
                <CardContent>
                    <FringeLayout>
                        <Box
                            sx={{
                                padding: '9px 0 6px 16px',
                                borderRight: '1px solid #E6E6E6',
                            }}
                        >
                            <Typography
                                variant="body1"
                                style={{
                                    fontSize: '13px',
                                    color: '#6C6C6C',
                                    fontWeight: 'bold',
                                }}
                            >
                                Night Loading
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                padding: '9px 0 6px 16px',
                            }}
                        >
                            <Typography
                                variant="body1"
                                style={{
                                    fontSize: '13px',
                                    color: '#6C6C6C',
                                    fontWeight: 'bold',
                                }}
                            >
                                After
                            </Typography>
                        </Box>
                    </FringeLayout>
                    {rateCard?.fringe?.nightLoading?.map((item) => (
                        <FringeLayout>
                            <Box
                                sx={{
                                    padding: '6px 0 8px 16px',
                                    borderRight: '1px solid #E6E6E6',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    style={{
                                        fontSize: '13px',
                                        color: '#6C6C6C',
                                    }}
                                >
                                    {item.nightLoading}%
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    padding: '6px 0 8px 16px',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    style={{
                                        fontSize: '13px',
                                        color: '#6C6C6C',
                                    }}
                                >
                                    {item.afterUnit}
                                    {item.afterUnit < 3 ? 'am' : 'pm'}
                                </Typography>
                            </Box>
                        </FringeLayout>
                    ))}
                </CardContent>
            </Card>

            <Typography variant="h3" sx={{ padding: '10px 16px 11px' }}>
                Gears
            </Typography>
            <Card variant="outlined" sx={{ borderRadius: '0px', border: 'none', overflow: 'auto' }}>
                <CardContent style={{ overflow: 'auto' }}>
                    <GearsLayout>
                        <Typography
                            variant="subtitle2"
                            style={{
                                borderRight: '1px solid #E6E6E6',
                                padding: '7px 16px',
                                fontSize: '13px',
                                color: '#6C6C6C',
                            }}
                        >
                            Item
                        </Typography>

                        <Typography
                            variant="subtitle2"
                            style={{
                                borderRight: '1px solid #E6E6E6',
                                padding: '7px 16px',
                                fontSize: '13px',
                                color: '#6C6C6C',
                            }}
                        >
                            Description
                        </Typography>

                        <Typography
                            variant="subtitle2"
                            style={{
                                padding: '7px 16px',
                                borderRight: '1px solid #E6E6E6',
                                fontSize: '13px',
                                color: '#6C6C6C',
                            }}
                        >
                            Rate
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            style={{
                                padding: '7px 16px',
                                borderRight: '1px solid #E6E6E6',
                                fontSize: '13px',
                                color: '#6C6C6C',
                            }}
                        >
                            Value
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            style={{
                                padding: '7px 12px',
                                borderRight: '1px solid #E6E6E6',
                                fontSize: '13px',
                                color: '#6C6C6C',
                            }}
                        >
                            Unit
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            style={{
                                padding: '7px 16px',
                                fontSize: '13px',
                                color: '#6C6C6C',
                            }}
                        >
                            Gear inclusive
                        </Typography>
                    </GearsLayout>
                    {rateCard?.gear?.map((item) => (
                        <GearsLayout>
                            <Typography
                                variant="subtitle2"
                                style={{
                                    borderRight: '1px solid #E6E6E6',
                                    padding: '7px 16px',
                                    fontSize: '13px',
                                    color: '#6C6C6C',
                                }}
                            >
                                {item.item}
                            </Typography>

                            <Typography
                                variant="subtitle2"
                                style={{
                                    borderRight: '1px solid #E6E6E6',
                                    padding: '7px 16px',
                                    fontSize: '13px',
                                    color: '#6C6C6C',
                                }}
                            >
                                {item.description}
                            </Typography>

                            <Typography
                                variant="subtitle2"
                                style={{
                                    padding: '7px 16px',
                                    borderRight: '1px solid #E6E6E6',
                                    fontSize: '13px',
                                    color: '#6C6C6C',
                                }}
                            >
                                ${item.rate}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                style={{
                                    padding: '7px 16px',
                                    borderRight: '1px solid #E6E6E6',
                                    fontSize: '13px',
                                    color: '#6C6C6C',
                                }}
                            >
                                {item.unit}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                style={{
                                    padding: '7px 16px',
                                    borderRight: '1px solid #E6E6E6',
                                    fontSize: '13px',
                                    color: '#6C6C6C',
                                }}
                            >
                                {item.value}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                style={{
                                    padding: '7px 16px',
                                    fontSize: '13px',
                                    color: '#6C6C6C',
                                }}
                            >
                                {item.included ? 'YES' : 'NO'}
                            </Typography>
                        </GearsLayout>
                    ))}
                </CardContent>
            </Card>
        </Box>
    );
}

export default BookingRates;
