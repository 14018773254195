import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useLocalStyles = makeStyles((theme) => ({
    // root: {
    //     width: '100%',
    //     maxWidth: 500,
    // },
    // cartHeader: {
    //     padding: theme.spacing(2, 0),
    // },
    // option: {
    //     display: 'flex',
    //     alignItems: 'flex-start',
    //     //padding: theme.spacing(2),
    //     '& + &': {
    //         marginTop: '8px',
    //     },
    // },
    // headingWrap: {
    //     color: '#6e6969 !important',
    //     fontSize: '11px !important',
    //     fontWeight: '400 !important',
    //     // padding: '10px !important',
    //     // width: '85px'
    // },
    // titleWrap: {
    //     padding: '16px 16px 16px 10px',
    //     color: '#6e6969 !important',
    //     fontSize: '11px !important',
    //     fontWeight: '400 !important',
    // },
    // tableWrap: {
    //     height: '400px',
    //     '& .MuiTable-root': {
    //         '& .MuiTableRow-root': {
    //             display: 'grid',
    //             gridTemplateColumns: '26% 12% 15% 20% auto',
    //             width: '100%',
    //         },
    //     },
    // },
    // typography: {
    //     fontSize: '11px',
    // },
}));

const BookingEquipmentDetails = (props) => {
    const classes = useLocalStyles();

    const { equipments } = props;
    return (
        <TableContainer component={Paper} className={classes.tableWrap}>
            <Table aria-label="spanning table">
                {/* <TableHead>
                    <TableRow>
                        <TableCell className={classes.titleWrap}>ITEM</TableCell>
                        <TableCell className={classes.headingWrap}>UNIT</TableCell>
                        <TableCell align="left" className={classes.headingWrap}>
                            RATE
                        </TableCell>
                        <TableCell align="left" className={classes.headingWrap}>
                            QTY
                        </TableCell>
                        <TableCell align="left" className={classes.headingWrap}>
                            TOTAL
                        </TableCell>
                    </TableRow>
                </TableHead> */}
                <TableBody>
                    {equipments.length > 0 ? (
                        equipments.map((item, index) => (
                            <TableRow>
                                <TableCell>
                                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                                        ITEM
                                    </Typography>
                                    <Typography>{item.equipment}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                                        UNIT
                                    </Typography>
                                    <Typography>{item.unit}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                                        RATE
                                    </Typography>
                                    <Typography>${item.rate}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                                        QTY
                                    </Typography>
                                    <Typography>{item.qty}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                                        TOTAL
                                    </Typography>
                                    <Typography>${item.total}</Typography>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colspan={5} sx={{ padding: '1rem!important', textAlign: 'center' }}>
                                No data available
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BookingEquipmentDetails;
