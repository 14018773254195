import {Chip, colors} from '@mui/material';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {allStyles, paddingNormalStyle, showSmallLabelStyle} from './LabelProps';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 0,
        flexShrink: 0,
        borderRadius: 2,
        lineHeight: '1em',
        fontSize: '11px',
        height: 20,
        minWidth: 20,
        whiteSpace: 'nowrap',
        padding: '14px 15px',
        //padding: theme.spacing(0.5, 1)
    },
    rounded: {
        borderRadius: 50,
        //padding: '14px 15px'
    },
}));

function Label({ className, variants, color, shape, children, style, ...rest }) {
    const classes = useStyles();
    const rootClassName = clsx(
        {
            [classes.root]: true,
            [classes.rounded]: shape === 'rounded',
        },
        className,
    );
    const { showSmallLabel, paddingNormal } = { ...rest };
    var finalStyle = { ...style, ...allStyles[`${variants}`] };

    if (showSmallLabel) {
        finalStyle = { ...finalStyle, ...showSmallLabelStyle };
    }

    if (paddingNormal) {
        finalStyle = { ...finalStyle, ...paddingNormalStyle };
    }

    return <Chip label={children} size={showSmallLabel ? 'small' : 'medium'} color={variants} />;
}

Label.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.string,
    shape: PropTypes.oneOf(['square', 'rounded']),
    style: PropTypes.object,
    variant: PropTypes.oneOf(['contained', 'outlined']),
};

Label.defaultProps = {
    style: {},
    color: colors.grey[600],
    variant: 'contained',
    shape: 'square',
};

export default Label;
