import React, { useEffect } from 'react';
import Label from '../../components/Common/Label';
import { getStatusLabelBookie, getStatusLabelColor } from '../../services/GeneralService';
import { Chip } from '@mui/material';

function BookingStatusChip(props) {
    const { booking, showSmallLabel } = props;

    useEffect(() => {
        console.log('=====booking====>', booking);
    }, [booking]);
    if (booking == null) {
        return <></>;
    }

    const { hasDraftBookingDates, isPublished, bookingDates } = booking;

    if (hasDraftBookingDates) {
        return <Chip label="Unsubmitted Changes" size={showSmallLabel ? 'small' : 'medium'} color="default" />;
    } else if (isPublished) {
        if (bookingDates.length === 0)
            return <Chip label="To Do" size={showSmallLabel ? 'small' : 'medium'} color="default" />;
        return (
            <Label
                variants={getStatusLabelColor(bookingDates, getStatusLabelBookie(bookingDates, booking))}
                shape="rounded"
                showSmallLabel={showSmallLabel ? 'small' : 'medium'}
                paddingNormal={!showSmallLabel}
            >
                {getStatusLabelBookie(bookingDates, booking)}
            </Label>
        );
    } else {
        return <Chip label="To Do" size={showSmallLabel ? 'small' : 'medium'} color="default" />;
    }
}

export default BookingStatusChip;
