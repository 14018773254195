import {Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    // root: {
    //     width: '100%',
    //     maxWidth: 500,
    // },
    // cartHeader: {
    //     padding: theme.spacing(2, 0),
    // },
    // option: {
    //     display: 'flex',
    //     alignItems: 'flex-start',
    //     //padding: theme.spacing(2),
    //     '& + &': {
    //         marginTop: '8px',
    //     },
    // },
    // headingWrap: {
    //     color: '#6e6969 !important',
    //     fontSize: '12px !important',
    //     fontWeight: '400 !important',
    //     padding: '10px !important',
    //     // width: '85px'
    // },
    // titleWrap: {
    //     padding: '16px 16px 16px 10px',
    //     width: '120px',
    //     fontSize: '12px !important',
    //     fontWeight: '400 !important',
    // },
    // tableWrap: {
    //     height: '400px',
    // },
}));

const BookingExpenseView = (props) => {
    const classes = useStyles();
    const { onExpenseValueChange, item, remove, handleFieldChange, index, emptyValues } = props;

    const [endDate, setEndDate] = useState(new Date());
    const [keyDateObj, setKeyDateObj] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [updatedTaskType, setUpdatedTaskType] = useState('');
    let total = 0;

    useEffect(() => {
        setKeyDateObj(item);
        // if (item.title) {
        //     setUpdatedTaskType({
        //         _id: item._id,
        //         title: item.title
        //     });
        // }
    }, [item]);

    const onFieldChange = (event, name, value) => {
        event.persist();
        if (name === 'qty') {
            if (item.rate) {
                total = value * item.rate;
            }
            onExpenseValueChange({ ...keyDateObj, [name]: value, total }, props.index);
        } else if (name === 'rate') {
            if (item.qty) {
                total = value * item.qty;
            }
            onExpenseValueChange({ ...keyDateObj, [name]: value, total }, props.index);
        } else {
            onExpenseValueChange({ ...keyDateObj, [name]: value }, props.index);
        }
    };
    return (
        <>
            <TableRow
                sx={{
                    borderBottom: '1px solid #EEEEEE',
                    '&:last-of-type': {
                        borderBottom: 'none',
                    },
                }}
            >
                <TableCell className={classes.titleWrap}>
                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                        ITEM
                    </Typography>
                    <Typography variant="body1">{item.item != '' ? item.item : item.title}</Typography>
                </TableCell>
                <TableCell className={classes.titleWrap}>
                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                        QTY
                    </Typography>
                    <Typography variant="body1">{item.qty}</Typography>
                </TableCell>
                <TableCell className={classes.titleWrap}>
                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                        RATE
                    </Typography>
                    <Typography variant="body1">${item.rate}</Typography>
                </TableCell>
                <TableCell className={classes.titleWrap}>
                    <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                        TOTAL
                    </Typography>
                    ${item.total}
                </TableCell>
            </TableRow>
        </>
    );
};

export default BookingExpenseView;
