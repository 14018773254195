import {fetchRecord, postRecord} from './Axios';
import {AUTHENTICATION} from '../constants/ApiEndPoint';
import {clearAccessToken, clearLoggedInUser, setAccessToken, setLoggedInUser} from './Storage';

export const login = async (user) => {
    const response = await postRecord(AUTHENTICATION.LOGIN_BY_EMAIL, user);
    if (response.status === 200) {
        clearAccessToken();
        setAccessToken(response.data.tokenInfo.accessToken);
        let user = response.data.userInfo;
        const relatedAccounts = response.data.relatedAccounts;
        const password = response.data.password;
        if (relatedAccounts) {
            user = { ...user, relatedAccounts: relatedAccounts, password: password };
        }
        setLoggedInUser(user);
    }

    return response;
};

export const signup = async (user) => await postRecord(AUTHENTICATION.REGISTER, user);

export const logout = async () => {
    let response = await postRecord(AUTHENTICATION.LOGOUT);
    clearAccessToken();
    clearLoggedInUser();
    return response;
};

export const verify = async (code) => {
    return await fetchRecord(AUTHENTICATION.EMAIL_VERIFY, code);
};

export const forgotPassword = (user) => postRecord(AUTHENTICATION.LOGIN, user);

export const getCurrentUser = async () => {
    const response = await fetchRecord(AUTHENTICATION.USER_PROFILE);
    if (response.status === 200) {
        console.log('RE', response);
        clearLoggedInUser();
        setLoggedInUser(response.data.userInfo);
    } else {
        clearAccessToken();
        clearLoggedInUser();
    }
};

export const accountLogin = async (data) => {
    clearAccessToken();
    clearLoggedInUser();
    setAccessToken(data.tokenInfo.accessToken);
    let user = data.userInfo;
    delete user.password;
    const relatedAccounts = data.relatedAccounts;
    const password = data.password;
    if (relatedAccounts) {
        user = { ...user, relatedAccounts: relatedAccounts, password: password };
    }
    setLoggedInUser(user);
};
