import React, { useEffect, useRef, useState } from 'react';
import { Alert, BottomNavigation, Box, Button, Divider, Stack } from '@mui/material';

import { BOOKING_REQUEST, COMPONENTS } from 'src/constants/ApiEndPoint';
import { fetchRecord, postRecord } from 'src/services/Axios';
import { COMMON_CODES } from 'src/constants/StatusCode';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PreferredCrewItem from './PreferredCrewItem';

import { useDispatch } from 'react-redux';
import SimpleBackdrop from '../../../components/Common/SimpleBackdrop';
import { ReactComponent as AddIcon } from '../../../assets/images/icons/add-icon.svg';
import Header from './Header';

import { makeStyles } from '@mui/styles';

import { arrayMoveImmutable } from 'array-move';
import { Mixpanel } from '../../../services/MixPanelService';
import { getLoggedInUser } from '../../../services/Storage';
import SnackBarWithTimer from '../../../components/Common/SnackBarWithTimer';
import { processPhoneNumber } from '../../../services/GeneralService';

const useStyles = makeStyles((theme) => ({
    // chip: {
    //     backgroundColor: '#D32F2F',
    //     color: '#FFFFFF',
    //     fontSize: 13,
    // },
    // chipDone: {
    //     backgroundColor: '#379B87',
    //     color: '#FFFFFF',
    //     fontSize: 13,
    // },
    // headerItem: {
    //     //fontStyle: 'italic',
    //     fontWeight: '400',
    //     fontSize: '11px',
    //     lineHeight: '12px',
    //     letterSpacing: '0.6px',
    //     textTransform: 'uppercase',
    //     color: '#999999',
    // },
    // preferredCrewContainer: {},
    // underLinedButton: {},
}));

function PreferredCrew(props) {
    const { bookingId, booking, preferredCrews, setPreferredCrews, setBooking } = props;
    const { productionInfo } = booking;
    const formRef = useRef(null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [crews, setCrews] = useState(preferredCrews);
    const [saveOnly, setSaveOnly] = useState(false);
    const [isPreferredCrewSubmitted, setIsPreferredCrewSubmitted] = useState(booking.isPreferredCrewSubmitted);
    const [hasDraftPreferredCrews, setHasDraftPreferredCrews] = useState(booking.hasDraftPreferredCrews);
    const [isDisabled, setIsDisabled] = useState(isPreferredCrewSubmitted || hasDraftPreferredCrews);
    const [isChanged, setIsChanged] = useState(false);
    const [isMakingChange, setIsMakingChange] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(
        !booking.isPreferredCrewSubmitted && !booking.hasDraftPreferredCrews,
    );
    const [showMakingChangeButton, setShowMakingChangeButton] = useState(
        booking.isPreferredCrewSubmitted || booking.hasDraftPreferredCrews,
    );
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [message, setMessage] = useState({});
    useEffect(() => {
        setCrews(preferredCrews);
    }, [preferredCrews]);

    const addPreferredCrew = () => {
        setCrews([
            ...crews,
            {
                _id: new Date().getTime(),
                isAddedByBooker: false,
            },
        ]);
        setIsChanged(true);
    };

    const deletePreferredCrew = (id) => {
        if (crews.length > 0) {
            const removed = crews.filter((item) => item._id.toString() !== id.toString());
            setCrews(removed);
            setIsChanged(true);
        }
    };

    const [roles, setRoles] = useState([]);
    const [myContacts, setMyContacts] = useState([]);
    const [loading, setLoading] = useState(false);

    const getRoles = async () => {
        setLoading(true);
        let response = await fetchRecord(COMPONENTS.ROLES);

        if (response.status === COMMON_CODES.SUCCESS) {
            setRoles(response.data.docs);
        }
        setLoading(false);
    };

    const getContacts = async () => {
        setLoading(true);
        let response;
        response = await fetchRecord(COMPONENTS.CONTACTS);
        console.log('pppppp---c>', response);
        if (response.status === 200) {
            setMyContacts(response.data.docs);
        }
        setLoading(false);
    };

    const makeChanges = async () => {
        //setIsPreferredCrewSubmitted(booking.isPreferredCrewSubmitted);
        //setHasDraftPreferredCrews(booking.hasDraftPreferredCrews);
        setIsDisabled(false);
        setIsMakingChange(true);
        setShowSaveButton(true);
        setShowMakingChangeButton(false);
    };

    const onCancel = async () => {
        //setIsPreferredCrewSubmitted(booking.isPreferredCrewSubmitted);
        //setHasDraftPreferredCrews(booking.hasDraftPreferredCrews);
        setIsDisabled(true);
        setCrews(preferredCrews);
        setIsMakingChange(false);
        setIsChanged(false);
        setShowSaveButton(false);
        setShowMakingChangeButton(true);
    };

    const handleSave = async () => {
        let isFormValid = false;
        if (formRef && formRef.current) {
            formRef.current.isFormValid(false).then(async (isValid) => {
                if (isValid) {
                    setLoading(true);
                    const response = await savePreferredCrew(true);
                    if (!response) {
                        setLoading(false);
                        return;
                    }
                    setIsPreferredCrewSubmitted(false);
                    setHasDraftPreferredCrews(true);
                    setIsMakingChange(false);
                    setIsChanged(false);
                    setShowSaveButton(false);
                    setShowMakingChangeButton(true);
                    setLoading(false);
                }
            });
        }
    };

    const handleSubmit = async () => {
        if (formRef && formRef.current) {
            formRef.current.isFormValid(false).then(async (isValid) => {
                if (isValid) {
                    setLoading(true);
                    const response = await savePreferredCrew(false);
                    if (!response) {
                        setLoading(false);
                        return;
                    }
                    setIsPreferredCrewSubmitted(true);
                    setHasDraftPreferredCrews(false);
                    setIsMakingChange(false);
                    setIsChanged(false);
                    setShowSaveButton(false);
                    setShowMakingChangeButton(true);
                    setLoading(false);
                    const loggedInUser = getLoggedInUser();
                    const mixpanelObject = {
                        loggedInUserId: loggedInUser._id,
                        productionTitle: productionInfo.title,
                    };
                    Mixpanel.SubmitPreferredCrews.fire(mixpanelObject);
                    goBack();
                }
            });
        }
    };

    const goBack = () => {
        window.location.reload();
    };

    const savePreferredCrew = async (saveOnly) => {
        console.log('crews---', crews);

        const body = {
            saveOnly: saveOnly,
            preferredCrews: crews
                .filter((value) => !value.isAddedByBooker)
                .map((item) => ({
                    roleRequested: item.role._id,
                    contactId: item.contactId || null,
                    fullName: item.fullName || item.crew.fullName,
                    email: item.email,
                    phoneNumber: processPhoneNumber(item.phoneNumber),
                    preferredNumber: item.preferredNumber,
                    isAddedByBooker: false,
                    inCrews: item.inCrews,
                    _id: item.inCrews ? item.crew?._id || item.crew || item._id : null,
                    amIVisible: true,
                })),
        };
        console.log('body---', body);
        let response = await postRecord(BOOKING_REQUEST.SET_PREFERRED_CREWS, body, bookingId);
        console.log('PPPP---', response);

        if (response) {
            if (response.status === COMMON_CODES.SUCCESS) {
                setIsDisabled(true);
                //setPreferredCrews(response.data.preferredCrews);
                // setBooking(response.data);
            } else {
                setMessage({ type: 'error', message: response.message });
                setShowSnackbar(true);
                return false;
            }
        }
        return true;
    };
    console.log('PREFERRED CREWS', crews, preferredCrews);

    useEffect(() => {
        getRoles().then();
        getContacts().then();
    }, []);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let sortedArray = arrayMoveImmutable(crews, oldIndex, newIndex);
        setCrews(sortedArray);
        setIsChanged(true);
    };

    return (
        <>
            {loading && <SimpleBackdrop />}
            <Header onBackClick={props.hideRates} booking={booking} title="Preferred Crews" />
            {showSnackbar && (
                <SnackBarWithTimer
                    open={showSnackbar}
                    timer={6000}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            {/* <Divider /> */}
            {!showMakingChangeButton && (crews.length == 0 || !booking.isPreferredCrewSubmitted) ? (
                <Alert
                    variant="outlined"
                    severity="warning"
                    icon={false}
                    sx={{ maxWidth: '241px', my: 1, px: 4, mx: 'auto' }}
                >
                    The booker has requested you submit your preffered crew
                </Alert>
            ) : (
                <Divider />
            )}
            {/* <CardContent style={{ padding: '0px 16px 0px 16px' }}> */}
            <ValidatorForm onSubmit={handleSubmit} ref={formRef} style={{ paddingBottom: '75px' }}>
                {crews &&
                    roles &&
                    myContacts &&
                    crews.map((value, index) => (
                        <PreferredCrewItem
                            key={index}
                            index={index}
                            setCrews={setCrews}
                            crews={crews}
                            roles={roles}
                            myContacts={myContacts}
                            deletePreferredCrew={deletePreferredCrew}
                            isDisabled={isDisabled}
                            setRoles={setRoles}
                            setMyContacts={setMyContacts}
                            setIsChanged={setIsChanged}
                            item={value}
                        />
                    ))}

                {!isDisabled && (
                    <Box sx={{ mt: '0.5rem' }}>
                        <Button
                            variant="text"
                            color="primary"
                            disableRipple
                            startIcon={<AddIcon />}
                            onClick={() => addPreferredCrew()}
                            sx={{ textDecoration: 'underline !important', fontSize: '0.786rem' }}
                        >
                            Add New Line Item
                        </Button>
                    </Box>
                )}

                <BottomNavigation
                    sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, p: 2, borderRadius: 0, display: 'block' }}
                >
                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="stretch">
                        {isMakingChange && (
                            <Button variant="text" color="secondary" disableRipple onClick={() => onCancel()}>
                                Cancel
                            </Button>
                        )}
                        {showSaveButton && (
                            <Button
                                disabled={isMakingChange && !isChanged}
                                variant="contained"
                                color="grey"
                                type="button"
                                onClick={() => handleSave()}
                            >
                                Save
                            </Button>
                        )}

                        {showMakingChangeButton && (
                            <Button variant="contained" type="button" onClick={() => makeChanges()} color="grey">
                                Make Changes
                            </Button>
                        )}
                        {(!isPreferredCrewSubmitted || isMakingChange) && (
                            <Button
                                disabled={isMakingChange && !isChanged && !booking.hasDraftPreferredCrews}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Submit
                            </Button>
                        )}
                    </Stack>
                </BottomNavigation>
            </ValidatorForm>
            {/* </CardContent> */}
        </>
    );
}

export default PreferredCrew;
