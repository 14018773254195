import React from 'react';
import {AppBar, Box, Grid, IconButton, Toolbar} from '@mui/material';
import {ReactComponent as Logo} from '../../assets/images/logos/logo-dark.svg';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useNavigate} from 'react-router';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Header = (props) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        navigate('/login');
    };
    return (
        <AppBar position="sticky" color="transparent" elevation={0}>
            <Toolbar>
                <Grid container>
                    <Grid item xs={3}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="back button"
                            onClick={() => navigate(-1)}
                        >
                            <ArrowBackIosNewIcon />
                        </IconButton>
                    </Grid>
                    <Grid container item xs={6} alignItems="center" justifyContent="center">
                        <Box sx={{ marginTop: '-2px' }}>{props.title ? props.title : <Logo />}</Box>
                    </Grid>
                    <Grid container item xs={3} justifyContent="flex-end">
                        {/* <IconButton size="large" edge="end" color="inherit" aria-label="more icon">
                            <CalendarTodayOutlinedIcon />
                        </IconButton>*/}
                        {props.showOptions && (
                            <IconButton
                                onClick={handleClick}
                                size="large"
                                color="inherit"
                                edge="end"
                                aria-label="more icon"
                                sx={{ mr: '-12px!important' }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        )}
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            open={open}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleClose}>Logout</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};
export default Header;
