import React from 'react';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import {TextValidator} from 'react-material-ui-form-validator';
import classNames from 'classnames';
// import onClickOutside from 'react-onclickoutside';
import {Box, TextField} from '@mui/material';

/*.Demo__suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.Demo__suggestion-item--active {
  background-color: #fafafa;
}*/
class GoogleLocationInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: typeof props.value !== 'undefined' ? props.value : '',
            lat: typeof props.lat !== 'undefined' ? props.lat : 0,
            long: typeof props.long !== 'undefined' ? props.long : 0,
            autoCompleteInitialized: false,
            searchOptions: typeof props.searchOptions !== 'undefined' ? props.searchOptions : {},
        };
    }

    locationRef = React.createRef();

    reset = () => {
        this.setState({ lat: 0, long: 0 });
    };

    resetProps = (value = 0) => {
        this.props.setLat(value);
        this.props.setLong(value);
    };

    handleChange = (address) => {
        this.setState({ address });

        this.props.onChange(address);
        this.reset();
        if (!address) {
            if (typeof this.props.setLat !== 'undefined') {
                this.resetProps();
            }
        }
        if (address !== this.state.address) {
            if (address !== this.props.value) {
                if (typeof this.props.setLat !== 'undefined') {
                    this.resetProps();
                }
            }
        }
    };

    handleSelect = (address) => {
        this.setState({ address });
        this.props.onChange(address);
        this.setState({ autoCompleteInitialized: false });
        if (typeof this.props.setLat !== 'undefined') {
            this.props.setLat(1);
            this.props.setLong(1);
        }
        if (!this.props.noGeoCodes && address.length > 1) {
            geocodeByAddress(address)
                .then((results) => {
                    const googleAddress = results[0];
                    const formatted_address = googleAddress.formatted_address;
                    let validAddress = false;
                    const firstWord = address.replace(/ .*/, '');
                    if (formatted_address.includes(firstWord) || address.includes(formatted_address)) {
                        validAddress = true;
                    }
                    if (!validAddress) {
                        return;
                    }
                    return getLatLng(results[0]);
                })
                .then((latLng) => {
                    if (typeof this.props.setLat !== 'undefined') {
                        this.props.setLat(latLng.lat);
                        this.props.setLong(latLng.lng);
                    }
                    if (typeof this.props.onBlur !== 'undefined') {
                        this.props.onBlur(latLng.lat, latLng.lng);
                    }
                    if (latLng) {
                        this.locationRef.current.validate(address);
                    }
                    this.setState({ lat: latLng.lat ? latLng.lat : 0 });
                    this.setState({ long: latLng.lng ? latLng.lng : 0 });
                })
                .catch((error) => {
                    this.setState({ lat: 0, long: 0 });
                });
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.handleChange(this.props.value);
        }
    }

    handleClickOutside = (e) => {
        this.setState({ autoCompleteInitialized: false });
    };

    handleBlur = (event) => {
        const { name, value } = event.target;
        /*   if(this.state.lat !== 0 && event.key === 'Enter'){
        this.locationRef.current.validate(value);
    }*/
    };
    /* searchOptions = {
    types: ['(cities)'],
    //componentRestrictions: {country: 'AU'}
  };*/
    render() {
        var autoCompleteClass = classNames({
            autoCompleteClass: this.state.autoCompleteInitialized,
        });
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={this.state.searchOptions}
            >
                {({ getInputProps, getInputLabelProps, suggestions, getSuggestionItemProps, loading }) => (
                    <Box sx={{ position: 'relative' }}>
                        {this.props.validate === true && this.props.disabled === true ? (
                            <TextValidator
                                ref={this.locationRef}
                                label="Location"
                                onChange={(e) => {
                                    this.props.onChange(this.state.address);
                                }}
                                placeholder="i.e Sydney, Australia"
                                helperText=""
                                fullWidth
                                name="address"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.address}
                                autocomplete="address"
                                variant="outlined"
                                {...getInputProps({
                                    placeholder: 'i.e Sydney, Australia',
                                    className: 'location-search-input',
                                    disabled: true,
                                })}
                                {...this.props.inputProps}
                            />
                        ) : this.props.validate === true ? (
                            <TextValidator
                                ref={this.locationRef}
                                label="Location"
                                onChange={(e) => {
                                    this.props.onChange(this.state.address);
                                }}
                                placeholder="i.e Sydney, Australia"
                                helperText=""
                                fullWidth
                                name="address"
                                type="text"
                                autocomplete="address"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                //onKeyUp={this.handleBlur}
                                /*     onKeyPress={this.handleBlur}*/
                                value={this.state.address}
                                variant="outlined"
                                {...getInputProps({
                                    placeholder: 'i.e Sydney, Australia',
                                    className: 'location-search-input',
                                    autoComplete: 'addressss',
                                })}
                                {...this.props.inputProps}
                            />
                        ) : (
                            <TextField
                                label="Your Location"
                                onChange={(e) => {
                                    this.props.onChange(this.state.address);
                                }}
                                placeholder="i.e Sydney, Australia"
                                helperText=""
                                fullWidth
                                name="address"
                                type="text"
                                margin="none"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.address}
                                {...this.props.inputProps}
                                variant="outlined"
                                {...getInputProps({
                                    placeholder: 'i.e Sydney, Australia',
                                    className: 'location-search-input',
                                    autoComplete: 'addressss',
                                })}
                            />
                        )}

                        <div className="autocomplete-dropdown-container">
                            <div className={autoCompleteClass}>
                                {/*loading && <div className={'location-loader'}>Loading...</div>*/}
                                {suggestions.map((suggestion) => {
                                    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Box>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default GoogleLocationInput;
