import Axios from 'axios';
import Configs from '../configs/Configs';
import {GENERAL} from '../constants/ApiResponseFormat';
import {getAccessToken, getFirebaseToken} from '../services/Storage';

const API = Axios.create({
    baseURL: Configs.API_URI,
    timeout: 30000,
    headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache, no-store' },
});

API.interceptors.request.use(
    (config) => {
        config.headers.Authorization = getAccessToken();
        config.headers.Firebase = getFirebaseToken();
        return config;
    },
    (e) => Promise.reject(e),
);

const exceptionHandler = (e) => {
    let responseContent = {};
    if (e.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        responseContent = {
            status: e.response.status,
            message: e.response.data.message,
            data: e.response.data.data,
        };
        // return responseContent;
        // console.log(e.response.status);
        // console.log(e.response.headers);
    } else if (e.request) {
        // The request was made but no response was received
        // `e.request` is an instance of XMLHttpRequest in the
        // browser and an instance of
        // http.ClientRequest in node.js
        responseContent = {
            status: 503,
            message: 'Service Unavailable',
            data: '',
        };
        //return responseContent;
        //console.log(e.request);
    } else {
        //500 Interval Server Error
        // Something happened in setting up the request that triggered an Error
        //console.log('Error', e.message);
        responseContent = {
            status: 500,
            message: e.message,
            data: '',
        };
        // return responseContent;
    }
    return responseContent;
};

const fetchRecord = async (relativeUri, pathParam1 = '', pathParam2 = '', queryParams) => {
    let responseContent = {
        status: '',
        message: '',
        data: '',
    };
    try {
        if (pathParam1 !== '') {
            relativeUri = relativeUri + '/' + pathParam1;
        }
        if (pathParam2 !== '') {
            relativeUri = relativeUri + '/' + pathParam2;
        }
        if (queryParams) {
            relativeUri = relativeUri + '?' + queryParams;
        }
        let response = await API.get(relativeUri)
            .then((response) => {
                responseContent = {
                    status: response.status,
                    message: response.data.message,
                    data: response.data.data,
                };

                return responseContent;
            })
            .catch((e) => {
                return exceptionHandler(e);
            });

        return response;
    } catch (e) {
        return GENERAL;
    }
};

const postRecord = async (relativeUri, data, pathParam1 = '', pathParam2 = '') => {
    let responseContent = {
        status: '',
        message: '',
        data: '',
    };
    if (pathParam1 !== '') {
        relativeUri = relativeUri + '/' + pathParam1;
    }
    if (pathParam2 !== '') {
        relativeUri = relativeUri + '/' + pathParam2;
    }

    try {
        let response = await API.post(relativeUri, data)
            .then((response) => {
                responseContent = {
                    status: response.status,
                    message: response.data.message,
                    data: response.data.data,
                };

                return responseContent;
            })
            .catch((e) => {
                return exceptionHandler(e);
            });
        return response;
    } catch (e) {
        return exceptionHandler(e);
    }
};

const patchRecord = async (relativeUri, data, pathParam1 = '', pathParam2 = '', queryParams) => {
    let responseContent = {
        status: '',
        message: '',
        data: '',
    };
    if (pathParam1 !== '') {
        relativeUri = relativeUri + '/' + pathParam1;
    }
    if (pathParam2 !== '') {
        relativeUri = relativeUri + '/' + pathParam2;
    }
    if (queryParams) {
        relativeUri = relativeUri + '?' + queryParams;
    }

    try {
        let response = await API.patch(relativeUri, data)
            .then((response) => {
                responseContent = {
                    status: response.status,
                    message: response.data.message,
                    data: response.data.data,
                };

                return responseContent;
            })
            .catch((e) => {
                return exceptionHandler(e);
            });
        return response;
    } catch (e) {
        return exceptionHandler(e);
    }
};

const putRecord = async (relativeUri, pathParam1 = '', pathParam2 = '', data) => {
    let responseContent = {
        status: '',
        message: '',
        data: '',
    };

    try {
        if (pathParam1 !== '') {
            relativeUri = relativeUri + '/' + pathParam1;
        }
        if (pathParam2 !== '') {
            relativeUri = relativeUri + '/' + pathParam2;
        }
        let response = await API.put(relativeUri, data)
            .then((response) => {
                responseContent = {
                    status: response.status,
                    message: response.data.message,
                    data: response.data.data,
                };

                return responseContent;
            })
            .catch((e) => {
                return exceptionHandler(e);
            });
        return response;
    } catch (e) {
        return exceptionHandler(e);
    }
};

const deleteRecord = async (relativeUri, data, pathParam1 = '', pathParam2 = '') => {
    let responseContent = {
        status: '',
        message: '',
        data: '',
    };
    if (pathParam1 !== '') {
        relativeUri = relativeUri + '/' + pathParam1;
    }
    if (pathParam2 !== '') {
        relativeUri = relativeUri + '/' + pathParam2;
    }

    try {
        let response = await API.delete(relativeUri, data)
            .then((response) => {
                responseContent = {
                    status: response.status,
                    message: response.data.message,
                    data: response.data.data,
                };

                return responseContent;
            })
            .catch((e) => {
                return exceptionHandler(e);
            });
        return response;
    } catch (e) {
        return exceptionHandler(e);
    }
};

const searchCrewings = async (relativeUri, queryParams) => {
    try {
        if (queryParams) {
            relativeUri = relativeUri + '?' + queryParams;
        }
        let response = await API.get(relativeUri)
            .then((response) => {
                GENERAL.status = response.status;
                GENERAL.message = response.data.message;
                GENERAL.data = response.data.data;
                return response;
            })
            .catch((e) => {
                return exceptionHandler(e);
            });

        return response;
    } catch (e) {
        return false;
    }
};

export const download = async (uri, data) => {
    try {
        const API = Axios.create({
            baseURL: Configs.API_URI,
            timeout: 300000,
            headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache, no-store' },
        });

        API.interceptors.request.use(
            (config) => {
                config.headers.Authorization = getAccessToken();
                config.headers.Firebase = getFirebaseToken();
                return config;
            },
            (e) => Promise.reject(e),
        );

        let response1 = await API.post(uri, data, { responseType: 'blob' })
            .then((response) => {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(new Blob([response.data]), data.fileName);
                    console.log('apple');
                } else {
                    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    const fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', data.fileName);
                    document.body.appendChild(fileLink);

                    fileLink.click();

                    GENERAL.status = 200;
                    GENERAL.message = 'File downloaded successfully.';
                    return GENERAL;
                }
            })
            .catch((e) => {
                const exception = exceptionHandler(e);
                GENERAL.status = 500;
                GENERAL.message = e.message;

                return GENERAL;
            });
        return GENERAL;
    } catch (e) {
        return false;
    }
};

export { API, fetchRecord, postRecord, deleteRecord, searchCrewings, patchRecord, putRecord };
