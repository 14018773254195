import React, { useEffect } from 'react';

import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function BookerAndCrew(props) {
    const { address, companyTitle, contactTitle, email, phoneNumber } = props.bookerDetails;
    const { booking } = props;
    const { shootStartDate, title } = props.production;
    // const { address, companyTitle, contactTitle, email, phoneNumber } = props.bookerDetails;
    // const { showKeyCrewMembers } = props.booking;
    useEffect(() => {
        console.log('bookerDetails====>', props.bookerDetails);
        console.log('bookerDetails====>', props.production);
        console.log('bookerDetails====>', props.booking);
    }, []);
    const theme = useTheme();

    return (
        <Stack direction="column" spacing={3} px={0} pt={0}>
            <Card elevation={0}>
                <CardHeader title="PRODUCTION OVERVIEW" />
                <CardContent sx={{ pt: '12px!important', pb: '0!important' }}>
                    <Stack direction="column" columnSpacing={3} pt={0.75} pb={0}>
                        <Grid container rowSpacing={0.75} columnSpacing={1} px={2}>
                            <Grid item xs={6}>
                                <Typography variant="h4">Production Name</Typography>
                                <Typography variant="body1">{title}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">Client</Typography>
                                <Typography variant="body1">
                                    {props.production.client ? props.production.client.title : ''}
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="h4">Production Start Date</Typography>
                                <Typography variant="body1">{shootStartDate}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">Location</Typography>
                                <Typography variant="body1">{address}</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ mt: '1.39rem!important', mb: '0.929rem!important' }} />
                        <Grid
                            container
                            rowSpacing={0.5}
                            columnSpacing={1}
                            sx={{ mt: '0!important', mb: '0!important' }}
                            px={2}
                        >
                            <Grid item xs={6}>
                                <Typography variant="h4">Production Company</Typography>
                                <Typography variant="body1">{companyTitle}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">Production Contact</Typography>
                                <Typography variant="body1">{contactTitle}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="h4">Phone Number</Typography>
                                <Typography variant="body1">{phoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4">Email Address</Typography>
                                <Typography variant="body1">{email}</Typography>
                            </Grid>
                        </Grid>

                        {props.keyMembers.length > 0 && (
                            <>
                                <Divider sx={{ mt: '1.5rem!important', mb: '1.25rem!important' }} />
                                <Stack direction="column" spacing={2} px={2}>
                                    <Typography variant="h4">Key Crew Members</Typography>
                                    <Stack direction="column" spacing={0.75}>
                                        {props.keyMembers.map((item, index) => (
                                            <Stack direction="row" spacing={2} key={new Date() + index}>
                                                <Avatar
                                                    sx={{
                                                        w: '40px',
                                                        h: '40px',
                                                    }}
                                                    src={item.photoUri}
                                                />
                                                <Box>
                                                    <Typography variant="h4" sx={{ lineHeight: '1.1' }}>
                                                        {item.name}
                                                    </Typography>
                                                    <Typography variant="body1">{item.role}</Typography>
                                                </Box>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                            </>
                        )}
                        {booking?.allowToViewOverview && (
                            <>
                                <Divider sx={{ mt: '1.143rem!important', mb: '0!important' }} />
                                <Box
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{ display: 'flex' }}
                                    px={2}
                                    py={0.5}
                                    onClick={() => props.openDescription()}
                                >
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            textTransform: 'uppercase',
                                            textDecoration: 'underline',
                                            mb: '0!important',
                                            color: theme.palette.text.primary,
                                            cursor: 'pointer',
                                        }}
                                    >
                                        View Production Description
                                    </Typography>

                                    <IconButton
                                        onClick={() => props.openDescription()}
                                        size="large"
                                        edge="end"
                                        color="inherit"
                                        aria-label="back button"
                                        sx={{ p: '.5rem!important' }}
                                    >
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                </Box>
                            </>
                        )}
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    );
}

export default BookerAndCrew;
