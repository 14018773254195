import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Grid, IconButton, Typography} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function Toolbar({ date, view, onDatePrev, onDateNext, onEventAdd, onViewChange, onDateToday, className, ...rest }) {
    return (
        <Grid xs={12} alignItems="center" container justifyContent="center" pb={1}>
            <Grid xs={7} alignItems="center" container spacing={1}>
                <Grid item xs={2}>
                    <IconButton size="large" edge="end" color="inherit" aria-label="calendar icon" onClick={onDatePrev}>
                        <ArrowBackIosNewIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={8} justifyContent="space-between" alignItems="center">
                    <Typography style={{ textTransform: 'uppercase', textAlign: 'center' }}>
                        {moment(date).format('MMMM YYYY')}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <IconButton size="large" edge="end" color="inherit" aria-label="calendar icon" onClick={onDateNext}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
}

Toolbar.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    date: PropTypes.any.isRequired,
    onDateNext: PropTypes.func,
    onDatePrev: PropTypes.func,
    onDateToday: PropTypes.func,
    onEventAdd: PropTypes.func,
    onViewChange: PropTypes.func,
    view: PropTypes.string.isRequired,
};

export default Toolbar;
