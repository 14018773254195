import { BottomNavigation, Box, Button, Modal, Stack, Typography } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BOOKING_REQUEST } from '../../constants/ApiEndPoint';
import { COMMON_CODES } from '../../constants/StatusCode';
import { postRecord } from '../../services/Axios';
// import { setFlash } from '../../stores/actions/Flash';
import { useTheme } from '@mui/material/styles';
import BookingDatesItem from './BookingDatesItem';
// import { Typography, Button, Grid,  useTheme,  } from '@mui/material';
import SnackBarWithTimer from '../../components/Common/SnackBarWithTimer';

import { getBookingDatesResponseLabel, getStatusLabel } from '../../services/GeneralService';
import { Mixpanel } from '../../services/MixPanelService';
import { getLoggedInUser } from '../../services/Storage';

function BookingDates(props) {
    const { booking } = props;
    const { productionInfo } = booking;
    console.log(productionInfo, 'productionInfo');
    const [dates, setDates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [message, setMessage] = useState({});

    const theme = useTheme();

    const [submitVisible, setSubmitVisible] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(true);
    const [makeChangesVisible, setMakeChangesVisible] = useState(false);
    const [cancelVisible, setCancelVisible] = useState(false);
    const [submitAllVisible, setSubmitAllVisible] = useState(true);
    const [saveButtonVisible, setSaveButtonVisible] = useState(false);
    const [saveButtonDisable, setSaveButtonDisable] = useState(false);

    const [submitAllDisable, setSubmitAllDisable] = useState(false);

    const [editMode, setEditMode] = useState(false);
    const [saved, setSaved] = useState(false);
    const [changed, setChanged] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const dispatch = useDispatch();
    const [responses, setResponse] = useState([
        { title: 'Accept', id: 'accept', value: 'accept' },
        { title: 'Decline', id: 'decline', value: 'decline' },
        { title: 'Booking Conflict', id: 'bookingConflict', value: 'bookingConflict' },
        { title: 'On Leave', id: 'onLeave', value: 'onLeave' },
        //{ title: 'To Do', id: 'toDo', value: 'toDo' },
    ]);
    const [successModelOpen, setSucceModelOpen] = React.useState(false);
    const [allAcceptanceDate, setAllAcceptanceDate] = React.useState([]);
    const handleOpen = () => setSucceModelOpen(true);
    const handleClose = () => setSucceModelOpen(false);
    const modelStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: '40px 16px',
        border: 'none',
        width: '90%',
        borderRadius: '6px',
        maxWidth: '480px',
        textAlign: 'center',
    };

    const saveResponses = async (itemId, type, status, showSuccessMessage = false) => {
        // setLoading(true);
        const response = await postRecord(`${BOOKING_REQUEST.ACCEPT_DECLINE_DATES}/${itemId}/${type}`, {
            status: status,
            bookingDepartmentItemId: dates[0].bookingDepartmentItemId,
        });
        //dispatch(setFlash(response));
        console.log('response====>', response);
        if (response) {
            if (response.status === COMMON_CODES.SUCCESS) {
                // setMessage({ type: 'success', message: response.message });
                // setShowSnackbar(true);

                if (showSuccessMessage) {
                    setSucceModelOpen(true);
                } else {
                    setMessage({ type: 'success', message: response.message });
                    setShowSnackbar(true);
                }
                props.refreshDates();
            } else {
                setMessage({ type: 'error', message: response.message });
                setShowSnackbar(true);
            }
        }
        // setLoading(false);
    };
    const images = { Reccie: '', 'Gear Return': '', 'Weather Day': '' };
    const onSave = async () => {
        setLoading(true);
        let acceptedAllDates = false;
        let allDatesAcceptance = [];
        for (let i = 0; i < dates.length; i++) {
            if (typeof dates[i].receiverAcceptance == 'object') {
                allDatesAcceptance.push(dates[i].receiverAcceptance.value);
            } else {
                allDatesAcceptance.push(dates[i].receiverAcceptance);
            }
        }
        if (allDatesAcceptance.length == dates.length) {
            acceptedAllDates = allDatesAcceptance.every((val, i, arr) => val === arr[0] && val === 'accept');
        }
        var count = 0;
        for (let i = 0; i < dates.length; i++) {
            await saveResponses(
                dates[i]._id,
                typeof dates[i].receiverAcceptance == 'object'
                    ? dates[i].receiverAcceptance.value
                    : dates[i].receiverAcceptance,
                'draft',
                acceptedAllDates,
            );
            count++;
        }
        setMakeChangesVisible(false);
        setSubmitAllVisible(false);
        setSubmitVisible(true);
        setSaveButtonVisible(false);
        setCancelVisible(false);
        setLoading(false);
        // if (count == dates.length) {
        //     props.refreshView();
        // }
    };

    const onSubmit = async () => {
        console.log('submit dates--->', dates);
        setLoading(true);
        let allDatesAcceptance = [];
        let acceptedAllDates = false;
        console.log('typeof item.receiverAcceptance-->', typeof dates[0].receiverAcceptance);
        var count = 0;
        for (let i = 0; i < dates.length; i++) {
            if (typeof dates[i].receiverAcceptance == 'object') {
                allDatesAcceptance.push(dates[i].receiverAcceptance.value);
            } else {
                allDatesAcceptance.push(dates[i].receiverAcceptance);
            }
        }
        if (allDatesAcceptance.length == dates.length) {
            acceptedAllDates = allDatesAcceptance.every((val, i, arr) => val === arr[0] && val === 'accept');
        }
        setAllAcceptanceDate(allDatesAcceptance);
        for (let i = 0; i < dates.length; i++) {
            await saveResponses(
                dates[i]._id,
                typeof dates[i].receiverAcceptance == 'object'
                    ? dates[i].receiverAcceptance.value
                    : dates[i].receiverAcceptance,
                'final',
                acceptedAllDates,
            );
            count++;
        }
        console.log('this includes all data', allDatesAcceptance);
        await postRecord(`${BOOKING_REQUEST.NOTIFY_BOOKING_DATES}/${dates[0].bookingDepartmentItemId}`, {
            dates: dates.map((item) => {
                return {
                    response:
                        typeof item.receiverAcceptance === 'object'
                            ? item.receiverAcceptance.value
                            : item.receiverAcceptance,
                };
            }),
            bookingDepartmentItemId: dates[0].bookingDepartmentItemId,
        });
        const loggedInUser = getLoggedInUser();
        const mixpanelObject = {
            loggedInUserId: loggedInUser._id,
            productionTitle: productionInfo.title,
        };
        Mixpanel.SubmitBookingResponse.fire(mixpanelObject);
        setLoading(false);
        // if (count == dates.length) {
        //     props.refreshView();
        // }

        // setMakeChangesVisible(true);
        // setSubmitAllVisible(false);
        // setSubmitVisible(false);
        // setSaveButtonVisible(false);
        // setCancelVisible(false);
    };
    const acceptAll = async () => {
        const updatedDates = dates.map((obj) => {
            return { ...obj, receiverAcceptance: responses[0] };
        });
        setDates(updatedDates);
        var count = 0;
        setLoading(true);
        for (let i = 0; i < dates.length; i++) {
            await saveResponses(dates[i]._id, 'accept', 'final', true);
            count++;
        }
        await postRecord(`${BOOKING_REQUEST.NOTIFY_BOOKING_DATES}/${dates[0].bookingDepartmentItemId}`, {
            dates: dates.map((item) => {
                return { response: 'accept' };
            }),
            bookingDepartmentItemId: dates[0].bookingDepartmentItemId,
        });
        const loggedInUser = getLoggedInUser();
        const mixpanelObject = {
            loggedInUserId: loggedInUser._id,
            productionTitle: productionInfo.title,
        };
        Mixpanel.SubmitBookingResponse.fire(mixpanelObject);
        setLoading(false);
        // if (count == dates.length) {
        //     props.refreshView();
        // }
        // dates.asyncForEach(item => {

        // });
        setMakeChangesVisible(true);
        setSubmitAllVisible(false);
        setSubmitVisible(false);
        setSaveButtonVisible(false);
        setCancelVisible(false);
    };
    const declineAll = async () => {
        const updatedDates = dates.map((obj) => {
            return { ...obj, receiverAcceptance: responses[1] };
        });
        setDates(updatedDates);
        var count = 0;
        setLoading(true);
        for (let i = 0; i < dates.length; i++) {
            await saveResponses(dates[i]._id, 'decline', 'final');
            count++;
        }
        await postRecord(`${BOOKING_REQUEST.NOTIFY_BOOKING_DATES}/${dates[0].bookingDepartmentItemId}`, {
            dates: dates.map((item) => {
                return { response: 'decline' };
            }),
            bookingDepartmentItemId: dates[0].bookingDepartmentItemId,
        });
        const loggedInUser = getLoggedInUser();
        const mixpanelObject = {
            loggedInUserId: loggedInUser._id,
            productionTitle: productionInfo.title,
        };
        Mixpanel.SubmitBookingResponse.fire(mixpanelObject);
        setLoading(false);
        // if (count == dates.length) {
        //     props.refreshView();
        // }
        setMakeChangesVisible(true);
        setSubmitAllVisible(false);
        setSubmitVisible(false);
        setSaveButtonVisible(false);
        setCancelVisible(false);
    };

    useEffect(() => {
        console.log('props.dates----', props.dates);
        console.log('draft----------', isDraftCheck());
        console.log('isSubmitted----', isSubmitted());
        setDates(props.dates);
        setMakeChangesVisible(isSubmitted() || isDraftCheck());
        setSaveButtonVisible(isSubmitted());
        setSubmitAllVisible(!isDraftCheck() && !isSubmitted());
        setSubmitVisible(isDraftCheck() || (!isDraftCheck() && !isSubmitted()));
        setSubmitDisable(!isDraftCheck());
        setSaveButtonVisible(false);
        setCancelVisible(false);
        setEditMode(false);
        setLabel(/*props.label &&*/ getStatusLabel(props.dates, props.booking));
    }, [props.dates]);

    const getDaysDiff = (start_date, end_date, date_format = 'YYYY-MM-DD') => {
        const getDateAsArray = (date) => {
            return moment(date.split(/\D+/), date_format);
        };
        return getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days') + 1;
    };
    const handleChange = (value, item) => {
        setChanged(true);
        setSubmitDisable(false);
        setSubmitAllDisable(false);
        setSaveButtonDisable(false);
        const updatedDates = dates.map((obj) => {
            if (obj._id == item._id) {
                return { ...obj, receiverAcceptance: value };
            } else {
                return obj;
            }
        });
        console.log('updatedDates===item=>', updatedDates);
        setDates(updatedDates);
        setMakeChangesVisible(false);
        setSubmitAllVisible(false);
        setSubmitVisible(true);
        setSaveButtonVisible(true);
        setCancelVisible(true);
    };
    const getResponseObject = (value) => {
        console.log('value====2=>', value);

        if (value.trim() == '') {
            return { title: '', id: 'to do', value: 'to do' };
        }

        if (
            value.toLowerCase() == 'awaiting approval' ||
            value.toLowerCase() == 'unpublished' ||
            value.toLowerCase() == 'awaiting approval' ||
            value.toLowerCase() == 'to do'
        ) {
            console.log('value====2=c>', value);
            return { title: '', id: value.toLowerCase(), value: value.toLowerCase() };
        }

        if (value.toLowerCase() == 'decline' || value.toLowerCase() == 'declined') return responses[1];
        else if (value.toLowerCase() == 'accept' || value.toLowerCase() == 'accepted') return responses[0];
        else if (value.toLowerCase() == 'bookingconflict' || value.toLowerCase() == 'booking conflict')
            return responses[2];
        else if (value.toLowerCase() == 'onleave' || value.toLowerCase() == 'on leave') return responses[3];
        else return '';
    };
    const isDraftCheck = () => {
        //return props.dates[0].status == 'draft';
        if (props.dates.find((date) => date.status == 'draft') == undefined) {
            return false;
        } else {
            return true;
        }
    };
    const isSubmitted = () => {
        // console.log(
        //   'props.dates[0].receiverAcceptance-->',
        //   props.dates[0].receiverAcceptance
        // );
        if (isDraftCheck()) {
            return false;
        }
        if (props.dates.find((date) => date.status == 'final') == undefined) {
            return false;
        } else {
            return true;
        }
        //return props.dates.find(date => date.status == 'final');
        // return (
        //   props.dates[0].receiverAcceptance == 'decline' ||
        //   props.dates[0].receiverAcceptance == 'accept'
        // );
    };
    const handleInputChange = () => {};

    // const isSubmitButtonVisible = () => {
    //   if (isDraft || saved) {
    //     // current status is draft
    //     return true;
    //   } else if (!isSubmitted() || changed) {
    //     // editMode and some changes
    //     return true;
    //   } else return false;
    // };

    const setAllResponseReadyCondition = () => {
        if (props.dates.findIndex((item) => item.receiverAcceptance == 'Awaiting Approval') == -1) {
            setMakeChangesVisible(true);
            setSaveButtonVisible(false);
            setSubmitAllVisible(false);
            setSubmitVisible(false);
            setCancelVisible(false);
            setEditMode(false);
        } else {
            setEditMode(true);
        }
    };

    const [label, setLabel] = useState(getStatusLabel(props.dates, props.booking));
    useEffect(() => {
        console.log('hererere');
        setAllResponseReadyCondition();
    }, [props.dates]);

    const getStatusLabelx = (bookingDates) => {
        console.log(bookingDates, 'bookingDates');
        let bookingResponseArray = [];
        let label = 'Submitted';

        for (const item of bookingDates) {
            bookingResponseArray.push(item.receiverAcceptance);
        }
        bookingResponseArray = [...new Set(bookingResponseArray)];

        label =
            bookingResponseArray.length > 1 ? 'Mixed Response' : getBookingDatesResponseLabel(bookingResponseArray[0]);

        return label;
    };

    return (
        <>
            <div>
                <Modal
                    keepMounted
                    open={successModelOpen}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={modelStyle}>
                        <Typography id="keep-mounted-modal-title" component="h2" variant="h2">
                            You have successfully accepted your booking dates, your booking is now considered held.
                        </Typography>
                        <Typography id="keep-mounted-modal-description" variant="body1">
                            Your booker may update your Hold Type or release your hold as the production progresses, you
                            will receive a notification when this happens.
                        </Typography>
                        {/* <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} mb={2}>
                            <CalendarIcon className="icon-outlined" />
                            <Typography
                                id="keep-mounted-modal-description"
                                variant="body2"
                                component="span"
                                className="underline pointer"
                            >
                                Download Calender Event
                            </Typography>
                        </Stack> */}
                        <Button
                            variant="contained"
                            className="rounded"
                            color="primary"
                            onClick={handleClose}
                            sx={{ mt: 1, mb: 1 }}
                        >
                            Okay thanks
                        </Button>
                    </Box>
                </Modal>
            </div>
            <Stack direction="column" spacing={0} px={0} pb={9}>
                {showSnackbar && (
                    <SnackBarWithTimer
                        open={showSnackbar}
                        timer={6000}
                        message={message}
                        onClose={() => setShowSnackbar(false)}
                    />
                )}

                {loading && (
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
                {dates.map((item, index) => (
                    <>
                        <BookingDatesItem
                            item={item}
                            getResponseObject={getResponseObject}
                            handleChange={handleChange}
                            handleInputChange={handleInputChange}
                            isSubmitted={isSubmitted}
                            isDraftCheck={isDraftCheck}
                            responses={responses}
                            editMode={editMode}
                        />
                    </>
                ))}
            </Stack>
            <BottomNavigation
                sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, p: 2, borderRadius: 0, display: 'block' }}
            >
                <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="stretch">
                    {/* <Button variant="contained" color="grey" size="small">
                        Decline All
                    </Button>
                    <Button variant="contained" color="primary" size="small">
                        Submit
                    </Button>
                    <Button variant="contained" color="primary" size="small">
                        Accept All
                    </Button> */}
                    {cancelVisible && (
                        <Button
                            variant="text"
                            color="secondary"
                            disableRipple
                            onClick={() => {
                                setDates(props.dates);
                                setEditMode(false);
                                setSaved(false);
                                setCancelVisible(false);
                                // setMakeChangesVisible(true);
                                if (isSubmitted()) {
                                    setMakeChangesVisible(true);
                                    setSubmitAllVisible(false);
                                    setSubmitVisible(false);
                                    setSaveButtonVisible(false);
                                } else {
                                    // setMakeChangesVisible(false);
                                    if (isDraftCheck()) {
                                        setMakeChangesVisible(true);
                                        setSubmitAllVisible(false);
                                        setSubmitVisible(false);
                                        setSaveButtonVisible(false);
                                        setSubmitAllVisible(false);
                                    } else {
                                        setSaveButtonVisible(false);
                                        setMakeChangesVisible(false);
                                        setSubmitVisible(true);
                                        setSubmitDisable(true);
                                        setSubmitAllVisible(true);
                                        setSubmitAllDisable(false);
                                    }
                                }
                            }}
                        >
                            Cancel
                        </Button>
                    )}
                    {saveButtonVisible && (
                        <Button
                            variant="contained"
                            type="submit"
                            color="grey"
                            disabled={saveButtonDisable}
                            onClick={() => {
                                setSaved(true);
                                setEditMode(false);
                                onSave();
                            }}
                        >
                            Save
                        </Button>
                    )}
                    {makeChangesVisible && (
                        <Button
                            variant="contained"
                            type="submit"
                            color="grey"
                            onClick={() => {
                                setEditMode(true);
                                setSaved(false);
                                setSubmitDisable(true);
                                setMakeChangesVisible(false);
                                setSubmitAllVisible(false);
                                setSubmitVisible(true);
                                setSaveButtonVisible(true);
                                setSaveButtonDisable(true);
                                setCancelVisible(true);
                            }}
                        >
                            Make Changes
                        </Button>
                    )}

                    {submitAllVisible && (
                        <>
                            <Button
                                variant="contained"
                                color="grey"
                                type="submit"
                                onClick={declineAll}
                                disabled={submitAllDisable}
                            >
                                Decline All
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={submitAllDisable}
                                onClick={acceptAll}
                            >
                                Accept All
                            </Button>
                        </>
                    )}
                    {submitVisible && (
                        <Button
                            disabled={submitDisable}
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={onSubmit}
                        >
                            Submit
                        </Button>
                    )}
                </Stack>
            </BottomNavigation>
        </>
    );
}

export default BookingDates;
