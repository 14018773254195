// /* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';

import { BOOKING_REQUEST, COMPONENTS } from '../../constants/ApiEndPoint';
import Backdrop from '@mui/material/Backdrop';
import BookerAndCrew from './BookerAndCrew';
import BookingDates from './BookingDates';
import BookingDescriptions from './BookingDescriptions';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Header from './Header';
// import { PreferredCrew } from './PreferredCrew';
// import { RatesAndEstimates } from './RatesAndEstimates';
// import Stepper from './Stepper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { fetchRecord } from '../../services/Axios';
import { setSelectedBooking } from '../../services/Storage';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import BookingRates from './BookingRates';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Box>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const { bookingId } = useParams();
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(false);
    const [production, setProduction] = useState(null);
    const [booking, setBooking] = useState();
    const [productions, setProductions] = useState([]);
    const [bookerDetails, setBookerDetails] = useState();
    const [bookingDates, setBookingDates] = useState([]);
    const [user, setUser] = useState();
    const [showDescription, setShowDescription] = useState(false);
    const [showRates, setShowRates] = useState(false);
    const navigate = useNavigate();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const fetchBooking = async (bookingId) => {
        setLoading(true);
        const response = await fetchRecord(BOOKING_REQUEST.BOOKING_REQUEST_DETAILS, bookingId);
        setSelectedBooking(response.data);
        if (response) {
            if (response.status === 200) {
                setBookerDetails(response.data.bookerDetails);
                // setKeyMembers(response.data.userKeyMembersInfo);
                setBookingDates(response.data.bookingDates);
                setBooking(response.data);
                const production = response.data.productionInfo;
                setProduction(production);
            }
        }
        setLoading(false);
    };

    const [myContacts, setMyContacts] = useState([]);

    const getContacts = async () => {
        setLoading(true);
        let response;
        response = await fetchRecord(COMPONENTS.CONTACTS + `?page=1&perPageLimit=2000`);
        if (response.status === 200) {
            setMyContacts(response.data.docs);
        }
    };

    useEffect(() => {
        fetchBooking(bookingId).then();
        getContacts().then();
    }, []);

    const goBack = () => {
        navigate('/bookings');
    };

    return (
        <Box sx={{ width: '100%' }}>
            {loading && (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}

            {production && (
                <Header
                    currentId={bookingId}
                    title={production.title}
                    onBackClick={goBack}
                    chipVisible={false}
                    booking={booking}
                />
            )}
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs" className="tabsPill tabsPillSmall">
                <Tab label="Overview" {...a11yProps(0)} />
                <Tab label="Hold Dates" {...a11yProps(1)} />
                {booking && booking.showMemberRates && <Tab label="Rate Card" {...a11yProps(2)} />}
                {/* <Tab label="Estimate" {...a11yProps(3)} /> */}
            </Tabs>
            <CustomTabPanel value={value} index={0}>
                {production && <BookingDescriptions production={production} booking={booking} />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                {booking && <BookingDates booking={booking} />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                {booking && booking.showMemberRates && myContacts && (
                    <BookingRates booking={booking} myContacts={myContacts} />
                )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                Coming Soon
            </CustomTabPanel>
        </Box>
    );
}
