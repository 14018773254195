import React from 'react';
import {Snackbar} from '@mui/material';
import {makeStyles} from '@mui/styles';
import MuiAlert from '@mui/material/Alert';

// function Alert(props, ref) {
//     return <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />;
// }

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    message: {
        whiteSpace: 'pre-wrap',
    },
}));

export default function SnackBarWithTimer(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        if (props.onClose) {
            props.onClose();
        }
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <Snackbar
                open={props.open ? props.open : open}
                autoHideDuration={props.timer ? props.timer : 5000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={props.message ? props.message.type : ''}
                    className={classes.message}
                >
                    {props.message ? props.message.message : ''}
                </Alert>
            </Snackbar>
        </div>
    );
}
