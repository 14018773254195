import React from 'react';
import {Box, Button, Link, Radio, Stack, Tooltip, Typography, useTheme} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import {ReactComponent as InfoCircle} from '../../assets/images/icons/information-circle.svg';

export default function SelectAccount(props) {
    const theme = useTheme();
    const userTypeSteps = [
        {
            title: 'Freelancer Account',
            details: 'I wish to appear as a freelancer or indivdual',
            type: 'freelancer',
        },
        { title: 'Company Account', details: 'I wish to appear as a company', type: 'company' },
    ];

    const whatBestDescribesYouSteps = [
        {
            title: 'I am here to get booked',
            details: 'Creative or crew member',
            type: 'getBooked',
        },
        {
            title: 'I am here to book crew',
            details: 'Producer, Production Manager, Production Company',
            type: 'bookCrew',
        },
        {
            title: 'All of the above',
            details: 'Producer/ Director, Production Company',
            type: 'both',
        },
    ];
    const { selectedValue, handleChange, selectedWhatBestDescribes, handleWhatBestDescribesChange, handleNext } = props;

    return (
        <Stack direction="column" spacing={1} bgcolor={theme.palette.background.paper} px={2} pt={3} pb={3.2} mt={3}>
            <Typography variant="h2" component={'div'} textAlign="center" mb={1.5}>
                Select Your Account Type
            </Typography>
            <Stack direction="column" spacing={0}>
                {userTypeSteps.map((value, index) => (
                    <Stack direction="row" spacing={0} key={index}>
                        <Radio
                            checked={selectedValue === value.type}
                            onChange={handleChange}
                            value={value.type}
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                            size="large"
                            edge="start"
                        />
                        <Box>
                            <Typography variant="h4" sx={{ display: 'flex', gap: 0.5 }} alignItems="center">
                                {value.title}
                                <Tooltip title={value.details} placement="top" arrow>
                                    <InfoCircle />
                                </Tooltip>
                            </Typography>
                            <Typography variant="subtitle1">{value.details}</Typography>
                        </Box>
                    </Stack>
                ))}
            </Stack>
            <Typography variant="h4" sx={{ display: 'flex', gap: 0.5 }} alignItems="center">
                Which best describes you?
                <Tooltip title="I am here to get booked or to book crew" placement="top" arrow>
                    <InfoCircle />
                </Tooltip>
            </Typography>
            <Stack direction="column" spacing={-0.7} sx={{ mt: '0.143rem!important' }}>
                {whatBestDescribesYouSteps.map((value, index) => (
                    <Stack direction="row" spacing={0} alignItems="flex-start" key={index}>
                        <Radio
                            checked={selectedWhatBestDescribes === value.type}
                            onChange={handleWhatBestDescribesChange}
                            value={value.type}
                            name="radio-buttons1"
                            inputProps={{ 'aria-label': 'C' }}
                            size="large"
                            edge="start"
                        />
                        <Box>
                            <Typography variant="h5" sx={{ mt: '1rem!important', mb: '0.143rem!important' }}>
                                {value.title}
                            </Typography>
                            <Typography variant="subtitle1">{value.details}</Typography>
                        </Box>
                    </Stack>
                ))}
            </Stack>
            <Stack direction="row" spacing={2} sx={{ display: 'flex', mt: '2.25rem!important' }} alignItems="center">
                <Button variant="contained" className="rounded" sx={{ marginLeft: '.125rem' }} onClick={handleNext}>
                    Next Step
                </Button>
                <Link to="/login" component={RouterLink} variant="body1">
                    Already have an account?
                </Link>
            </Stack>
        </Stack>
    );
}
