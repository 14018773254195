export const USER_MANAGEMENT = {
    LIST_USER: '/account-settings/list-users',
};

const AUTHENTICATION = {
    LOGIN_BY_EMAIL: 'loginByEmail',
    SEND_ONE_TIME_CODE: 'sendCode',
    LOGIN_BY_CODE: 'loginByCode',
    REGISTER: 'register',
    USER_PROFILE: 'profile',
    COMPARE_PASSWORD: 'comparePassword',
    LOGIN_BY_ID: 'loginById',
    LOGOUT: 'logout',
    RESEND_VERIFICATION_EMAIL: 'resendVerification',
    VERIFY_EMAIL: 'verify-email',
    SEND_PHONE_NUMBER_VERIFICATION_CODE: 'sendPhoneNumberVerificationCode',
    VERIFICATION_PHONE_NUMBER_CODE: 'verifyCode',
    FORGOT_PASSWORD: 'forgotPassword',
    VERIFY_RESET_PASSWORD_TOKEN: 'verifyResetPasswordToken',
    RESET_PASSWORD: 'resetPassword',
};

const USER = {
    HOME: 'user/home',
    SET_FIRST_LOGIN: 'user/setFirstLogin',
    DETAIL: '/user/detail/',
    DISABLE_NOTIFICATION: '/user/disableChatNotification/',
    GET_NOTIFICATION_DISABLED: '/user/getDisabledChatNotification/',
    UPDATE_PRIVACY: '/user/privacy/',
};

const PROFILE = {
    UPDATE_COMPANY_VENDOR_PROFILE: 'user/setCompanyVendorProfile',
    UPDATE_FREELANCER_PROFILE: 'user/setFreelancerProfile',
    UPDATE_AVAILABILITY: 'user/setAvailability',
    UPDATE_CONTACT_METHODS: 'user/updateContactMethods',
};

const PAGE = {
    TERMS_AND_CONDITION: 'page/get/TermsAndCondition',
    PRIVACY_POLICY: 'page/get/PrivacyPolicy',
    CONTACT_US: 'page/get/ContactUs',
    ABOUT_US: 'page/get/AboutUs',
};

const COMPONENTS = {
    SKILLS: 'components/list-components?component=skills',
    USERS: '/components/list-components?component=users',
    COMPANIES: '/components/list-components?component=companies',
    ROLES: '/components/getRoles',
    TASK_TYPES: 'components/getTaskTypes',
    SERVICES: '/components/list-components?component=services',
    PROFESSIONS: '/components/list-components?component=professions',
    CHAT_USERS: '/components/list-components?component=chat',
    EQUIPMENTS: '/components/list-components?component=equipments',
    SPECIALITIES: '/components/list-components?component=content_format_specialities',
    USED_SKILLS: 'components/list-components?component=used_skills',
    UNIONS: 'components/list-components?component=unions',
    USED_UNIONS: 'components/list-components?component=used_unions',
    CREW_TEMPLATES: 'components/list-components?component=crew_templates',
    ALL_CREWS: 'components/list-components?component=all_crews',
    USED_EQUIPMENTS: 'components/list-components?component=used_equipments',
    CURATION_TAGS: 'components/list-components?component=curation_tags',
    ACCREDITATION: 'components/list-components?component=accreditations',
    HOLD_TYPES: 'components/list-components?component=holdTypes',
    USED_COMPANIES: 'components/list-components?component=used_companies',
    PRODUCTIONS: 'components/list-components?component=productions',
    CONTACTS: 'components/listContacts',
};

const COMPANY = {
    SEARCH: 'company/search',
    PROFILE: 'company/profile',
    TYPES: 'company/getCompanyTypes',
};

const SAVED_ITEMS = {
    LIST: '/saved-items/list',
    LIST_ALL: '/saved-items/listAllFolders',
    LIST_ALL_ITEMS: '/saved-items/listAllItems',
    CREATE_FOLDER: '/saved-items/createFolder',
    RENAME_FOLDER: '/saved-items/renameFolder/',
    DELETE_FOLDER: '/saved-items/deleteFolder/',
    DELETE_ITEM: '/saved-items/deleteItems/',
    SAVE_TO_LIST: '/saved-items/saveToList',
};

const PROJECTS = {
    DETAIL: '/projects/detail/',
    MY_PROJECTS: '/projects/my-projects',
    LIKE: '/projects/like/',
    CREDIT_CREW: '/projects/creditRequest',
    LIKED_PROJECTS: '/projects/likedProjects',
    FEATURED_PROJECTS: '/projects/featured-projects',
    ADMIN_FEATURED_PROJECTS: '/projects/admin-featured-projects',
    CHECK_VIDEO: '/projects/checkVideo',
    REPORT_PROJECT: '/projects/report',
};

const CONNECTIONS = {
    CONNECT: 'connections/connect',
    MY_CONNECTIONS: 'connections/myConnections',
    MY_REPRESENTED_CREWS: 'connections/myRepresentedCrews',
    MY_PREFERRED_CREWS: 'connections/myPreferredCrews',
    DISCONNECT: 'connections/disconnect',
};

const NOTIFICATIONS = {
    LIST: 'notifications/list',
    RESPOND: 'notifications/respond',
    MARK_AS_READ: 'notifications/markAsRead',
    MARK_ALL_AS_READ: 'notifications/markAllAsRead',
    ACTIVITIES: 'notifications/activities',
    PRODUCTION_ACTIVITIES: 'notifications/productionActivities',
    CONTACT_HISTORY_LOGS: 'notifications/contactHistoryLogs',
    NOTIFY_CHAT: 'notifications/notifyNewChat',
    NOTIFY_BY_MAIL: 'notifications/notifyByEmail',
};
const CONTACTS = {
    SAVE: '/contacts/save',
    ADD_SINGLE_CREW: '/contacts/addSingleCrewContact',
    LIST: 'contacts/list',
};

const CREWING = {
    PROJECTS_CREDITED_IN: '/crewing/projectsCreditedIn',
    ADD_CREDITED_PROJECT: '/crewing/addCreditedProject',
    REMOVE_CREDIT: '/crewing/removeCredit',
    SHOW_ALL_CREDITED: '/crewing/showAllCredited',
};
const CALL_SHEETS = {
    CREATE: '/call-sheets/create',
    LIST: '/call-sheets/list/',
    DELETE: '/call-sheets/delete/',
    UPDATE: '/call-sheets/update',
    DETAIL: '/call-sheets/detail/',
    DETAIL_BY_TOKEN: '/call-sheets/detail-by-token/',
    ADD_RECIPIENTS: '/call-sheets/add-recipients',
    LIST_RECIPIENTS: '/call-sheets/list-recipients/',
    LIST_RECIPIENTS_BY_TOKEN: '/call-sheets/list-recipients-by-token/',
    DELETE_RECEPIENT: '/call-sheets/delete-recipient/',
    DELETE_RECEPIENTS: '/call-sheets/delete-recipients/',
    UPDATE_RECIPIENTS: '/call-sheets/update-recipient',
    SORT_RECIPIENTS: '/call-sheets/sort-recipients/',
    PATCH_ATTACHMENTS: '/call-sheets/patchAttachments',
    DELETE_ATTACHMENT: '/call-sheets/deleteAttachment',
    DOWNLOAD_ATTACHMENT: '/call-sheets/downloadPdf',
    SEND_EMAIL: '/call-sheets/sendCallSheet',
    RESEND_EMAIL: '/call-sheets/resendCallSheet/',
};
const CALL_TIMES = {
    CREATE: '/call-times/create',
    UPDATE: '/call-times/update/',
    LIST: '/call-times/list/',
    LIST_BY_TOKEN: '/call-times/listByToken/',
    DELETE: '/call-times/delete/',
    SORT: '/call-times/sort/',
};

const SPECIAL_INSTRUCTIONS = {
    CREATE: '/special-instructions/create',
    UPDATE: '/special-instructions/update/',
    LIST: '/special-instructions/list/',
    LIST_BY_TOKEN: '/special-instructions/listByToken/',
    DELETE: '/special-instructions/delete/',
    SORT: '/special-instructions/sort/',
};

const LOCATIONS = {
    CREATE: '/locations/create',
    UPDATE: '/locations/update/',
    LIST: '/locations/list/',
    LIST_BY_TOKEN: '/locations/listByToken/',
    DELETE: '/locations/delete/',
    SORT: '/locations/sort/',
};
const SCHEDULES = {
    CREATE: '/schedules/create',
    UPDATE: '/schedules/update/',
    LIST: '/schedules/list/',
    LIST_BY_TOKEN: '/schedules/listByToken/',
    DELETE: '/schedules/delete/',
    SORT: '/schedules/sort/',
};
const EXTRA_ATMOSPHERE = {
    CREATE: '/extra-atmosphere/create',
    UPDATE: '/extra-atmosphere/update/',
    LIST: '/extra-atmosphere/list/',
    DELETE: '/extra-atmosphere/delete/',
    SORT: '/extra-atmosphere/sort/',
};

export const PRODUCTIONS = {
    CREATE: '/productions/create',
    DETAIL: '/productions/detail',
    DETAIL_BY_TOKEN: '/productions/detailByToken',
    LIST: '/productions/myProductions',
    PATCH_ATTACHMENTS: '/productions/patchAttachments',
    DELETE_ATTACHMENT: '/productions/deleteAttachment',
    UPDATE: '/productions/update',
    CREW_MEMBERS: '/productions/crewMembers',
    DOWNLOAD_ATTACHMENT: '/productions/downloadAttachment',
    CREW_ACTIVITY: '/productions/crewActivity',
    DELETE: '/productions/delete',
    SAVE_DEPARTMENTS: '/productions/saveDepartments',
    SAVE_DEPARTMENT_MEMBERS: '/productions/saveDepartmentMembers',
    GET_ALL_DEPARTMENTS: '/productions/getDepartments',
    SAVE_CONFIGURATIONS: '/productions/saveConfiguration',
};

export const BOOKINGS = {
    CREATE: '/bookings/create',
    DETAIL: '/bookings/detail',
    UPDATE: '/bookings/update',
    DELETE: '/bookings/delete',
    CANCEL: '/bookings/cancel',
    DELETE_CREW_BOOKINGS: '/bookings/deleteBookingsByCrew',
    MY_BOOKINGS: '/bookings/myBookings',
    GET_BOOKINGS: '/bookings/getBookings',
    MY_PRODUCTION_COMPANIES: '/bookings/myProductionCompanies',
    CREW_AVAILABILITY_CALENDAR: '/getCrewAvailabilityCalendar',
};

export const EVENTS = {
    CREATE: '/events/create',
    DETAIL: '/events/detail',
    UPDATE: '/events/update',
    DELETE: '/events/delete',
    LIST: 'bookingRequest/events',
    AUTHORIZE: 'events/authorizeGoogleCode',
    GENERATE_URL: 'events/generateUrl',
};

export const SUBSCRIPTIONS = {
    CREATE_CUSTOMERS: '/subscriptions/createCustomer',
    BUY: '/subscriptions/buy',
    FREE_TRIAL: '/subscriptions/freeTrial',
    COUPON_INFO: '/subscriptions/couponInfo',
    BILLING_INFO: '/subscriptions/getBillingDetails',
    INVOICES: '/subscriptions/getInvoices',
    UPDATE_BILLING: '/subscriptions/updateBillingDetails',
    UPDATE_CARD: '/subscriptions/updateCard',
    DELETE_CARD: '/subscriptions/deleteCard',
    DETAIL: '/subscriptions/detail',
    CANCEL: '/subscriptions/cancel',
    UPDATE: '/subscriptions/update',
    PLAN_DETAIL: '/subscriptions/getPlanDetail',
    PREVIEW_PLAN_SWITCH: 'subscriptions/previewPlanSwitch',
};

export const BANNER = {
    GET: '/banner/get',
};

export const WIZARD = {
    DIRECTORY_APPLICATION: '/wizard/setDirectoryApplication',
    PROFILE_INFORMATION: '/wizard/setProfileInformation',
    COMPANY_PROFILE_INFORMATION: '/wizard/setCompanyProfileInformation',
    PREFERRED_CREWS: '/wizard/setPreferredCrews',
    GET_PREFERRED_CREWS: '/wizard/getInvitedPreferredCrews',
    DELETE_INVITED_CREWS: '/wizard/deleteInvitedCrews',
};

export const TEMPLATE = {
    DETAILS: '/template/details',
};
export const PRODUCTION_TEAMS = {
    LIST: '/team/list',
    SELECT_TEAM: '/team/selectTeam',
    LIST_PREVIOUS_TEAMS: '/productionBooking/listPreviousTeams',
    SELECT_PREVIOUS_TEAM: '/team/selectPreviousTeam',
};

export const AVAILABILITY_CHECK = {
    GET_ALL_TASK_TYPES: '/bookingRequest/getTaskTypes',
    DETAIL: '/availabilityCheck/detail',
    SAVE_DRAGGED_MEMBER: '/availabilityCheck/saveDraggedMember',
    DELETE_MEMBER: '/availabilityCheck/deleteMember',
    RESET: '/availabilityCheck/reset',
    ORDER_DEPARTMENTS: '/availabilityCheck/orderDepartments',
    SET_RESPONSE_STATUS: '/availabilityCheck/setResponseStatus',
    ORDER_MEMBERS: '/availabilityCheck/orderAcMembers',
    UPDATE_DEPARTMENT: '/availabilityCheck/updateDepartment',
    DELETE_DEPARTMENT: '/availabilityCheck/deleteDepartment',
    SAVE_DEPARTMENTS: '/availabilityCheck/saveDepartments',
    ORDER_BLOCKS: '/availabilityCheck/orderBlocks',
    PUBLISH_CHECKS: '/availabilityCheck/publishChecks',
    NOTIFICATIONS: '/availabilityCheck/notifications',
    PRODUCTION_DETAIL: '/availabilityCheck/acProductionDetail',
    MARK_ALL_AS_READ: 'availabilityCheck/markAllAsReadForProduction',
    SEND_MEMBER_TO_BOOKINGS: 'availabilityCheck/sendMemberToBookings',
    ADD_MEMBER_TO_AVAIL_CHECK: 'availabilityCheck/addMemberToAvailCheck',
};

export const PRODUCTION_BOOKING = {
    SELECT_TEMPLATE: '/productionBooking/setTemplate',
    SELECT_TEAM: '/productionBooking/selectTeam',
    SELECT_PREVIOUS_TEAM: '/productionBooking/selectPreviousTeam',
    START_FROM_AVAILABILITY_CHECKER: '/productionBooking/startFromAvailabilityChecker',
    SAVE_BOOKING_CONFIGURATIONS: '/productionBooking/saveBookingConfiguration',
    DETAIL: '/productionBooking/detail',
    MEMBER_DETAIL: '/productionBooking/memberDetail',
    SAVE_MEMBER: '/productionBooking/saveDraggedMember',
    SAVE_DRAGGED_MEMBER: '/productionBooking/saveDraggedMember',
    DELETE_MEMBER: '/productionBooking/deleteMember',
    RESET: '/productionBooking/reset',
    ORDER_DEPARTMENTS: '/productionBooking/orderBookingDepartments',
    SET_RESPONSE_STATUS: '/productionBooking/setResponseStatus',
    ORDER_MEMBERS: '/productionBooking/orderBookingMembers',
    UPDATE_DEPARTMENT: '/productionBooking/updateDepartment',
    DELETE_DEPARTMENT: '/productionBooking/deleteDepartment',
    SAVE_DEPARTMENTS: '/productionBooking/saveDepartments',
    ORDER_BLOCKS: '/productionBooking/orderBlocks',
    PUBLISH_CHECKS: '/productionBooking/publishChecks',
    NOTIFICATIONS: '/productionBooking/notifications',
    PRODUCTION_DETAIL: '/productionBooking/acProductionDetail',
    MARK_ALL_AS_READ: 'productionBooking/markAllAsReadForProduction',
    SAVE_BOOKING_DATES: '/productionBooking/saveDepartmentItemDates',
    SAVE_BOOKING_TIMES: '/productionBooking/saveDepartmentItemTimes',
    SAVE_BOOKING_EQUIPMENTS: '/productionBooking/saveDepartmentItemEquipments',
    SAVE_BOOKING_EXPENSES: '/productionBooking/saveDepartmentItemExpenses',
    UPDATE_BOOKING_DATES: '/productionBooking/updateDepartmentItemDates',
    UPDATE_BOOKING_CONFIGURATION: '/productionBooking/updateBookingItemConfiguration',
    ADD_MEMBER_TO_BOOKING: '/productionBooking/addMemberToBooking',
    SAVE_BOOKING_RATES: '/productionBooking/saveDepartmentItemRates',
    UPDATE_BOOKING_RATES: '/productionBooking/updateDepartmentItemRates',

    MARK_AS_PUBLISHED: 'productionBooking/markAsPublished',
    APPROVE_RATES: 'productionBooking/approveRates',
    RESPOND_RATES: 'productionBooking/respondRates',
    SET_PREFERRED_CREWS: 'productionBooking/setPreferredCrews',
    SEARCH_PC: 'productionBooking/search/new',
};

export const BOOKING_REQUEST = {
    LIST_BOOKING_REQUESTS: '/bookingRequest/list',
    BOOKING_REQUEST_DETAILS: '/bookingRequest/details',
    ACCEPT_DECLINE_DATES: '/bookingRequest/acceptDeclineBookingDates',
    SET_PREFERRED_CREWS: '/bookingRequest/setPreferredCrews',
    SET_RATES: '/bookingRequest/setRates',
    APPROVE_RATES: '/bookingRequest/approveRates',
    NOTIFY_BOOKING_DATES: '/bookingRequest/notifyBookingDatesChange',
    RESPOND_RATES: '/bookingRequest/respondRates',
    SEARCH_PC: '/bookingRequest/search/new',
};

export {
    USER,
    PAGE,
    AUTHENTICATION,
    PROFILE,
    COMPONENTS,
    COMPANY,
    SAVED_ITEMS,
    PROJECTS,
    CONNECTIONS,
    NOTIFICATIONS,
    CONTACTS,
    CREWING,
    CALL_SHEETS,
    CALL_TIMES,
    EXTRA_ATMOSPHERE,
    SPECIAL_INSTRUCTIONS,
    LOCATIONS,
    SCHEDULES,
};
