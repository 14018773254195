import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  List,
  ListItem,
  Stack,
  Typography,
  colors,
} from '@mui/material';
import React, {useEffect, useState} from 'react';

import {ReactComponent as DownloadIcon} from '../../assets/images/icons/navigation-down-circle.svg';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {PRODUCTIONS} from 'src/constants/ApiEndPoint';
import bytesToSize from 'src/utils/bytesToSize';
import clsx from 'clsx';
import {download} from '../../services/Axios';
import {makeStyles} from '@mui/styles';
import {useTheme} from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
        height: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        position: 'relative',
        paddingBottom: 77,
        width: '100%',
    },
    contentWrapper: {
        width: '100%',
    },
    media: {
        height: 240,
        width: '100%',
    },
    placeholder: {
        height: 240,
        backgroundColor: colors.blueGrey[50],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    insertDriveFileIcon: {
        height: theme.spacing(6),
        width: theme.spacing(6),
        fontSize: theme.spacing(6),
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        paddingRight: 0,
        width: '100%',
    },
    actionsWrapper: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
    },
    downloadIcon: {
        color: theme.palette.common.white,
        fontSize: 22,
        marginRight: theme.spacing(1),
    },
    fileName: {
        width: 'calc(100% - 50px)',
        wordBreak: 'break-all',
        padding: '24px 16px',
    },
}));

function BookingDescriptions({ booking, production }) {
    const [loading, setLoading] = useState(false);
    const { attachments } = production;
    const classes = useStyles();

    const downloadFile = async (file, fileName) => {
        setLoading(true);

        const response = await download(PRODUCTIONS.DOWNLOAD_ATTACHMENT, {
            fileName: file.file.name,
            uri: file.fileUri,
        });
        setLoading(false);
    };
    const dummyDescription = `Production Description will be shown here .Sed aliquet sed arcu nec tincidunt. Pellentesque habitant morbi tristique senectus et netus et
    malesuada fames ac turpis egestas. Quisque a faucibus ligula. Maecenas sollicitudin egestas
    nisl. Cras tempus nulla eget urna fermentum, vel mattis nisl varius. Vivamus metus ipsum,
    porttitor sit amet porta vel, finibus dignissim nulla. Nullam ultrices .`;

    const dummySchedules = [
        { description: 'Day One Picnic Scene', dateTime: '9:00am 22-10-19', address: ' 18 Stuart St, Manly NSW 2095' },
        { description: 'Day Two Picnic Scene', dateTime: '9:00am 22-10-20', address: ' 18 Stuart St, Manly NSW 2095' },
        {
            description: 'Day Three Picnic Scene',
            dateTime: '9:00am 22-10-21',
            address: ' 18 Stuart St, Manly NSW 2095',
        },
        { description: 'Day four Picnic Scene', dateTime: '9:00am 22-10-22', address: ' 18 Stuart St, Manly NSW 2095' },
    ];
    const [schedules, setSchedules] = useState([]);

    useEffect(() => {
        console.log('production====>', production);
        if (production.productionSchedule && production.productionSchedule.length > 0) {
            setSchedules(production.productionSchedule);
        }
    }, [production]);
    const theme = useTheme();

    return (
        <>
            {loading && (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <Stack
                direction="column"
                spacing={3}
                bgcolor={theme.palette.background.paper}
                px={2}
                pt={2.5}
                pb={4}
                mt={1}
            >
                <Typography variant="h4">Production Description</Typography>

                {/* <div style={{ color: '#000' }}>
                        {production.description === '' ? dummyDescription : production.description}
                    </div> */}
                <Box
                    sx={{
                        marginTop: '1rem!important',
                        paddingBottom: '0.857rem',
                        fontSize: '1rem',
                        lineHeight: '1.714',
                    }}
                    dangerouslySetInnerHTML={{
                        __html: production.description === '' ? dummyDescription : production.description,
                    }}
                />
            </Stack>
            {schedules.length > 0 && (
                <Stack direction="column" spacing={3} bgcolor={theme.palette.background.paper} pt={2.5} pb={4.5} mt={1}>
                    <Box px={2}>
                        <Typography variant="h4">Production Schedule</Typography>
                    </Box>
                    <List>
                        {schedules.map((schedule) => {
                            return (
                                <ListItem disablePadding sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Typography variant="body2">
                                        {schedule.description} / {schedule.dateTime}
                                    </Typography>

                                    <Typography variant="subtitle1">{schedule.address}</Typography>
                                </ListItem>
                            );
                        })}
                    </List>
                </Stack>
            )}
            {attachments.length > 0 && (
                <Stack
                    direction="column"
                    spacing={3}
                    bgcolor={theme.palette.background.paper}
                    px={2}
                    pt={2.5}
                    pb={4}
                    mt={1}
                >
                    <Typography variant="h4">Attachments</Typography>

                    {/* <div style={{ color: '#000' }}>
                            {production.description === '' ? dummyDescription : production.description}
                        </div> */}
                    {attachments &&
                        attachments.map((file) => (
                            <Box sx={{ minWidth: 275 }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        {file.file.type && file.file.type.includes('image/') ? (
                                            <CardMedia className={classes.media} image={file.fileUri} />
                                        ) : (
                                            <div className={classes.placeholder}>
                                                <InsertDriveFileIcon className={classes.insertDriveFileIcon} />
                                            </div>
                                        )}
                                    </CardContent>
                                    <div className={classes.fileName}>
                                        <Typography variant="h6">{file.file.name}</Typography>
                                        <Typography variant="caption">{bytesToSize(file.file.size)}</Typography>
                                    </div>
                                    <CardActions style={{ justifyContent: 'center' }}>
                                        <Button
                                            onClick={(event) => downloadFile(file)}
                                            style={{
                                                backgroundColor: '#999999',
                                                color: 'white',
                                                borderRadius: '50px',
                                                paddingBottom: '9px',
                                                textTransform: 'none',
                                                paddingTop: '7px',
                                                lineHeight: 1.25,
                                                marginBottom: '7px',
                                            }}
                                        >
                                            <span className={clsx('crews-download-circle', classes.downloadIcon)}>
                                                <DownloadIcon />
                                            </span>
                                            Download
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Box>
                        ))}
                </Stack>
            )}
        </>
    );
}

export default BookingDescriptions;
