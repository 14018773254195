export const AUTH_ROUTES = {
    LOGIN: '/login',
    ONE_TIME_LOGIN: '/login-one-time',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-password',
    VERIFY_EMAIL: '/verify-email',
    LOGOUT: '/logout',
    RESEND_VERIFICATION_EMAIL: '/resend-verification-email',
    DASHBOARD: '/dashboard',
    ACCOUNT_CHOICE: '/account-choice',
    VERIFY_PHONE_NUMBER: '/verify-phone-number',
    TUTORIALS: '/tutorial/boarding-slideshow',
    RESET_PASSWORD: '/reset-password',
    RESET_PASSWORD_SUCCESS: '/reset-password-success',
};

export const BOOKING_ROUTES = {
    INDEX: '/bookings',
    DETAIL: '/booking-requests/detail',
    DETAIL_NEW: '/booking-requests-new',
};

export const ERROR_ROUTES = {
    _404: '/errors/error-404',
};
