import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import {WHAT_DESCRIBERS_YOU_BOOK_CREW, WHAT_DESCRIBERS_YOU_GET_BOOKED} from "../constants";
import Configs from "../configs/Configs";


mixpanel.init(Configs.MIXPANEL_TOKEN, {debug: true});

console.log(Configs.MIXPANEL_TOKEN,'TOKEN','BOKEN')

let env_check = true;//process.env.NODE_ENV === 'production';
const FREELANCER = "freelancer";
const COMPANY = "company";
const COMPANY_STANDARD = "companyStandard";
const COMPANY_VENDOR = "companyVendor";
const COMPANY_ADMIN = "administrator";
const COMPANY_EDITOR = "Editor";

function getWhatBestDescribesYou(whatBestDescribesYou) {
  let response = 'Hybrid';
  if (whatBestDescribesYou === WHAT_DESCRIBERS_YOU_GET_BOOKED) {
    response = 'Bookee'
  } else if (whatBestDescribesYou === WHAT_DESCRIBERS_YOU_BOOK_CREW) {
    response = 'Booker'
  }
  return response;
}

function getCustomAccountType(accountType, companyAccountType = "", whatBestDescribesYou = false) {

  let customAccountType = "Freelancer";
  if (accountType === FREELANCER) {
    customAccountType = "Freelancer";
  } else if (accountType === COMPANY_STANDARD) {
    customAccountType = "Company";
  } else if (accountType === COMPANY_VENDOR) {
    customAccountType = "Company";
  } else if (accountType === COMPANY_ADMIN || accountType === COMPANY_EDITOR) {
    if (companyAccountType === COMPANY_VENDOR) {
      customAccountType = "Company Sub User";
    } else {
      customAccountType = "Company Sub User";
    }
  }
  if (whatBestDescribesYou) {

    customAccountType = customAccountType + ' - ' + getWhatBestDescribesYou(whatBestDescribesYou)
  }
  return customAccountType;
}

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  register: (props) => {
    if (env_check) mixpanel.register(props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
  MobileRegisterEvent: {
    fire: (props) => {

      Mixpanel.identify(props._id);
      let accountType = props.accountType;
      let whatBestDescribesYou = props.whatBestDescribesYou;

      let userType = FREELANCER;
      let subscriptionPlan = "Freelancer";
      if (accountType === COMPANY) {
        userType = COMPANY_STANDARD;
        subscriptionPlan = "Vendor & Company";

        if (props.doOfferProductionService) {
          userType = COMPANY_VENDOR;
          subscriptionPlan = "Vendor & Company";

        }
      }
      userType = getCustomAccountType(userType, '', whatBestDescribesYou);
      let mixPanelData = {
        '$name': props.companyName && props.companyName.length > 0 ? props.companyName : props.firstName + ' ' + props.lastName,
        '$first_name': props.firstName,
        '$last_name': props.lastName,
        '$email': props.email,
        'Account Type': userType,
        'Company Name': props.companyName,
        'Do you offer production services?': props.doOfferProductionService,
        'Will you be sourcing crew and managing your own productions?': props.isSourcingCrewOwnProduction,
        'Registration Date': moment().format('L'),
        'Subscription Plan Type': `${subscriptionPlan} Free Plan`,//needs change in future
        'Account Status': 'Unconfirmed'
      };
      console.log(mixPanelData, 'mixPanelData');

      Mixpanel.track('Complete Registration Mobile', mixPanelData);
      Mixpanel.people.set(mixPanelData);

    }
  },
  MobileLoginEvent: {
    fire: (props) => {
      //console.log('process.env.REACT_APP_MIX_PANEL_LOGIN',process.env.REACT_APP_MIX_PANEL_LOGIN);
      if (!env_check) {
        return true;
      } else {
        let loggedInData = props;
        let subscriptionPlanType = "";
        let customAccountType = "";//getCustomAccountType(loggedInData.userType,loggedInData.companyInfo.userType);
        //identify
        console.log(loggedInData);
        mixpanel.identify(loggedInData._id);

        //fire login event
        if (loggedInData.userType === COMPANY_ADMIN || loggedInData.userType === COMPANY_EDITOR) {
          customAccountType = getCustomAccountType(loggedInData.userType, loggedInData.companyInfo.userType, loggedInData.whatBestDescribesYou);
          let customAccountTypeSubscription = getCustomAccountType(loggedInData.companyInfo.userType);
          if (loggedInData.companyInfo.hasSubscription === true) {
            subscriptionPlanType = `${loggedInData.companyInfo.subscription.planName} ${loggedInData.companyInfo.subscription.subscriptionType}`;
          } else {
            subscriptionPlanType = `${customAccountTypeSubscription} Free Plan`;
          }
        } else {
          customAccountType = getCustomAccountType(loggedInData.userType, "", loggedInData.whatBestDescribesYou);
          if (loggedInData.hasSubscription === true) {
            subscriptionPlanType = `${loggedInData.subscription.planName} ${loggedInData.subscription.subscriptionType}`;
          } else {
            subscriptionPlanType = `${customAccountType} Free Plan`;
          }
        }
        let loginEventObject = {
          'Login Method': 'E-mail',
          'Subscription Plan Type': subscriptionPlanType,
          'Account Type': customAccountType,
          'Account Status': "Confirmed"
        };
        //console.log(loginEventObject);return false;
        if (loggedInData.userType == COMPANY_ADMIN || loggedInData.userType == COMPANY_EDITOR) {
          loginEventObject['$name'] = loggedInData.fullName;
          loginEventObject['$email'] = loggedInData.email;
        }
        //console.log('loginEventObject',loginEventObject);
        //track event
        mixpanel.track('Login Mobile', loginEventObject);

        mixpanel.people.set(loginEventObject);
      }

      //if (env_check) mixpanel.people.set(props);
    }
  },
  MobileOneTimeLoginEvent: {
    fire: (props) => {
      //console.log('process.env.REACT_APP_MIX_PANEL_LOGIN',process.env.REACT_APP_MIX_PANEL_LOGIN);
      if (!env_check) {
        return true;
      } else {
        let subscriptionPlanType = "";
        let customAccountType = "";//getCustomAccountType(loggedInData.userType,loggedInData.companyInfo.userType);
        mixpanel.identify(props._id);

        //fire login event
        if (props.userType == COMPANY_ADMIN || props.userType == COMPANY_EDITOR) {
          customAccountType = getCustomAccountType(props.userType, props.companyInfo.userType, props.whatBestDescribesYou);
          let customAccountTypeSubscription = getCustomAccountType(props.companyInfo.userType);
          if (props.companyInfo.hasSubscription === true) {
            subscriptionPlanType = `${props.companyInfo.subscription.planName} ${props.companyInfo.subscription.subscriptionType}`;
          } else {
            subscriptionPlanType = `${customAccountTypeSubscription} Free Plan`;
          }
        } else {
          customAccountType = getCustomAccountType(props.userType, "", props.whatBestDescribesYou);
          if (props.hasSubscription === true) {
            subscriptionPlanType = `${props.subscription.planName} ${props.subscription.subscriptionType}`;
          } else {
            subscriptionPlanType = `${customAccountType} Free Plan`;
          }
        }
        let loginEventObject = {
          'Login Method': 'E-mail',
          'Subscription Plan Type': subscriptionPlanType,
          'Account Type': customAccountType,
          'Account Status': "Confirmed"
        };
        //console.log(loginEventObject);return false;
        if (props.userType == COMPANY_ADMIN || props.userType == COMPANY_EDITOR) {
          loginEventObject['$name'] = props.fullName;
          loginEventObject['$email'] = props.email;
        }
        //console.log('loginEventObject',loginEventObject);
        //track event
        mixpanel.track('Login One Time Mobile', loginEventObject);

        mixpanel.people.set(loginEventObject);
      }

      //if (env_check) mixpanel.people.set(props);
    }
  },
  SubmitBookingResponse: {
    fire: (props) => {
      if (!env_check) {
        return true;
      } else {
        let loggedUserId = props.loggedInUserId;

        const name = props.productionTitle;

        let event = {
          'Production Name': name,
        };
        mixpanel.identify(loggedUserId);
        mixpanel.track('Submit A Response', event);
        mixpanel.people.set(event);
      }
    }
  },
  SubmitBookingRates: {
    fire: (props) => {
      if (!env_check) {
        return true;
      } else {
        let loggedUserId = props.loggedInUserId;

        const name = props.productionTitle;

        let event = {
          'Production Name': name,
        };
        mixpanel.identify(loggedUserId);
        mixpanel.track('Submit Rates', event);
        mixpanel.people.set(event);
      }
    }
  },
  SubmitPreferredCrews: {
    fire: (props) => {
      if (!env_check) {
        return true;
      } else {
        let loggedUserId = props.loggedInUserId;

        const name = props.productionTitle;

        let event = {
          'Production Name': name,
        };
        mixpanel.identify(loggedUserId);
        mixpanel.track('Submit Preferred Crews', event);
        mixpanel.people.set(event);
      }
    }
  },
  DummySubmitPreferredCrews: {
    fire: (props) => {
      if (!env_check) {
        return true;
      } else {
        let loggedUserId = props.loggedInUserId;

        const name = props.productionTitle;

        let event = {
          'Production Name': name,
        };
        mixpanel.identify(loggedUserId);
        mixpanel.track('Submit Preferred Crews', event);
        mixpanel.people.set(event);
      }
    }
  },
};

export let Mixpanel = actions;
