/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {useTheme} from '@mui/material/styles';

// import validate from 'validate.js';
// import { makeStyles } from '@mui/styles';
import {Button, Link, Stack, Typography} from '@mui/material';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AUTH_ROUTES} from '../../constants/SiteRoutes';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {Link as RouterLink} from 'react-router-dom';
// import components
import Header from '../../components/Header';
import SnackBarWithTimer from '../../components/Common/SnackBarWithTimer';
import {postRecord} from "../../services/Axios";
import {AUTHENTICATION} from "../../constants/ApiEndPoint";
import AccountSelectionModal from "../ResendVerificationEmail/AccountSelection";

function ForgotPassword() {
    const theme = useTheme();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [resend, setResend] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [message, setMessage] = useState({});
    const [openBase, setOpenBase] = useState(false);
    const [accounts, setAccounts] = React.useState([]);

    const handleSubmit = async ()  => {
        const body = {email: email};
        setLoading(true);
        const response = await postRecord(AUTHENTICATION.FORGOT_PASSWORD,body);
        setMessage({ type: 'success', message: response.message });
        setShowSnackbar(true);
        if(response.status === 200){
            setEmail('');
        }
        else if(response.status === 412){
            setOpenBase(true)
            setAccounts(response.data.accounts);
        }
        else{
            setMessage({ type: 'error', message: response.message });
            setShowSnackbar(true);
        }
        setLoading(false);
    }


    return (
        <>
            {loading && (
              <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={loading}>
                  <CircularProgress color="inherit"/>
              </Backdrop>
            )}
            {showSnackbar && (
                <SnackBarWithTimer
                    open={showSnackbar}
                    timer={6000}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <Header type="general" />

            <ValidatorForm onSubmit={handleSubmit}>
                <Stack direction="column" spacing={3} bgcolor={theme.palette.background.paper} px={2} pt={2.5} pb={4}>
                    <Typography variant="h3" component={'div'} textAlign="center">
                        Password Reset
                    </Typography>
                    <Stack direction="column" spacing={2.6}>
                        <TextValidator
                            label="Your Email Address"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Your Email Address"
                            helperText=""
                            fullWidth
                            name="email"
                            type="text"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={email}
                            validators={['required', 'isEmail']}
                            errorMessages={['Email field is required.', 'Must be a valid Email.']}
                            variant="outlined"
                            autoComplete="email"
                            autoFocus
                            size="medium"
                        />
                        <Stack direction="column" spacing={0.8}>
                            <Button color="primary" size="large" type="submit" variant="contained" disabled={loading}>
                                Reset Password
                            </Button>
                            <Stack direction="column" spacing={0} textAlign="center">
                                <Link component={RouterLink} to={AUTH_ROUTES.LOGIN} variant="body2">
                                    Wait, I remember by password
                                </Link>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </ValidatorForm>
            <AccountSelectionModal
              setLoading={setLoading}
              setShowSnackbar={setShowSnackbar}
              setMessage={setMessage}
              onClose={() => setOpenBase(false)}
              open={openBase}
              accounts={accounts}
              setOpenBase={setOpenBase}
              resetEmail={setEmail}
              enteredEmail={email}
              url={AUTHENTICATION.FORGOT_PASSWORD}
            />
        </>
    );
}

export default ForgotPassword;
