import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack
} from '@mui/material';

import {AUTH_CODES} from '../../constants/StatusCode';
import {postRecord} from '../../services/Axios';

function AccountSelectionModal({open, onClose, className, ...rest}) {
  const {
    accounts,
    setOpenBase,
    resetEmail,
    enteredEmail,
    url,
    currentIndex,
    setShowSnackbar,
    setLoading,
    setMessage
  } = rest;
  const [selectedIndex, setSelectedIndex] = React.useState(currentIndex);
  const [email, setEmail] = React.useState(enteredEmail);
  const [disabled, setDisabled] = React.useState(true);
  const [id, setId] = React.useState('');
  if (!open) {
    return null;
  }
  const handleListItemClick = async (item, index) => {
    setSelectedIndex(index);
    setEmail(item.email);
    setId(item._id);
    //setUserType(item.userType);
    setDisabled(false);
  };

  const handleClose = () => {
    onClose();
    setDisabled(true);
    setEmail('');
    setId('');
    setSelectedIndex(null);

  };

  const dismissModal = () => {
    onClose();
    setDisabled(true);
    setSelectedIndex(null);
    setId('');
    setEmail('');
  };

  async function handleSubmit(event) {
    const user = {
      _id: id,
      email: email
    };
    setLoading(true);
    const response = await postRecord(url, user);

    if (response.status === AUTH_CODES.SUCCESS) {
      setOpenBase(false);
      resetEmail('');
      setMessage({ type: 'success', message: response.message });
      setShowSnackbar(true);
    } else {
      setOpenBase(false);
      setMessage({ type: 'error', message: response.message });
      setShowSnackbar(true);
    }

    setDisabled(true);
    setSelectedIndex(null);
    setId('');
    setEmail('');
    setLoading(false);
  }

  return (

    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Account Selection</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select an account.
        </DialogContentText>
        <Stack direction="column" spacing={2.6}>
          <List sx={{ bgcolor: 'background.paper', mt: '0.5rem!important', p: '0!important' }}>
            {accounts.map((item, key) => (
              <>
                <ListItem
                  key={key}
                  button
                  selected={selectedIndex === key}
                  onClick={(event) => handleListItemClick(item, key)}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={item.displayName}
                      src={
                        item.photoUri
                          ? item.photoUri
                          : '/images/avatars/avatar-unregistered-user.svg'
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText primary={item.displayName} secondary={item.email}/>
                  <ListItemSecondaryAction>
                    <IconButton
                      size="large"
                      edge="end"
                      color="inherit"
                      aria-label="go button"
                      onClick={(event) => handleListItemClick(item, key)}
                    >
                      {/* <ArrowForwardIosIcon/> */}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider component="li"/>
              </>
            ))}
          </List>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Dismiss</Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          variant="contained"
          disabled={disabled}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AccountSelectionModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  handlePopup: PropTypes.func
};

AccountSelectionModal.defaultProps = {
  open: false,
  onClose: () => {
  }
};

export default AccountSelectionModal;
