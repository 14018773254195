import { createTheme } from '@mui/material/styles';
import colors from './_themes-vars.module.scss';

export default createTheme({
    palette: {
        primary: {
            main: colors.primaryMain,
            light: colors.primaryLight,
            dark: colors.primaryDark,
        },
        secondary: {
            main: colors.secondaryMain,
        },
        error: {
            main: colors.errorMain,
            light: colors.errorLight,
            dark: colors.errorDark,
        },
        warning: {
            main: colors.warningMain,
            light: colors.warningLight,
            contrastText: colors.warningText,
        },
        info: {
            main: colors.infoMain,
            light: colors.infoLight,
            dark: colors.infoDark,
        },
        success: {
            main: colors.successMain,
            light: colors.successLight,
            dark: colors.successDark,
        },
        text: {
            primary: colors.textPrimary,
        },
        grey: {
            100: colors.grey100,
            300: colors.grey300,
            400: colors.grey400,
            500: colors.grey500,
            600: colors.grey600,
            700: colors.grey700,
            800: colors.grey800,
            900: colors.grey900,
            A100: colors.greyA100,
            A200: colors.greyA200,
            A400: colors.greyA400,
            A700: colors.greyA700,
        },
        background: {
            paper: colors.commonWhite,
            default: colors.bgDefault,
        },
        common: {
            black: colors.commonBlack,
            white: colors.commonWhite,
        },
    },
    typography: {
        fontFamily: 'inherit',
        fontWeight: '400',
        poster: {
            color: '#1A2733',
        },
        h1: {
            fontFamily: 'Koor',
            fontSize: '2.286rem',
            fontWeight: '400',
            lineHeight: '2.643rem',
            marginTop: '0!important',
            '& sup': {
                fontFamily: 'Graphik',
                fontSize: '0.857rem',
                marginRight: '0.286rem',
            },
        },
        h2: {
            fontSize: '1.714rem',
            fontWeight: '400',
            lineHeight: '1.857rem',
            letterSpacing: '0.6px',
            color: colors.grey900,
        },
        h3: {
            color: colors.textPrimary,
            fontFamily: 'GraphikMedium',
            fontSize: '1rem',
            fontWeight: '400',
            letterSpacing: '0.35px',
            lineHeight: '2.286rem',
        },
        h4: {
            color: colors.textPrimary,
            fontFamily: 'GraphikMedium',
            fontSize: '1rem',
            fontWeight: '400',
            letterSpacing: '0.2px',
            lineHeight: '1.5rem',
        },
        h5: {
            fontSize: '.857rem',
            lineHeight: '1.429rem',
            color: colors.textPrimary,
            letterSpacing: '0.4px',
        },
        body1: {
            fontFamily: 'Graphik',
            fontWeight: '400',
            fontSize: '1rem',
            lineHeight: '1.714rem',
            color: colors.textPrimary,
            letterSpacing: '0.5px',
            wordBreak: 'break-word',
        },
        body2: {
            fontSize: '.857rem',
            lineHeight: '1.429rem',
            color: colors.textPrimary,
        },
        button: {
            textTransform: 'none',
        },
        caption: {
            display: 'block',
            fontSize: '0.786rem',
            lineHeight: '0.974rem',
            color: colors.grey500,
            marginBottom: '.25rem!important',
        },
        subtitle1: {
            display: 'block',
            fontSize: '0.857rem',
            lineHeight: '1.429rem',
            letterSpacing: '0.3px',
            color: colors.grey500,
            marginBottom: '.25rem!important',
        },
        subtitle2: {
            display: 'block',
            fontFamily: 'GraphikMedium',
            fontSize: '1rem',
            fontWeight: '400',
            lineHeight: '1.75rem',
            letterSpacing: '0.15px',
            color: colors.textPrimary,
        },
        overline: {
            color: colors.grey500,
            fontFamily: 'Graphik',
            fontWeight: '400',
            fontSize: '0.786rem',
            lineHeight: '0.864rem',
            letterSpacing: '0.6px',
        },
    },
    TablePagination: {
        rowsPerPage: {
            display: 'flex',
            border: '1px solid black',
        },
    },
    FormLabel: {
        fontSize: '2rem',
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    color: colors.textPrimary,
                    textDecorationColor: colors.textPrimary,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.commonWhite,
                    color: colors.commonBlack,
                    '&.MuiAppBar-colorTransparent': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    width: '100%',
                    minHeight: '87px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    zIndex: '9',
                    '& .MuiIconButton-root': {
                        //marginBottom: '5px',
                        padding: '.5rem',
                    },
                    '& .MuiStepper-root': {
                        paddingTop: '10px',
                        '& .MuiStepLabel-label': {
                            marginTop: '0',
                            fontSize: '.857rem',
                            color: colors.commonWhite,
                            letterSpacing: '0.4px',
                            lineHeight: '.8rem',
                        },
                        '& .MuiStepConnector-root': {
                            '& .MuiStepConnector-line': {
                                borderColor: colors.grey700,
                            },
                            '&.Mui-active': {
                                '& .MuiStepConnector-line': {
                                    borderColor: colors.primaryMain,
                                },
                            },
                            '&.MuiStepConnector-alternativeLabel': {
                                left: 'calc(-50% + 13px)',
                                right: 'calc(50% + 13px)',
                            },
                        },
                        '& .MuiStepLabel-root': {
                            '& .MuiStepLabel-iconContainer': {
                                '& .QontoStepIcon-circle': {
                                    width: '12px',
                                    height: '12px',
                                    color: colors.grey700,
                                },
                                '&.Mui-active': {
                                    '& .QontoStepIcon-circle': {
                                        color: colors.primaryMain,
                                    },
                                },
                                '&.Mui-completed': {
                                    '& .QontoStepIcon-completedIcon': {
                                        color: colors.primaryMain,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    margin: '0',
                    '& .MuiInputLabel-root': {
                        letterSpacing: '0.57px',
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    top: '-1px!important',
                    fontSize: '1rem',
                    color: colors.textPrimary,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '0.429rem',
                    '& .MuiOutlinedInput-input': {
                        padding: '12px 15px 16px!important',
                        height: 'auto!important',
                        '&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus': {
                            '-webkit-box-shadow': '0 0 0px 1000px #fff inset',
                        },
                    },
                    '&.MuiInputBase-sizeSmall': {
                        '& .MuiOutlinedInput-input': {
                            padding: '7px 7px 9px!important',
                        },
                    },
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    whiteSpace: 'nowrap',
                    '&.MuiButton-containedGrey': {
                        color: colors.commonWhite,
                        backgroundColor: colors.greyA700,
                    },
                    '& .MuiButton-startIcon': {
                        marginRight: '11px',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'GraphikMedium',
                    fontSize: '1rem',
                    fontWeight: 'normal',
                    boxShadow: 'none!important',
                    padding: '7px 20px 9px!important',
                    letterSpacing: '0.12px',
                    '&.rounded': {
                        borderRadius: '22px',
                        letterSpacing: '.6px',
                    },
                    '&.MuiButton-containedPrimary:not(.Mui-disabled)': {
                        backgroundColor: colors.primaryMain,
                        color: colors.commonWhite,
                        '&:hover': {
                            backgroundColor: colors.primaryDark,
                        },
                    },
                    '&.MuiButton-textPrimary': {
                        color: `${colors.primaryMain}!important`,
                    },
                    '&.buttonRounded': {
                        borderRadius: '50px',
                    },
                },
            },
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    '& .react-tel-input': {
                        margin: '0!important',
                        '& .special-label': {
                            left: '9px',
                            top: '-11px',
                            fontFamily: 'Graphik',
                            fontSize: '.857rem',
                            color: colors.textPrimary,
                            padding: '0 6px',
                        },
                        '& .form-control': {
                            borderColor: colors.grey400,
                            width: '100%',
                            padding: '11px 15px 15px 59px!important',
                            fontFamily: 'Graphik',
                            fontSize: '1rem',
                        },
                        '& .flag-dropdown': {
                            left: '16px',
                            top: '3px',
                            bottom: '1px',
                        },
                        '& .selected-flag': {
                            padding: '0!important',
                            '&:hover,&:focus': {
                                backgroundColor: 'transparent',
                            },
                        },
                        '&:focus-within': {
                            '& .special-label': {
                                color: colors.primaryMain,
                            },
                            '& .form-control': {
                                borderColor: colors.primaryMain,
                                boxShadow: `0 0 0 1px ${colors.primaryMain}`,
                            },
                        },
                    },
                    '& .autocomplete-dropdown-container': {
                        position: 'absolute',
                        top: '74%',
                        left: 'auto',
                        width: '100%',
                        zIndex: '9',
                        '& > div': {
                            backgroundColor: colors.commonWhite,
                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                            borderRadius: '2px',
                            '&:empty': {
                                display: 'none',
                            },
                        },
                        '& .suggestion-item': {
                            padding: '8px',
                        },
                        '& .suggestion-item--active': {
                            padding: '8px',
                            backgroundColor: colors.commonBlack,
                        },
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-sizeSmall': {
                        height: '16px',
                        '& .MuiChip-iconSmall': {
                            width: '0.857rem',
                            height: '0.857rem',
                            fontSize: '12px',
                            margin: '0 -4px 0 4px!important',
                        },
                        '& .MuiChip-icon + .MuiChip-labelSmall': {
                            padding: '0 6px 0 5px',
                        },
                        '& .MuiChip-labelSmall': {
                            padding: '0 11px 0 10px',
                            fontSize: '10px',
                            lineHeight: '1',
                            textTransform: 'uppercase',
                            letterSpacing: '0.25px',
                        },
                    },
                    '&.MuiChip-sizeMedium': {
                        height: '19px',
                        '& .MuiChip-iconMedium': {
                            fontSize: '12px',
                            margin: '0 -4px 0 4px!important',
                        },
                        '& .MuiChip-icon + .MuiChip-labelMedium': {
                            padding: '0 6px 0 5px',
                        },
                        '& .MuiChip-labelMedium': {
                            padding: '0 12px 0 11px',
                            fontSize: '11px',
                            textTransform: 'uppercase',
                            letterSpacing: '0.25px',
                        },
                    },
                    '&.MuiChip-filledDefault': {
                        backgroundColor: colors.defaultChip,
                        color: colors.defaultText,
                    },
                    '&.MuiChip-filledPrimary': {
                        backgroundColor: colors.primaryChip,
                        color: colors.primaryDark,
                    },
                    '&.MuiChip-filledInfo': {
                        backgroundColor: colors.infoChip,
                        color: colors.infoDark,
                    },
                    '&.MuiChip-filledWarning': {
                        backgroundColor: colors.warningChip,
                        color: colors.warningDark,
                    },
                    '&.MuiChip-filledError': {
                        backgroundColor: colors.errorChip,
                        color: colors.errorDark,
                    },
                    '&.MuiChip-filledSuccess': {
                        backgroundColor: colors.successChip,
                        color: colors.successDark,
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    marginTop: '0!important',
                    '& .MuiCardHeader-root': {
                        backgroundColor: colors.primaryMain,
                        borderRadius: '8px 8px 0 0',
                        padding: '10px 16px',
                        '& .MuiCardHeader-title': {
                            fontSize: '0.786rem',
                            lineHeight: '0.857rem',
                            letterSpacing: '0.6px',
                            color: colors.commonWhite,
                        },
                    },
                    '& .MuiCardContent-root': {
                        padding: '0',
                        // padding: '14px 0',
                        '& .MuiTypography-body1': {
                            fontSize: '0.857rem',
                            color: colors.textPrimary,
                        },
                        '& .MuiTypography-body2': {
                            fontSize: '0.714rem',
                            color: colors.greyA400,
                            lineHeight: '1.286rem',
                            letterSpacing: '0.5px',
                        },
                    },
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    borderTop: '1px solid',
                    borderColor: colors.greyA200,
                },
            },
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    height: 'auto',
                    minHeight: '72px',
                    zIndex: '15',
                    boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.1)',
                    '& .MuiButton-root': {
                        borderRadius: '6px',
                        flex: '1',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        '&.MuiButton-text': {
                            minWidth: 'auto',
                            fontFamily: 'Graphik',
                            fontWeight: '400',
                            fontSize: '0.786rem',
                            flex: '0',
                            color: colors.textPrimary,
                            textDecoration: 'underline',
                            paddingLeft: '0',
                            paddingRight: '0',
                            '&:hover,&:focus': {
                                backgroundColor: 'transparent',
                            },
                        },
                    },
                    '& .MuiAlert-root': {
                        marginBottom: '0.571rem',
                    },
                    '& .MuiFilledInput-root': {
                        '&.MuiInputBase-colorPrimary': {
                            backgroundColor: colors.primaryMain,
                            borderRadius: '6px',
                            border: 'none!important',
                            '& .MuiSelect-nativeInput': {
                                border: 'none',
                            },
                            '& .MuiSelect-select': {
                                fontFamily: 'GraphikMedium',
                                padding: '8px 80px 12px 40px',
                                textAlign: 'center',
                                color: colors.commonWhite,
                                maxWidth: '50px',
                            },
                            '& .MuiSelect-icon': {
                                width: '39px',
                                height: '100%',
                                top: '0px',
                                right: '0px',
                                padding: '1px 2px 0',
                                color: colors.commonWhite,
                                borderLeft: '1px solid rgba(244, 244, 244, 0.21)',
                            },
                        },
                        '&:hover': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                // border: 'none',
                            },
                        },
                        '&:before, &:after': {
                            display: 'none',
                        },
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    '&.MuiAlert-outlinedWarning': {
                        borderColor: colors.warningMain,
                        backgroundColor: colors.warningLight,
                        padding: '0 8px',
                        '& .MuiAlert-message': {
                            color: colors.warningText,
                            textAlign: 'center',
                            padding: '6px 8px 8px',
                        },
                    },
                    '&.MuiAlert-standardWarning': {
                        padding: '0 8px',
                        '& .MuiAlert-message': {
                            color: colors.warningText,
                            textAlign: 'center',
                            padding: '6px 8px 8px',
                        },
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    margin: '0!important',
                    padding: '4px 0!important',
                    display: 'flex',
                    flexDirection: 'column',
                    '& .MuiListItem-root': {
                        padding: '1.071rem 1.143rem 1rem',
                        whiteSpace: 'inherit',

                        '&:nth-child(even)': {
                            backgroundColor: colors.grey100,
                        },
                        '& .MuiTypography-body1': {
                            lineHeight: '1.429',
                        },
                    },
                    '& .MuiMenuItem-root': {
                        minHeight: 'auto',
                        padding: '6px 12px 4px',
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.textPrimary,
                    },
                    '& .Mui-disabled': {
                        '& .MuiAutocomplete-endAdornment': {
                            opacity: '.6',
                        },
                    },
                    '& .MuiAutocomplete-endAdornment': {
                        top: 'calc(50% - 18px)!important',
                    },
                    '& .MuiInputBase-sizeSmall': {
                        '& .MuiInput-input': {
                            fontSize: '0.857rem',
                            padding: '0 4px',
                        },
                    },
                    '& .MuiAutocomplete-inputRoot': {
                        //paddingRight: '18px!important',
                    },
                    '&-popper': {
                        whiteSpace: 'nowrap',
                    },
                    '& .MuiInputLabel-root': {
                        transform: 'translate(14px, 12px) scale(1)',
                        '&.MuiInputLabel-shrink': {
                            transform: 'translate(14px, -9px) scale(.75)',
                        },
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    '& .fc-header-toolbar': {
                        justifyContent: 'center',
                        padding: '0.429rem 0 1.071rem',
                        margin: '0!important',
                        '& .fc-center': {
                            padding: '0 .5rem',
                            '& h2': {
                                fontSize: '1rem!important',
                                lineHeight: '1.1rem',
                                fontWeight: 'normal',
                                color: colors.grey900,
                                textTransform: 'uppercase',
                                letterSpacing: '.6px',
                            },
                        },
                        '& .fc-button-primary': {
                            backgroundColor: 'transparent',
                            border: 'none',
                            color: colors.grey900,
                            '&.fc-button': {
                                padding: '0 .5rem',
                                '& .fc-icon': {
                                    marginTop: '0.071rem',
                                    fontSize: '2rem!important',
                                },
                                '&:hover,&:focus,&:active': {
                                    boxShadow: 'none',
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    color: colors.grey900,
                                },
                            },
                        },
                    },
                    '& .fc-view-container': {
                        backgroundColor: colors.commonWhite,
                        'th, td': {
                            borderColor: colors.greyA200,
                            borderWidth: '2px',
                        },
                        '& .fc-widget-header': {
                            '& th': {
                                fontSize: '0.786rem',
                                fontWeight: '400',
                                lineHeight: '1.714rem',
                                color: colors.greyA700,
                                textTransform: 'uppercase',
                                letterSpacing: '.2px',
                            },
                        },
                        '& .fc-day-number': {
                            fontSize: '1rem',
                            lineHeight: '1.429rem',
                            letterSpacing: '.15px',
                        },
                        '& .fc-event': {
                            borderRadius: '0.286rem',
                            '&.fc-day-grid-event': {
                                margin: '1.22px 0 0!important',
                                padding: '0 1px 1px 4px!important',
                                '& .fc-title': {
                                    fontSize: '.786rem',
                                    lineHeight: '0.864rem',
                                    textTransform: 'uppercase',
                                    letterSpacing: '.4px',
                                },
                            },
                        },
                        '& .fc-day-top': {
                            textAlign: 'center',
                            '& .fc-day-number': {
                                display: 'block',
                                padding: '0 1px 1px!important',
                                float: 'none!important',
                            },
                        },
                        '& .fc-view': {
                            '& .fc-head td': {
                                borderTop: 'none!important',
                                borderLeft: 'none!important',
                                borderRight: 'none!important',
                            },
                            '& .fc-widget-content': {
                                borderBottom: 'none!important',
                                borderLeft: 'none!important',
                            },

                            '& .fc-body': {
                                '& .fc-widget-content:not(.fc-day)': {
                                    borderRight: 'none!important',
                                },
                            },
                        },
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: '.5rem!important',
                    alignSelf: 'center',
                    '&:hover svg path': {
                        stroke: 'none',
                        fill: colors.primaryMain,
                    },
                    '&:hover .icon-outlined path': {
                        stroke: colors.primaryMain,
                        fill: 'none',
                    },
                    '&.MuiIconButton-edgeEnd': {
                        //marginRight: '-12px!important'
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    // minHeight: '44px!important',
                    '& .MuiTab-root': {
                        color: colors.grey500,
                        fontSize: '1rem',
                        fontFamily: 'Graphik',
                        background: colors.commonWhite,
                        minHeight: 'auto!important',
                        // minHeight: '44px!important',
                        display: 'flex',
                        alignItems: 'center',
                        // padding: '13px 16px 12px!important',
                        zIndex: '1',
                        lineHeight: '1.2',
                        '&.Mui-selected': {
                            fontFamily: 'GraphikMedium',
                            color: colors.primaryMain,
                        },
                    },

                    '&.tabsPill': {
                        padding: '1.286rem 1.286rem',
                        backgroundColor: 'white',
                        '& .MuiTabs-scroller': {
                            backgroundColor: 'rgba(118, 118, 128, 0.12)',
                            // border: '1px solid rgba(0, 0, 0, 0.04)',
                            borderRadius: '8px',
                            padding: '0 2px',
                            '& .MuiTabs-flexContainer': {
                                height: '100%',
                            },
                            '& .MuiTab-root': {
                                flexGrow: '1',
                                fontFamily: 'GraphikMedium',
                                // minHeight: '42px!important',
                                color: 'black',
                                padding: '12px 16px 13px!important',
                                backgroundColor: 'transparent',
                                '&::after, &::before': {
                                    content: '""',
                                    position: 'absolute',
                                    right: '0',
                                    top: '8px',
                                    bottom: '8px',
                                    background: 'rgba(60, 60, 67, 0.36)',
                                    width: '1px',
                                },
                                '&::before': {
                                    left: 0,
                                    right: 'auto',
                                },
                                '&.Mui-selected': {
                                    color: 'black',
                                    '&::after, &::before': {
                                        display: 'none',
                                    },
                                    '& + .MuiTab-root::before': {
                                        display: 'none',
                                    },
                                },
                                '&:last-of-type': {
                                    '&::after': {
                                        display: 'none',
                                    },
                                },
                                '&:first-of-type': {
                                    '&::before': {
                                        display: 'none',
                                    },
                                },
                                '&:not(:last-of-type)::after': {
                                    display: 'none',
                                },
                            },
                            '& .MuiTabs-indicator': {
                                height: 'auto',
                                top: '2px',
                                bottom: '2px',
                                backgroundColor: '#FFFFFF',
                                border: '1px solid rgba(0, 0, 0, 0.04)',
                                boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)',
                                borderRadius: '7px',
                            },
                        },
                        '&.tabsPillSmall': {
                            padding: '0 16px 16px',
                            marginBottom: '16px',
                            '& .MuiTab-root': {
                                padding: '6px 2px 7px!important',
                                // minHeight: '32px!important',
                            },
                        },
                    },
                    '& + [role="tabpanel"]': {
                        '& .MuiAccordion-root': {
                            boxShadow: 'none',
                            borderRadius: '0!important',
                            margin: '0 0 16px',
                            '&.Mui-expanded': {
                                margin: '0 0 16px',
                            },
                            '&::before': {
                                opacity: '0',
                            },
                        },
                        '& .MuiAccordionSummary-root': {
                            // fontSize: '1rem',
                            // color: colors.textPrimary,
                            padding: '0 16px',
                            minHeight: 'auto',
                            '& .MuiAccordionSummary-expandIconWrapper': {
                                '& .MuiSvgIcon-root': {
                                    fontSize: '2rem',
                                },
                            },
                        },
                        '& .MuiAccordionSummary-content': {
                            margin: '13px 0 14px',
                            '&.Mui-expanded': {
                                // margin: '7px 0',
                            },
                        },
                        '& .MuiAccordionDetails-root': {
                            // padding: '0 16px 17px',
                            padding: '0',
                        },
                    },
                },
            },
        },

        MuiCollapse: {
            styleOverrides: {
                root: {
                    borderTop: '1px solid',
                    borderTopColor: colors.grey200,
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '0!important',
                    '& .MuiDivider-root:last-of-type': {
                        border: 'none!important',
                    },
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-root': {
                        border: 'none!important',
                        verticalAlign: 'top',
                        padding: '13px 0 13px 16px',
                        '& .MuiInputBase-inputAdornedStart': {
                            padding: '0!important',
                            height: '1.929rem',
                            fontFamily: 'Koor',
                            fontSize: '1.714rem',
                        },
                        '& .MuiInputAdornment-positionStart': {
                            alignSelf: 'flex-start',
                            marginTop: '0.786rem',
                            marginRight: '4px',
                            '& .MuiTypography-root': {
                                fontSize: '0.857rem',
                                lineHeight: '1rem',
                                fontFamily: 'Graphik',
                            },
                        },
                        '& .MuiInput-root': {
                            '&:before,&:after': {
                                bottom: '-8px',
                                left: '12px',
                            },
                            '&:before': {
                                borderColor: colors.primaryLight,
                            },
                        },
                        '& .MuiSelect-select': {
                            paddingRight: '30px!important',
                        },
                        '& .MuiSelect-icon': {
                            right: '5px',
                            fill: colors.secondaryMain,
                            '&:hover': {
                                fill: colors.primaryMain,
                            },
                        },
                    },
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    '& h2': {
                        color: colors.primaryMain,
                        fontSize: '1.4rem',
                        marginBottom: '12px',
                    },
                    '& p.MuiTypography-body1': {
                        fontSize: '0.875rem',
                        lineHeight: '1.429',
                        color: colors.grey800,
                        marginBottom: '10px',
                    },
                },
            },
        },
    },
});
