import {Alert, BottomNavigation, Button, Divider, Stack, Tab, Tabs, Typography} from '@mui/material';

import {makeStyles} from '@mui/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {ValidatorForm} from 'react-material-ui-form-validator';

import {BOOKING_REQUEST, COMPONENTS} from 'src/constants/ApiEndPoint';
import {COMMON_CODES} from 'src/constants/StatusCode';
import {fetchRecord, postRecord, putRecord} from 'src/services/Axios';
import {calculateTotal, calculateWithoutTax, taxAmount} from './TaxUtil';
import BookingRequestButtonGroup from './BookingRequestButtonGroup';
import {RATE_CARD_CHANGES_SUBMITTED_BY_BOOKER, RATE_CARD_SUBMITTED_TO_CREW} from 'src/constants';
import BookingEstimateView from './BookingEstimateView';
import BookingRates from './BookingRates';
import Header from './Header';
import SimpleBackdrop from 'src/components/Common/SimpleBackdrop';
import {useTheme} from '@mui/material/styles';
import {getLoggedInUser} from "../../../services/Storage";
import {Mixpanel} from "../../../services/MixPanelService";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    // root: {
    //     width: '100%',
    //     maxWidth: 500,
    // },
    // cartHeader: {
    //     margin: theme.spacing(2),
    // },
    // option: {
    //     display: 'flex',
    //     alignItems: 'flex-start',
    //     //padding: theme.spacing(2),
    //     '& + &': {
    //         marginTop: '8px',
    //     },
    // },
    // selectedOption: {
    //     //backgroundColor: colors.grey[50]
    // },
    // optionRadio: {
    //     margin: -10,
    // },
    // optionDetails: {
    //     display: 'flex',
    //     justifyContent: 'flex-start',
    //     marginLeft: theme.spacing(2),
    //     '& .MuiTypography-body1': {
    //         fontWeight: '400',
    //         fontSize: 12,
    //     },
    // },
    // typography: {
    //     fontSize: '14px',
    //     fontWeight: '500',
    //     margin: '6px 0px 8px',
    // },
    // captionText: {
    //     color: '#999999',
    //     display: 'block',
    //     textAlign: 'center',
    // },
    // gridWrapper: {
    //     margin: '10px 0',
    // },
    // tooltip: {
    //     marginLeft: '4px',
    //     fontSize: 20,
    //     color: theme.palette.text.secondary,
    //     display: 'inline-block',
    //     verticalAlign: 'text-bottom',
    //     '&:hover': {
    //         color: theme.palette.secondary.main,
    //     },
    // },
    // // formWrap: {
    // //   overflowY: 'auto',
    // //   overflowX: 'hidden'
    // // },
    // tabItemHeader: {
    //     opacity: 1,
    //     paddingBottom: 5,
    // },
    // tabsHeader: {
    //     '& .MuiTabs-flexContainer': {
    //         display: 'flex',
    //         //   justifyContent: 'center',
    //         '& .MuiTab-root': {
    //             // paddingLeft: 12,
    //             minWidth: 'auto',
    //             color: '#999999',
    //             fontSize: 12,
    //         },
    //         '& .Mui-selected': {
    //             fontWeight: 500,
    //         },
    //     },
    //     '& .MuiTabs-indicator': {
    //         backgroundColor: '#379B87',
    //         height: 2,
    //         color: '#999999',
    //     },
    //     '& .MuiTab-root.Mui-selected': {
    //         color: 'black !important',
    //     },
    // },
    // leftDiv: {
    //     color: 'gray',
    //     fontSize: '12px',
    //     display: 'flex',
    //     justifyContent: 'end',
    // },
    // rightDiv: {
    //     fontSize: '12px',
    //     display: 'flex',
    //     justifyContent: 'end',
    // },
    // captionWrap: {
    //     display: 'flex',
    //     justifyContent: 'end',
    // },
    // headingWrap: {
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     height: '66px',
    //     alignItems: 'center',
    // },
    // chipDone: {
    //     backgroundColor: '#379B87',
    //     color: '#FFFFFF',
    //     // fontSize: 13,
    //     margin: theme.spacing(2),
    // },
}));

function RatesAndEstimates(props) {
    const classes = useStyles();
    const theme = useTheme();
    const formRef = useRef();

    const { booking, refresh, production } = props;
    const {productionInfo} = booking;
    const { bookingRates, bookingTimes, bookingEquipments, bookingExpenses } = booking;

    const setBookingRates = () => {};
    const setBooking = () => {};
    const [currentTab, setCurrentTab] = useState(0);
    const taxList = [
        { label: '0%', value: 0 },
        { label: '10%', value: 10 },
        { label: '20%', value: 20 },
    ];
    const [times, setTimes] = useState();
    const [equipments, setEquipments] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [emptyTaskType, setEmptyTaskType] = useState(false);
    const [emptyValues, setEmptyValues] = useState(false);
    const [emptyEquipmentValues, setEmptyEquipmentValues] = useState(false);
    const [emptyExpenseValues, setEmptyExpenseValues] = useState(false);
    const [requestRates, setRequestRates] = useState(booking.isInitialRequestedRates);
    const [hideApprove, setHideApprove] = useState(false);
    const [initialRequestedRates, setInitialRequestedRates] = useState(booking.isInitialRequestedRates);
    const [showEstimateView, setShowEstimateView] = useState(true);
    const [useExistingRates, setUseExistingRates] = useState(false);
    const [total, setTotal] = useState({
        timesTotal: 0,
        equipmentsTotal: 0,
        expensesTotal: 0,
        tax: 0,
    });
    const [disableButton, setDisableButton] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);
    let otRateList = [
        { label: '(@1.5x)', value: '1.5' },
        { label: '(@2.0x)', value: '2.0' },
    ];
    const hoursList = [
        { label: '8 hr day', value: '8' },
        { label: '10 hr day', value: '10' },
        { label: '12 hr day', value: '12' },
    ];

    useEffect(() => {
        console.log('booking=====>', booking);

        setShowEstimateView(booking.showEstimateView);
        setUseExistingRates(booking.useExistingRates);
        changeBookingRates();

        if (requestRates && initialRequestedRates) {
            setDisableButton(true);
        }
    }, []);

    useEffect(() => {
        console.log('booking=====>', booking);

        setShowEstimateView(booking.showEstimateView);
        setUseExistingRates(booking.useExistingRates);
        changeBookingRates();

        if (requestRates && initialRequestedRates) {
            setDisableButton(true);
        }
    }, [booking]);
    const [tasks, setTasks] = useState([]);
    // const [unitList, setUnitList] = useState([
    //     { label: 'Day', value: 'day' },
    //     { label: 'Week', value: 'week' }
    // ])

    const fetchTaskTypes = async (rates) => {
        let response = await fetchRecord(COMPONENTS.TASK_TYPES);
        console.log('response======response====>', response);
        console.log('response======response====>', booking.useExistingRates);
        if (response.status === 200) {
            setTasks(response.data);
            if (booking.useExistingRates) {
                prepareTimesFromRate(response.data, rates);
            } else {
                changeBookingTimes();
            }
        }
    };

    const getByTaskType = (time, rates1) => {
        console.log('tt#====>', time.taskType);
        console.log('tt#====>', rates1);
        var tt = undefined;
        if (time.taskType.title) {
            tt = rates1.find((rate) => rate.taskType.title == time.taskType.title);
        } else {
            tt = rates1.find((rate) => rate.taskType._id == time.taskType);
        }
        console.log('tt#====>', tt);
        if (tt) {
            return {
                ...time,
                rate: tt.rate,
                otRate: tt.otRate,
                tax: tt.tax,
                per: tt.per,
                hours: time.hours == null ? 0 : time.hours,
                disabled: true,
            };
        } else {
            return { ...time, disabled: false };
        }
    };
    const prepareTimesFromRate = (taskArray, rates1) => {
        const mapped = bookingTimes.map((item) => {
            const fromRate = getByTaskType(item, rates1);
            console.log('times==before fromRate==>', fromRate);
            return {
                ...fromRate,
                date: fromRate.date,
                hours: fromRate.hours == null ? 0 : fromRate.hours,
                id: fromRate._id,
                ot: fromRate.ot,
                otRate: fromRate.otRate.label
                    ? fromRate.otRate
                    : { value: fromRate.otRate, label: `@${fromRate.otRateBaseValue}` },
                per: fromRate.per.label ? fromRate.per : { value: fromRate.per, label: `${fromRate.per} hr day` },
                rate: `${fromRate.rate}`,
                taskType: fromRate.taskType == '' ? taskArray[0] : findTaskTypeObject(fromRate, taskArray),
                tax: fromRate.tax.label ? fromRate.tax : { value: fromRate.tax, label: `${fromRate.tax}%` },
                taxAmount: taxAmount(fromRate),
                totalWithoutTax: calculateWithoutTax(fromRate),
                total: calculateTotal(fromRate),
            };
        });

        const mappedWithTotal = mapped.map((item) => {
            return {
                ...item,
                taxAmount: taxAmount(item),
                totalWithoutTax: calculateWithoutTax(item),
                total: calculateTotal(item),
            };
        });
        console.log('mapping items booking time--->', mappedWithTotal);
        setTimes(mappedWithTotal);

        calculateTimeTotal(mappedWithTotal);
        if (bookingEquipments) {
            let updatedBookingEquipments = changeBookingEquipments();
            setEquipments([].concat.apply([], updatedBookingEquipments));
            calculateEquipmentTotal(updatedBookingEquipments);
            //calculateTax(updatedBookingTimes);
        }
        console.log('exxx--be>', bookingExpenses);
        if (bookingExpenses) {
            if (bookingExpenses.length > 0) {
                const updatedExpenses = bookingExpenses.map((item) => {
                    return {
                        ...item,

                        total: item.qty * item.rate,
                    };
                });
                setExpenses([].concat.apply([], updatedExpenses));
                console.log('exxx--eu>', updatedExpenses);
                calculateExpenseTotal(updatedExpenses);
            }

            //  calculateTax(updatedBookingTimes);
        }
        //setTimes(mapped);
    };

    const findTaskTypeObject = (item, taskArray) => {
        if (typeof item.taskType == 'object') {
            return item.taskType;
        }
        // console.log('found---id>', `'${item.taskType}'`);
        // console.log('found---array>', taskArray);
        const obj = taskArray.find((task) => task._id == item.taskType);
        //  console.log('found---obj>', obj);
        if (obj) {
            return {
                _id: obj._id,
                title: obj.title,
            };
        } else return taskArray[1];
    };

    const changeBookingRates = () => {
        const updatedBookingRates = bookingRates.map((item) => {
            return {
                ...item,
                taskType: item.taskTypeInfo,
                otRate: {
                    value: item.otRate,
                    label: `(@${item.otRateBaseValue}x)`,
                },
                tax: { label: `${item.tax}%`, value: item.tax },
                per:
                    item.per == 1 && item.otRateBaseValue == 0
                        ? { label: 'Hour', value: item.per }
                        : item.per == 1 && item.otRateBaseValue != 0
                        ? ''
                        : { label: `${item.per} hr day`, value: item.per },
                total: item.qty * item.rate,
            };
        });
        setRates([].concat.apply([], updatedBookingRates));
        fetchTaskTypes(updatedBookingRates).then();
    };

    const changeBookingTimes = () => {
        const updatedBookingTimes = bookingTimes.map((item) => {
            return {
                ...item,
                taskType: item.taskTypeInfo,
                tax: { label: `${item.tax}%`, value: item.tax },
                per:
                    item.per == 1 && item.otRateBaseValue == 0
                        ? { label: 'Hour', value: item.per }
                        : item.per == 1 && item.otRateBaseValue != 0
                        ? ''
                        : { label: `${item.per} hr day`, value: item.per },
                otRate:
                    item.otRate !== 0 && item.otRateBaseValue !== 0
                        ? {
                              label: `(@${item.otRateBaseValue}x)`,

                              value: item.otRate,
                          }
                        : item.otRate == 0 && item.otRateBaseValue !== 0
                        ? { value: item.otRate, label: `(@${item.otRateBaseValue}x)` }
                        : { value: 'NA', label: '(@1.5x)' },
                total:
                    item.per == '1' || item.ot == 0
                        ? item.rate * item.hours + (item.rate * item.hours * item.tax) / 100
                        : item.rate + item.otRate * item.ot + ((item.rate + item.otRate * item.ot) * item.tax) / 100,
                taxableAmount:
                    item.per == '1' || item.ot == 0
                        ? (item.rate * item.hours * item.tax) / 100
                        : ((item.rate + item.otRate * item.ot) * item.tax) / 100,
            };
        });
        setTimes([].concat.apply([], updatedBookingTimes));
        calculateTimeTotal(updatedBookingTimes);
        if (bookingEquipments) {
            let updatedBookingEquipments = changeBookingEquipments();
            setEquipments([].concat.apply([], updatedBookingEquipments));
            calculateEquipmentTotal(updatedBookingEquipments);
            //calculateTax(updatedBookingTimes);
        }
        console.log('exxx--be>', bookingExpenses);
        if (bookingExpenses) {
            if (bookingExpenses.length > 0) {
                const updatedExpenses = bookingExpenses.map((item) => {
                    return {
                        ...item,

                        total: item.qty * item.rate,
                    };
                });
                setExpenses([].concat.apply([], updatedExpenses));
                console.log('exxx--u>', updatedExpenses);

                calculateExpenseTotal(updatedExpenses);
            }

            //  calculateTax(updatedBookingTimes);
        }
    };

    const changeBookingEquipments = () => {
        return bookingEquipments.map((item) => {
            return {
                ...item,
                total: item.rate * item.qty,
            };
        });
    };

    const handleTabsChange = (event, value) => {
        setCurrentTab(value);
    };

    const onAddKeyDates = (name) => {
        if (name === 'rates') {
            setRates([
                ...rates,
                {
                    id: new Date().getTime(),
                    taskType: '',
                    rate: '',
                    per: '',
                    otRate: { value: 0, label: '(@1.5x)' },
                    tax: taxList[0],
                },
            ]);
        }
    };

    const removeKeyDates = (id) => {
        if (rates.length > 0) {
            const removed = rates.filter((kd) => (kd.id ? kd.id !== id : kd._id !== id));
            setRates(removed);
            setShowSubmit(true);
            setDisableButton(false);
        }
    };

    const onKeyDatesValueChange = (value, index) => {
        // console.log('value---->', value);
        // console.log('index---->', value.id, times);
        let updatedData = [...times];
        updatedData.map((x, keyIndex) => {
            if (keyIndex === index) {
                updatedData[index] = value.id || value._id ? value : { ...updatedData[index], ...value };
            }
        });
        setTimes(updatedData);
    };

    const handleExpenseFieldChange = (event, name, value) => {
        setRates((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const onRatesValueChange = (value, index) => {
        let updatedData = [...rates];
        updatedData.map((x, keyIndex) => {
            if (keyIndex === index) {
                updatedData[index] = value.id || value._id ? value : { ...updatedData[index], ...value };
            }
        });
        setRates(updatedData);
        setDisableButton(false);
    };

    const calculateTimeTotal = (times) => {
        console.log('calculateTimeTotal===>', times);
        setTotal((prevValues) => ({
            ...prevValues,
            tax: times.reduce((a, { taxAmount }) => a + taxAmount, 0),
            timesTotal: times.reduce((a, { totalWithoutTax }) => a + totalWithoutTax, 0),
        }));
    };

    const calculateEquipmentTotal = (equipmentValues) => {
        setTotal((prevValues) => ({
            ...prevValues,
            equipmentsTotal: equipmentValues.reduce((a, { total }) => a + total, 0),
        }));
    };

    const calculateExpenseTotal = (expenses) => {
        setTotal((prevValues) => ({
            ...prevValues,
            expensesTotal: expenses.reduce((a, { total }) => a + total, 0),
        }));
    };

    // const calculateTax = (times) => {
    //     console.log("taxxxxx--->",times)
    //     setTotal((prevValues) => ({
    //         ...prevValues,
    //         tax: times.reduce((a, { taxAmount }) => (taxAmount ? a + taxAmount : a + 0), 0),
    //     }));
    // };

    const makeChanges = () => {
        setRequestRates(true);
        setInitialRequestedRates(true);
        setDisableButton(true);
        if (booking.bookingRates.length > 0 && booking.bookingRates[0].isDraftFromCrew) {
            setShowSubmit(true);
        }
    };

    const onCancel = () => {
        setRequestRates(false);
        setInitialRequestedRates(false);
        changeBookingRates();
        setDisableButton(false);
        setShowSubmit(false);
    };

    const handleSave = async () => {
        if (formRef && formRef.current) {
            formRef.current.isFormValid(false).then(async (isValid) => {
                if (isValid) {
                    await saveRates(true);
                    setRequestRates(false);
                    setShowSubmit(true);
                    await refresh();
                }
            });
        }
    };

    const handleSubmit = async () => {
        if (formRef && formRef.current) {
            formRef.current.isFormValid(false).then(async (isValid) => {
                if (isValid) {
                    await saveRates(false);
                    setRequestRates(false);
                    setHideApprove(true);
                    setShowSubmit(false);
                    setDisableButton(true);
                    const loggedInUser = getLoggedInUser();
                    const mixpanelObject = {
                        loggedInUserId: loggedInUser._id,
                        productionTitle: productionInfo.title
                    }
                    Mixpanel.SubmitBookingRates.fire(mixpanelObject);
                    await refresh();
                }
            });
        }
    };

    const saveRates = async (saveOnly) => {
        setLoading(true);
        let submittedRates = rates.map((obj, index) => {
            return { ...obj, taskType: obj.taskType._id };
        });

        let response = await postRecord(
            BOOKING_REQUEST.SET_RATES,
            { bookingRates: submittedRates, saveOnly },
            booking._id,
        );
        if (response) {
            if (response.status === COMMON_CODES.SUCCESS) {
                setBookingRates(response.data.bookingRates);
                setBooking(response.data);
                setInitialRequestedRates(false);
            }
            setLoading(false);
        }
    };

    const approveRates = async () => {
        setLoading(true);
        let response = await putRecord(BOOKING_REQUEST.RESPOND_RATES, booking._id, 'approve');
        if (response) {
            if (response.status === COMMON_CODES.SUCCESS) {
                setBookingRates(response.data.bookingRates);
                setBooking(response.data);
            }
            setLoading(false);
        }
        await refresh();
    };

    const handleMenuItemClick = async (event, index) => {
        let responseText = index === 0 ? 'reject' : 'needsDiscussion';
        setLoading(true);
        let response = await putRecord(BOOKING_REQUEST.RESPOND_RATES, booking._id, responseText);
        if (response) {
            if (response.status === COMMON_CODES.SUCCESS) {
                setBookingRates(response.data.bookingRates);
                setBooking(response.data);
            }
            setLoading(false);
        }
        await refresh();
    };

    return (
        <div>
            {loading && <SimpleBackdrop />}
            <div className={classes.headingWrap}>
                <Header
                    onBackClick={props.hideRates}
                    booking={booking}
                    title={currentTab == '0' ? 'Rates' : 'Estimates'}
                />
            </div>
            <Divider />
            <Stack>
                <Tabs
                    sx={{ mb: '0.571rem' }}
                    className={classes.tabsHeader}
                    onChange={handleTabsChange}
                    scrollButtons="auto"
                    value={currentTab}
                    variant="fullWidth"
                    indicatorColor="primary"
                >
                    <Tab key="0" label="Rate Card" className={classes.tabItemHeader} />
                    {showEstimateView && <Tab key="1" label="Estimate View" className={classes.tabItemHeader} />}
                </Tabs>

                <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
                    <div
                        style={{
                            // height: '300px',
                            //padding: '0 16px',
                            overflow: 'hidden auto',
                        }}
                    >
                        {booking.isInitialRequestedRates && !booking.hasCrewSubmittedRates && (
                            <Alert
                                variant="outlined"
                                severity="warning"
                                icon={false}
                                sx={{ mb: '0.571rem', maxWidth: '241px', marginLeft: 'auto', marginRight: 'auto' }}
                            >
                                The booker has requested you submit your rates for approval.
                            </Alert>
                        )}
                        {currentTab === 0 && rates && (
                            <BookingRates
                                refresh={refresh}
                                rates={rates}
                                onAddKeyDates={onAddKeyDates}
                                removeKeyDates={removeKeyDates}
                                loading={loading}
                                handleFieldChange={handleExpenseFieldChange}
                                onRatesValueChange={onRatesValueChange}
                                emptyValues={emptyExpenseValues}
                                onKeyDatesValueChange={onKeyDatesValueChange}
                                hoursList={hoursList}
                                otRateList={otRateList}
                                taxList={taxList}
                                requestRates={requestRates}
                                hasCrewSubmittedRates={booking.hasCrewSubmittedRates}
                                booking={booking}
                                initialRequestedRates={initialRequestedRates}
                                production={production}
                            />
                        )}
                        {currentTab === 1 && times && showEstimateView && (
                            <BookingEstimateView
                                refresh={refresh}
                                items={times}
                                taxList={taxList}
                                emptyValues={emptyValues}
                                hoursList={hoursList}
                                otRateList={otRateList}
                                equipments={equipments}
                                expenses={expenses}
                                useExistingRates={useExistingRates}
                            />
                        )}
                    </div>
                    {/* <ModalFooter> */}
                    <BottomNavigation
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            p: 2,
                            borderRadius: 0,
                            display: 'block',
                        }}
                    >
                        {currentTab !== 1 && requestRates && initialRequestedRates && (
                            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="stretch">
                                <Button onClick={() => onCancel()}>Cancel</Button>
                                <Button variant="contained" onClick={handleSave} disabled={disableButton} color="grey">
                                    Save
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginLeft: '6px' }}
                                    type="submit"
                                    disabled={disableButton && !showSubmit}
                                >
                                    Submit
                                </Button>
                            </Stack>
                        )}
                        {/* {currentTab !== 1 && !initialRequestedRates && (
                            <Alert
                                icon={false}
                                severity="warning"
                                style={{
                                    backgroundColor: '#FAF4E5',
                                    color: '#702832',
                                    border: '1px solid #F1D5AF',
                                }}
                            >
                                Changes made will reset approvals and previous versions
                            </Alert>
                        )} */}
                        {currentTab !== 1 && !initialRequestedRates && (
                            // booking.isPublished &&
                            // booking.hasCrewSubmittedRates &&
                            <>
                                <Alert variant="outlined" severity="warning" icon={false}>
                                    Changes made will reset previous approvals and overwrite previous versions
                                </Alert>

                                <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="stretch">
                                    <Button fullWidth variant="contained" onClick={makeChanges} color="grey">
                                        Make Changes
                                    </Button>

                                    {booking.bookingRates.length > 0 && booking.bookingRates[0].isDraftFromCrew ? (
                                        // && !booking.hasCrewSubmittedRates
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            style={{ marginLeft: '6px' }}
                                            type="submit"
                                            disabled={disableButton && !showSubmit}
                                        >
                                            Submit
                                        </Button>
                                    ) : (
                                        (booking.rateCardStatus.includes(RATE_CARD_SUBMITTED_TO_CREW) ||
                                            booking.rateCardStatus.includes(RATE_CARD_CHANGES_SUBMITTED_BY_BOOKER) ||
                                            (booking.rateCardStatus.includes('TO_DO') && !booking.requestRates)) && (
                                            <BookingRequestButtonGroup
                                                handleClick={approveRates}
                                                handleMenuItemClick={handleMenuItemClick}
                                            />
                                        )
                                    )}
                                </Stack>
                            </>
                        )}
                        {currentTab === 1 && (
                            <Stack direction="column" spacing={0.75}>
                                <Stack direction="column">
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                                            TIME
                                        </Typography>
                                        <Typography
                                            variant="overline"
                                            component="div"
                                            sx={{ mb: '0.357rem', color: theme.palette.text.primary }}
                                        >
                                            ${isNaN(total.timesTotal) ? 0 : total.timesTotal}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                                            EQUIPMENT
                                        </Typography>
                                        <Typography
                                            variant="overline"
                                            component="div"
                                            sx={{ mb: '0.357rem', color: theme.palette.text.primary }}
                                        >
                                            ${isNaN(total.equipmentsTotal) ? 0 : total.equipments}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                                            EXPENSES
                                        </Typography>
                                        <Typography
                                            variant="overline"
                                            component="div"
                                            sx={{ mb: '0.357rem', color: theme.palette.text.primary }}
                                        >
                                            ${isNaN(total.expensesTotal) ? 0 : total.equipmentsTotal}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                                            TAX
                                        </Typography>
                                        <Typography
                                            variant="overline"
                                            component="div"
                                            sx={{ mb: '0.357rem', color: theme.palette.text.primary }}
                                        >
                                            ${isNaN(total.tax) ? 0 : total.tax}
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                                    <Typography
                                        variant="overline"
                                        component="div"
                                        sx={{ color: theme.palette.text.primary }}
                                    >
                                        TOTAL
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        component="div"
                                        sx={{ mb: '0.143rem', fontSize: '1.714rem', lineHeight: '2.2rem' }}
                                    >
                                        $
                                        {parseFloat(isNaN(total.timesTotal) ? 0 : total.timesTotal) +
                                            parseFloat(isNaN(total.equipmentsTotal) ? 0 : total.equipmentsTotal) +
                                            +parseFloat(isNaN(total.expensesTotal) ? 0 : total.expensesTotal) +
                                            parseFloat(isNaN(total.tax) ? 0 : total.tax)}
                                    </Typography>
                                </Stack>
                            </Stack>
                        )}
                    </BottomNavigation>
                </ValidatorForm>
            </Stack>
        </div>
    );
}

export default RatesAndEstimates;
