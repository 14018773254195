/* eslint-disable no-unused-vars */

import React, {useState} from 'react';
import {fetchRecord, postRecord} from '../../services/Axios';
import {AUTHENTICATION} from '../../constants/ApiEndPoint';
import {AUTH_ROUTES, BOOKING_ROUTES} from '../../constants/SiteRoutes';

import {useDispatch} from 'react-redux';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AUTH_CODES} from '../../constants/StatusCode';
import List from '@mui/material/List';
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
} from '@mui/material';
import {Navigate, useNavigate} from 'react-router';
import {getLoggedInUser} from '../../services/Storage';
import {accountLogin} from '../../services/Authorize';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SnackBarWithTimer from '../../components/Common/SnackBarWithTimer';
import {useTheme} from '@mui/material/styles';
import SimpleBackdrop from '../../components/Common/SimpleBackdrop';
import Header from '../../components/Header';
import {Mixpanel} from "../../services/MixPanelService";

function AccountChoice(props) {
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const user = getLoggedInUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const [email, setEmail] = React.useState('');
    const [userType, setUserType] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [resend, setResend] = React.useState(false);
    const [samePassword, setSamePassword] = React.useState(true);
    const [id, setId] = React.useState('');
    const [autoFocus, setAutoFocus] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [message, setMessage] = useState({});

    const handleListItemClick = async (item, index) => {
        setSelectedIndex(index);
        setEmail(item.email);
        setId(item._id);
        setUserType(item.userType);
        setLoading(true);
        setSamePassword(true);
        const response = await comparePassword(item);

        if (response.status === AUTH_CODES.SUCCESS) {
            await accountLogin(response.data);
            Mixpanel.MobileLoginEvent.fire(response.data.userInfo);
            navigate(BOOKING_ROUTES.INDEX);

            //dispatch(accountLogin(accountLogin));
            //window.location.href = getRedirectUrl(response);
        } else if (response.status === AUTH_CODES.SET_ACCOUNT_PASSWORD) {
            setSamePassword(false);
            setAutoFocus(true);
            setLoading(false);
            setResend(false);
        } else if (response.status === AUTH_CODES.ACCOUNT_NOT_VERIFIED) {
            setSamePassword(true);
            setResend(true);
            setLoading(false);
            //dispatch(setNotify(response));
            setMessage({ type: 'error', message: response.message });
            setShowSnackbar(true);
        } else {
            //dispatch(setNotify(response));
            setMessage({ type: 'error', message: response.message });
            setShowSnackbar(true);
            setLoading(false);
        }
    };

    async function handleSubmit(event) {
        const user = {
            _id: id,
            password: password,
        };
        setLoading(true);
        const response = await postRecord(AUTHENTICATION.LOGIN_BY_ID, user);
        if (response.status === AUTH_CODES.SUCCESS) {
            await accountLogin({ tokenInfo: response.data.tokenInfo, userInfo: response.data.userInfo });
            navigate(BOOKING_ROUTES.INDEX);
        } else if (response.status === AUTH_CODES.ACCOUNT_NOT_VERIFIED) {
            setResend(true);
            setLoading(false);
            setMessage({ type: 'error', message: response.message });
            setShowSnackbar(true);
        } else {
            setLoading(false);
            setMessage({ type: 'error', message: response.message });
            setShowSnackbar(true);
        }
    }

    const comparePassword = async (item) => {
        return await fetchRecord(AUTHENTICATION.COMPARE_PASSWORD, item._id, user.password);
    };

    return (
        <>
            {loading && <SimpleBackdrop />}
            {showSnackbar && (
                <SnackBarWithTimer
                    open={showSnackbar}
                    timer={6000}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}

            <Header type="general" showOptions={true} title={'Choose an Account'} />

            <Stack direction="column" spacing={2.6}>
                <div>
                    <List sx={{ bgcolor: 'background.paper', mt: '0.5rem!important', p: '0!important' }}>
                        {user.relatedAccounts ? (
                            user.relatedAccounts.map((item, key) => (
                                <>
                                    <ListItem
                                        key={key}
                                        button
                                        selected={selectedIndex === key}
                                        onClick={(event) => handleListItemClick(item, key)}
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={item.displayName}
                                                src={
                                                    item.photoUri
                                                        ? item.photoUri
                                                        : '/images/avatars/avatar-unregistered-user.svg'
                                                }
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item.displayName}
                                            secondary={item.email}
                                            sx={{ pr: '1rem' }}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                size="large"
                                                edge="end"
                                                color="inherit"
                                                aria-label="go button"
                                                onClick={(event) => handleListItemClick(item, key)}
                                            >
                                                <ArrowForwardIosIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider component="li" />
                                </>
                            ))
                        ) : !loading ? (
                            <Navigate to="/login" />
                        ) : (
                            ''
                        )}
                    </List>
                </div>

                {!samePassword && (
                    <ValidatorForm onSubmit={handleSubmit}>
                        <Stack
                            direction="column"
                            spacing={3}
                            bgcolor={theme.palette.background.paper}
                            px={2}
                            pt={2.5}
                            pb={4}
                        >
                            <Stack direction="column" spacing={2.6}>
                                <TextValidator
                                    autoFocus
                                    label="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter Password"
                                    helperText=""
                                    fullWidth
                                    name="password"
                                    type="password"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={password}
                                    validators={['required']}
                                    errorMessages={['Password is required.']}
                                    variant="outlined"
                                />
                                <Stack direction="column" spacing={0.8}>
                                    <Button
                                        color="primary"
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        disabled={loading}
                                    >
                                        Login
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </ValidatorForm>
                )}
                {resend && (
                    <Button variant="contained" color="secondary" href={AUTH_ROUTES.RESEND_VERIFICATION_EMAIL}>
                        Resend Verification Email
                    </Button>
                )}
            </Stack>
        </>
    );
}

export default AccountChoice;
