/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { fetchRecord } from '../../services/Axios';
import { BOOKING_REQUEST } from '../../constants/ApiEndPoint';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Header from './Header';
import BookerAndCrew from './BookerAndCrew';
import Stepper from './Stepper';
import BookingDescriptions from './BookingDescriptions';
import BookingDates from './BookingDates';
import { useNavigate } from 'react-router';
import { setSelectedBooking } from '../../services/Storage';
import { RatesAndEstimates } from './RatesAndEstimates';
import { PreferredCrew } from './PreferredCrew';

function BookingDetails() {
    // const { tab: currentTab, id: bookingId } = match.params;

    const [loading, setLoading] = useState(false);
    const [production, setProduction] = useState(null);
    const [booking, setBooking] = useState();
    const [productions, setProductions] = useState([]);
    const [bookerDetails, setBookerDetails] = useState();
    const [bookingDates, setBookingDates] = useState([]);
    const [user, setUser] = useState();
    const [showDescription, setShowDescription] = useState(false);
    const [showRates, setShowRates] = useState(false);
    const openRates = () => {
        setShowRates(true);
    };
    const hideRates = () => {
        setShowRates(false);
    };

    const [showDates, setShowDates] = useState(false);
    const [showPrefs, setShowPrefs] = useState(false);
    const [prefsEnabled, setPrefsEnabed] = useState(false);
    const [bookingRequestId, setBookingRequestId] = useState();
    const [currentId, setCurrentId] = useState();
    const navigate = useNavigate();
    const openDescription = () => {
        setShowDescription(true);
    };
    const closeDescription = () => {
        setShowDescription(false);
    };
    const openDates = (bookingRequestId) => {
        setBookingRequestId(bookingRequestId);
        setShowDates(true);
    };
    const openPrefs = () => {
        hideRates();
        closeDescription();
        closeDates();
        setShowPrefs(true);
    };
    const closeDates = () => {
        setShowDates(false);
    };

    const goBack = () => {
        navigate('/bookings');
    };

    const [keyMembers, setKeyMembers] = useState([]);

    const fetchMembers = async () => {
        setLoading(true);
        const resp = await fetchRecord(BOOKING_REQUEST.LIST_BOOKING_REQUESTS, '', '', {});
        if (resp && resp.data && resp.data.docs) {
            console.log('--resp------>', resp);
            //setRequests(resp.data.docs);
        }

        setLoading(false);
    };

    const refreshAndShowDates = async () => {
        await fetchBooking(currentId);
        openDates();
    };
    const refreshAndShowRates = async () => {
        console.log('---params--d->', window.location.pathname);
        const params = window.location.pathname;
        const id = params.split('/')[2];
        console.log('---params--d->', id);
        setCurrentId(id);
        fetchBooking(id).then();
        openRates();
    };
    const refreshAndShowPrefs = () => {
        console.log('---params--d->', window.location.pathname);
        const params = window.location.pathname;
        const id = params.split('/')[2];
        console.log('---params--d->', id);
        setCurrentId(id);
        fetchBooking(id).then();
        setShowPrefs(true);
    };

    const fetchBooking = async (bookingId) => {
        setLoading(true);
        console.log('response====>', bookingId);
        const response = await fetchRecord(BOOKING_REQUEST.BOOKING_REQUEST_DETAILS, bookingId);
        console.log('response=details===>', response.data);
        setSelectedBooking(response.data);

        // console.log('response====>', response.data.bookingRates);
        if (response) {
            if (response.status === 200) {
                setBookerDetails(response.data.bookerDetails);
                setKeyMembers(response.data.userKeyMembersInfo);
                setBookingDates(response.data.bookingDates);
                setBooking(response.data);
                const production = response.data.productionInfo;
                setProduction(production);
                if (response.data.provideCrewPreferences && response.data.preferredCrews.length > 0) {
                    setPrefsEnabed(true);
                }
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        // let params = window.location.href;
        // console.log('---params--->', window.location.href);
        console.log('---params--d->', window.location.pathname);
        const params = window.location.pathname;
        const id = params.split('/')[2];
        console.log('---params--d->', id);
        setCurrentId(id);
        fetchBooking(id).then();
        //setBookingId(id);
        // setPath(window.location.pathname);
    }, []);

    const refreshDates = () => {
        console.log('---params--d->', window.location.pathname);
        const params = window.location.pathname;
        const id = params.split('/')[2];
        console.log('---params--d->', id);
        setCurrentId(id);
        fetchBooking(id).then();
        setShowDates(true);
    };

    return (
        <>
            {loading ? (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                ''
            )}
            <div>
                {showDescription && production && (
                    <>
                        <Header
                            currentId={currentId}
                            title={'Production Details'}
                            onBackClick={closeDescription}
                            chipVisible={false}
                            booking={booking}
                        />
                        <BookingDescriptions production={production} />
                    </>
                )}
                {showDates && bookingDates && production && booking && (
                    <>
                        <Header
                            currentId={currentId}
                            title={'Booking Dates'}
                            onBackClick={closeDates}
                            chipVisible={true}
                            booking={booking}
                        />
                        <BookingDates
                            refreshDates={refreshDates}
                            bookingRequestId={bookingRequestId}
                            dates={bookingDates}
                            booking={booking}
                        />
                    </>
                )}
                {!showDates && !showDescription && !showRates && production && !showPrefs && (
                    <>
                        <Header
                            currentId={currentId}
                            title={production.title}
                            onBackClick={goBack}
                            chipVisible={false}
                            booking={booking}
                        />
                        {booking && (
                            <Stepper
                                booking={booking}
                                openDates={openDates}
                                openRates={openRates}
                                openPrefs={openPrefs}
                            />
                        )}

                        {bookerDetails && booking && (
                            <BookerAndCrew
                                openDescription={openDescription}
                                bookerDetails={bookerDetails}
                                production={production}
                                booking={booking}
                                keyMembers={keyMembers}
                            />
                        )}
                    </>
                )}
                {/* {!showDates && !showDescription && showRates && production && booking && (
                    <RatesAndEstimates
                        hideRates={hideRates}
                        production={production}
                        booking={booking}
                        setLoading={setLoading}
                        refresh={refreshAndShowRates}
                    />
                )} */}
                {!showDates && !showDescription && !showRates && production && booking && showPrefs && currentId && (
                    <PreferredCrew
                        refreshView={refreshAndShowPrefs}
                        production={production}
                        booking={booking}
                        setLoading={setLoading}
                        bookingId={currentId}
                        preferredCrews={booking.preferredCrews}
                    />
                )}
            </div>
        </>
    );
}

export default BookingDetails;
