import React, {useEffect, useState} from 'react';
import ReactPhoneInput from 'react-phone-input-material-ui';
import './PhoneInput.css';
import PhoneNumberField from './PhoneNumberField';
import {withStyles} from '@mui/styles';

const styles = (theme) => ({
    field: {},
    countryList: {
        ...theme.typography.body1,
    },
});

function PhoneField(props) {
    const { value, defaultCountry, onChange, classes, disabled, variant, disableUnderline, className } = props;

    const [countryCodeEditable, setCountryCodeEditable] = useState(false);

    useEffect(() => {
        if (!value) {
            setCountryCodeEditable(true);
        } else {
            if (value.charAt(0) === '+' && value.charAt(1) === '+') {
                // temporary condition for invalid numbers
                setCountryCodeEditable(true);
            } else if (value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)) {
                setCountryCodeEditable(true);
            } else {
                setCountryCodeEditable(false);
            }
        }
    }, []);

    return (
        <ReactPhoneInput
            value={value}
            // style={{ margin: '8px' }}
            defaultCountry={defaultCountry || 'au'}
            country={defaultCountry || 'au'}
            countryCodeEditable={countryCodeEditable}
            onChange={onChange}
            inputClass={classes.field}
            dropdownClass={classes.countryList}
            component={PhoneNumberField}
            autoFormat={false}
            disabled={disabled}
            inputProps={{
                variant: variant,
                disableUnderline: disableUnderline,
                className: className,
            }}
        />
    );
}

export default withStyles(styles)(PhoneField);
