import React, {useState} from 'react';
import {AppBar, Grid, IconButton, Stack, Toolbar, Typography} from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import {useNavigate} from 'react-router';
import PreferredChip from '../../BookingList/PreferredChip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {logout} from 'src/services/Authorize';
import {AUTH_ROUTES} from 'src/constants/SiteRoutes';

const Header = (props) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loading, setLoading] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = async () => {
        setLoading(true);
        setAnchorEl(null);
        let response = await logout();
        if (response) {
            if (response.status === 200) {
                setLoading(false);
                navigate(AUTH_ROUTES.LOGIN);
            }
        }
    };
    const goBack = () => {
        window.location.reload();
    };

    return (
        <AppBar position="sticky" elevation={0}>
            <Toolbar sx={{ minHeight: '68px' }}>
                <Stack sx={{ width: '100%' }} spacing={0}>
                    <Grid container>
                        <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton size="large" edge="start" color="inherit" aria-label="back button">
                                <ArrowBackIosNewIcon onClick={goBack} />
                            </IconButton>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={6}
                            alignItems="center"
                            sx={{ justifyContent: 'center', flexDirection: 'column', gap: 0.25 }}
                        >
                            <Typography variant="subtitle2" component="div" sx={{ marginTop: '-3px' }}>
                                {props.title}
                            </Typography>

                            <PreferredChip showSmallLabel={true} booking={props.booking} />
                        </Grid>
                        <Grid container item xs={3} justifyContent="flex-end" alignItems="center" pr={0.35}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <IconButton
                                    onClick={handleClick}
                                    size="large"
                                    edge="end"
                                    color="inherit"
                                    aria-label="more icon"
                                    sx={{ mr: '-12px!important' }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={() => setAnchorEl(null)}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                                </Menu>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};
export default Header;
