/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {fetchRecord} from '../../services/Axios';
import {BOOKING_REQUEST} from '../../constants/ApiEndPoint';
import BookingItem from './BookingItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Header from './Header';
import {getCurrentUser} from '../../services/Authorize';

function BookingList() {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [path, setPath] = useState('/Bookings');

    const fetchMembers = async () => {
        let queryParams = '';

        setLoading(true);
        await getCurrentUser();
        const resp = await fetchRecord(BOOKING_REQUEST.LIST_BOOKING_REQUESTS, '', '', queryParams);
        if (resp && resp.data && resp.data.docs) {
            setRequests(resp.data.docs);
        }
        setLoading(false);
    };

    useEffect(() => {
        setPath(window.location.pathname);
        fetchMembers().then();
    }, []);
    console.log('REQUESTS', requests);
    return (
        <>
            {loading ? (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                ''
            )}
            <div>
                <Header />
                {requests.map((member) => {
                    return <BookingItem member={member} />;
                })}
            </div>
        </>
    );
}

export default BookingList;
