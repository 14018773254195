const accessTokenIdentifierKey = 'accessToken';
const fcmIdentifierKey = 'fcmToken';
const userIdentifierKey = 'userDetails';
export const companySearchIdentifierKey = 'companySearchKey';
export const projectSearchIdentifierKey = 'projectSearchKey';
export const crewSearchIdentifierKey = 'crewSearchKey';
export const productionBookingIdentifierKey = 'productionBooking';
export const bookingKey = 'bookingKey';

const fakeAccessToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJjYW4gYmUgbmFtZSBvciBuYW1lIG9mIG91ciBzZXJ2ZXIgb3IgYXBwIiwic3ViIjoiNWU5ZWQ5OGIyNTg2MDUyNjIwMWM5ZGUwIiwiaWF0IjoxNjY3MzY3MTUyNDA2LCJleHAiOjE2Njc1Mzk5NTI0MDZ9.dGNzP0cLVwm79hrkz0h2wsduvWkRDO0FQmWWtJkKQ00';

export const setSearchParams = (key, params) => {
    return localStorage.setItem(key, JSON.stringify(params));
};

export const getSearchParams = (key) => {
    const memory = localStorage.getItem(key);
    if (memory) {
        return JSON.parse(memory);
    }
    return {};
};

export const clearSearch = (type = null) => {
    if (type === null) {
        localStorage.removeItem(companySearchIdentifierKey);
        localStorage.removeItem(projectSearchIdentifierKey);
        localStorage.removeItem(crewSearchIdentifierKey);
    } else {
        localStorage.removeItem(type);
    }
};

const setAccessToken = (token) => {
    return localStorage.setItem(accessTokenIdentifierKey, token);
};

const getAccessToken = () => {
    return localStorage.getItem(accessTokenIdentifierKey);

    //return fakeAccessToken;
};

const setSelectedBooking = (booking) => {
    return localStorage.setItem(bookingKey, JSON.stringify(booking));
};

const getSelectedBooking = () => {
    return JSON.parse(localStorage.getItem(bookingKey));
};

const clearAccessToken = () => {
    clearSearch();
    return localStorage.removeItem(accessTokenIdentifierKey);
};

export const setFirebaseToken = (token) => {
    return localStorage.setItem(fcmIdentifierKey, token);
};

export const getFirebaseToken = () => {
    return localStorage.getItem(fcmIdentifierKey);
};

export const clearFirebaseToken = () => {
    return localStorage.removeItem(fcmIdentifierKey);
};

export const setLoggedInUser = (userDetails) => {
    return localStorage.setItem(userIdentifierKey, JSON.stringify(userDetails));
};

export const getLoggedInUser = () => {
    return JSON.parse(localStorage.getItem(userIdentifierKey));
};

export const clearLoggedInUser = () => {
    return localStorage.removeItem(userIdentifierKey);
};

export { setAccessToken, getAccessToken, clearAccessToken, getSelectedBooking, setSelectedBooking };
