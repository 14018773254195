import React from 'react';
import { Typography } from '@mui/material';
import { getDayLabel } from 'src/services/GeneralService.js';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    popoverContent: {
        textAlign: 'center',
        backgroundColor: 'black',
        color: 'white',
        opacity: 0.75,
        borderRadius: 2,
        width: 'fit-content',
        padding: 4,
        position: 'absolute',
        zIndex: 6000,
    },
    dashIcon: {
        display: 'inline-block',
        verticalAlign: 'middle',
        margin: '0 3px',
    },
});

export default function PopUp(props) {
    const { event, anchorEl } = props;
    const classes = useStyles();

    return (
        <>
            {event && event._def.extendedProps.type === 'booking_request' && (
                <div className={classes.popoverContent} style={{ ...anchorEl }}>
                    <Typography color="inherit" variant="body1">
                        {event._def.extendedProps.originalTitle}
                    </Typography>
                    <Typography color="inherit" variant="body1">
                        {event._def.extendedProps.taskTypeInfo?.title}
                        {' - '}
                        {getDayLabel(event._def.extendedProps.days)}
                    </Typography>
                    <Typography color="inherit" variant="body1">
                        {event._def.extendedProps.holdTypeInfo.title}
                    </Typography>
                </div>
            )}
            {event && !event.extendedProps.type && (
                <div className={classes.popoverContent} style={{ ...anchorEl }}>
                    <Typography color="inherit" variant="body1">
                        {event._def.extendedProps.originalTitle}
                    </Typography>
                    <Typography color="inherit" variant="body1">
                        {getDayLabel(event._def.extendedProps.days)}
                    </Typography>
                    <Typography color="inherit" variant="body2">
                        {event._def.extendedProps.description}
                    </Typography>
                </div>
            )}
        </>
    );
}
