const AUTH_CODES = {
  SUCCESS: 200,
  ACCOUNT_NOT_VERIFIED: 402,
  SET_ACCOUNT_PASSWORD: 411
};

export const COMMON_CODES = {
  SUCCESS: 200,
  DUPLICATE: 409,
  NO_RECORDS: 204
};
const RATE_CARD_CODES = {
  UNPUBLISHED_BOOKER: 1,
  PUBLISHED_BOOKER: 2,
  UPDATED_BOOKER: 3,
  UNPUBLISHED_MEMBER: 4,
  PUBLISHED_MEMBER: 5,
  UPDATED_MEMBER: 6
};

export { AUTH_CODES, RATE_CARD_CODES };
