import React from 'react';
import {ValidatorComponent} from 'react-material-ui-form-validator';
import {Checkbox} from '@mui/material';

const style = {
    width: '100%',
    color: '#e53935',
    fontSize: '11px',
    marginTop: '8px',
    minHeight: '1em',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '1em',
    letterSpacing: '0.6px',
};

class CheckboxValidator extends ValidatorComponent {
    renderValidatorComponent() {
        const { errorMessages, validators, requiredError, value, ...rest } = this.props;

        return (
            <div>
                <Checkbox
                    {...rest}
                    ref={(r) => {
                        this.input = r;
                    }}
                />
                {this.errorText()}
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return <div style={style}>{this.getErrorMessage()}</div>;
    }
}

export default CheckboxValidator;
