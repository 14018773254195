import React, {forwardRef} from 'react';
import {TextValidator} from 'react-material-ui-form-validator';
import {VALIDATION_RULES} from '../../constants';

const phoneInput = (props, ref) => {
    return (
        <TextValidator
            {...props}
            fullWidth
            name="phoneNumber"
            className={props.className}
            label=""
            placeholder="Phone Number"
            InputLabelProps={{
                shrink: true,
            }}
            variant={props.variant ? props.variant : 'outlined'}
            validators={['required', 'minStringLength:9', 'maxStringLength:20', VALIDATION_RULES.PHONE_REGEX]}
            errorMessages={[
                'Phone Number is required.',
                'Phone Number must be at least 9 characters long.',
                'Phone Number must be less than 20 characters',
                'Phone Number must be valid.',
            ]}
        />
    );
};
export default forwardRef(phoneInput);
