import React from 'react';
import {Button, Link, Typography, useTheme} from '@mui/material';
import {Stack} from '@mui/system';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {FREELANCER, PasswordValidation, VALIDATION_RULES} from '../../constants';
import GeoLocationInput from '../../components/Common/GeoLocationInput';
import CheckboxValidator from '../../components/Common/CheckboxValidator';

export default function SignUpForm(props) {
    const theme = useTheme();
    const {
        handleBack,
        handleSubmit,
        handleUserDetailsChange,
        userDetails,
        formRef,
        setLat,
        setLong,
        lat,
        long,
        terms,
        setTerms,
        selectedUserType,
    } = props;

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== userDetails.password) {
            return false;
        }
        return true;
    });

    const isValidAddress = () => {
        return !(lat === 0 || long === 0);
    };

    ValidatorForm.addValidationRule('validAddress', (value) => {
        if (value !== '') {
            return isValidAddress();
        }

        return true;
    });

    ValidatorForm.addValidationRule('isTruthy', (value) => value);

    return (
        <Stack direction="column" spacing={1} bgcolor={theme.palette.background.paper} px={2} pt={3} pb={3.2} mt={3}>
            <ValidatorForm onSubmit={handleSubmit} ref={formRef}>
                <Typography variant="h2" component={'div'} textAlign="center" mb={1.5}>
                    Sign Up Details
                </Typography>
                <Stack direction="column" spacing={2.4} sx={{ mt: '1.65rem!important' }}>
                    {selectedUserType === FREELANCER ? (
                        <>
                            <TextValidator
                                fullWidth
                                id="outlined-basic"
                                label="First Name"
                                variant="outlined"
                                placeholder="First name"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={userDetails.firstName}
                                onChange={(event) => handleUserDetailsChange('firstName', event.target.value)}
                                validators={['required', VALIDATION_RULES.NAME_REGEX]}
                                errorMessages={[
                                    'First name is required.',
                                    "First name shouldn't contain special characters.",
                                ]}
                            />
                            <TextValidator
                                fullWidth
                                id="outlined-basic"
                                label="Last Name"
                                variant="outlined"
                                placeholder="Last name"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={userDetails.lastName}
                                onChange={(event) => handleUserDetailsChange('lastName', event.target.value)}
                                validators={['required', VALIDATION_RULES.NAME_REGEX]}
                                errorMessages={[
                                    'Last name is required.',
                                    "Last name shouldn't contain special characters.",
                                ]}
                            />
                        </>
                    ) : (
                        <TextValidator
                            fullWidth
                            id="outlined-basic"
                            label="Company Name"
                            variant="outlined"
                            placeholder="Company name"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={userDetails.companyName}
                            onChange={(event) => handleUserDetailsChange('companyName', event.target.value)}
                            validators={['required']}
                            errorMessages={['Company name is required.']}
                        />
                    )}
                    <TextValidator
                        fullWidth
                        id="outlined-basic"
                        label="Work Email Address"
                        variant="outlined"
                        placeholder="Work email address"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={userDetails.email}
                        onChange={(event) => handleUserDetailsChange('email', event.target.value)}
                        validators={['required', 'isEmail']}
                        errorMessages={['Email field is required.', 'Must be a valid Email.']}
                    />
                    <GeoLocationInput
                        onChange={(value) => handleUserDetailsChange('address', value)}
                        setLat={setLat}
                        setLong={setLong}
                        inputProps={{
                            validators: ['required', 'validAddress'],
                            errorMessages: ['Location is required.', 'Select a valid address.'],
                        }}
                        value={userDetails.address}
                        validate
                        searchOptions={{ types: ['(cities)'] }}
                    />
                    {/* <TextField
                        id="outlined-basic"
                        label="Location"
                        variant="outlined"
                        placeholder="i.e Sydney, Australia"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    /> */}
                    <TextValidator
                        fullWidth
                        type="password"
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        placeholder="Enter Password"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={userDetails.password}
                        onChange={(event) => handleUserDetailsChange('password', event.target.value)}
                        validators={PasswordValidation.validators}
                        errorMessages={PasswordValidation.errorMessages}
                    />
                    <TextValidator
                        fullWidth
                        type="password"
                        id="outlined-basic"
                        label="Confirm Password"
                        variant="outlined"
                        placeholder="Enter Confirm Password"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={userDetails.confirmPassword}
                        onChange={(event) => handleUserDetailsChange('confirmPassword', event.target.value)}
                        validators={['required', 'isPasswordMatch']}
                        errorMessages={['Confirm Password is required.', 'Confirm Password does not match.']}
                    />
                </Stack>
                <Stack direction="row" sx={{ display: 'flex', mt: '0!important' }} alignItems="center" spacing={-0.4}>
                    <CheckboxValidator
                        validators={['isTruthy']}
                        errorMessages={[
                            'You need to accept our Terms of Use and Privacy Policy to create a Crews account.',
                        ]}
                        checked={terms}
                        value={terms}
                        onChange={(e) => setTerms(e.target.checked)}
                    />
                    <Typography variant="body2" component="label" for="agreeTerms">
                        I agree to the Crews <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{ display: 'flex', mt: '1.45rem!important' }}
                    alignItems="center"
                >
                    <Button variant="contained" type="submit" className="rounded" sx={{ marginLeft: '.125rem' }}>
                        Create Account
                    </Button>
                    <Link href="#" variant="body1" onClick={handleBack}>
                        Go Back
                    </Link>
                </Stack>
            </ValidatorForm>
        </Stack>
    );
}
