/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {useTheme} from '@mui/material/styles';

// import validate from 'validate.js';
// import { makeStyles } from '@mui/styles';
import {Link, Stack, Typography} from '@mui/material';
import {AUTH_ROUTES} from '../../constants/SiteRoutes';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {Link as RouterLink} from 'react-router-dom';
// import components
import Header from '../../components/Header';
import SnackBarWithTimer from '../../components/Common/SnackBarWithTimer';

function ResetPasswordSuccess() {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState({});

  return (
    <>
      {loading && (
        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={loading}>
          <CircularProgress color="inherit"/>
        </Backdrop>
      )}
      {showSnackbar && (
        <SnackBarWithTimer
          open={showSnackbar}
          timer={6000}
          message={message}
          onClose={() => setShowSnackbar(false)}
        />
      )}
      <Header type="general"/>
      <Stack direction="column" spacing={3} bgcolor={theme.palette.background.paper} px={2} pt={2.5} pb={4}>
        <Typography variant="h3" component={'div'} textAlign="center">
          Reset Password
        </Typography>
        <Stack direction="column" spacing={2.6}>

          <Typography
            gutterBottom
            variant="h4"
            textAlign="center"
          >
            Password Changed Successfully.
          </Typography>
          <Stack direction="column" spacing={0} textAlign="center">
            <Link component={RouterLink} to={AUTH_ROUTES.LOGIN} variant="body2">
              Login
            </Link>
          </Stack>
        </Stack>
      </Stack>

    </>
  );
}

export default ResetPasswordSuccess;
