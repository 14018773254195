import React, {Fragment, useEffect} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import CustomizedSnackbars from '../../views/Snackbar/CustomSnackbar';
import {Mixpanel} from "../../services/MixPanelService";
import {getLoggedInUser} from "../../services/Storage";

const AppContent = ({ routes }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        location.pathname === '' && navigate('/login');

        //navigate(`${location.pathname}`);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const currentLocation = useLocation();

  const mixpanelTracker = (url) => {
    const arrayParts = url.split('/');
    console.log(arrayParts)
    let browsePage = null;
    let pagePath = arrayParts[1];
    switch (pagePath) {
      case 'bookings':
        browsePage = "Bookings";
        break;
      case 'mycalendar':
        browsePage = "Calendar";
        break;
      case 'booking-details':
        browsePage = "Booking Single";
        break;
      default:
        browsePage = (function () {
        })();
    }
    let auth = getLoggedInUser();
    if(browsePage && auth){
      let pageTrackObject = {
        Platform: "Web",
        'Page Name': browsePage,
        'Page Variant': "A"
      };
      console.log(pageTrackObject,'pageTrackObject')

      const distinctId = auth._id;
      Mixpanel.register(pageTrackObject);
      Mixpanel.track('View Page Mobile', {
        distinct_id: distinctId
      });
    }

  }

  useEffect(() => {
    mixpanelTracker(currentLocation.pathname);
    console.log('Location changed!', currentLocation);
  }, [currentLocation]);

    return (
        <Fragment>
            {/* {location.pathname !== '/login' && location.pathname !== undefined && location.pathname !== '/' ? (
                <AppBarComponent />
            ) : null} */}
            <CustomizedSnackbars />
            <div style={{ minHeight: '100vh' }}>
                <Routes>
                    {routes.map((route) =>
                        route.requiresAuth ? (
                            <Route key={route.id} path={route.path} element={<route.component />}>
                                {route.children.map((eachChild, index) => (
                                    <Route key={index} path={eachChild.path} element={<eachChild.component />} />
                                ))}
                            </Route>
                        ) : (
                            <Route key={route.id} path={route.path} element={<route.component />} />
                        ),
                    )}
                </Routes>
            </div>
            {/* {location.pathname !== '/login' && <Footer />} */}
        </Fragment>
    );
};

export default AppContent;
