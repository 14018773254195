import {
  CONFIRMED_HOLD_COLOR,
  CONFIRMED_HOLD_TEXT,
  DEFAULT_CHIP_COLOR,
  DEFAULT_CHIP_TEXT,
  ERROR_CHIP_COLOR,
  ERROR_CHIP_TEXT,
  FIRST_HOLD_COLOR,
  FIRST_HOLD_TEXT,
  INFO_CHIP_COLOR,
  INFO_CHIP_TEXT,
  SECOND_HOLD_COLOR,
  SECOND_HOLD_TEXT,
  SUCCESS_CHIP_COLOR,
  SUCCESS_CHIP_TEXT,
  WARNING_CHIP_COLOR,
  WARNING_CHIP_TEXT,
} from 'src/constants';

const defaultStyle = {
  //padding: 10,
  color: CONFIRMED_HOLD_TEXT,
  backgroundColor: CONFIRMED_HOLD_COLOR,
  fontSize: 10,
  //height: 24
};
export const allStyles = {
  secondary: {
    ...defaultStyle,
    backgroundColor: '#E8DDD2',
    color: '#a3784d'
  },
  primary: {
    ...defaultStyle,
    backgroundColor: CONFIRMED_HOLD_COLOR,
    color: CONFIRMED_HOLD_TEXT
  },
  primaryDark: {
    ...defaultStyle,
    backgroundColor: '#2e7c6c',
    color: '#fff'
  },
  success: {
    ...defaultStyle,
    backgroundColor: SUCCESS_CHIP_COLOR,
    color: SUCCESS_CHIP_TEXT
  },
  warning: {
    ...defaultStyle,
    backgroundColor: WARNING_CHIP_COLOR,
    color: WARNING_CHIP_TEXT
  },
  info: {
    ...defaultStyle,
    backgroundColor: INFO_CHIP_COLOR,
    color: INFO_CHIP_TEXT
  },
  error: {
    ...defaultStyle,
    backgroundColor: ERROR_CHIP_COLOR,
    color: ERROR_CHIP_TEXT
  },
  hold: {
    ...defaultStyle,
    backgroundColor: '#DED0C2',
    color: '#644A30'
  },
  invite: {
    ...defaultStyle,
    backgroundColor: '#bebebe',
    color: '#fff'
  },
  default: {
    ...defaultStyle,
    backgroundColor: DEFAULT_CHIP_COLOR,
    color: DEFAULT_CHIP_TEXT
  },
  // publish related
  unPublishedUpdate: {
    ...defaultStyle,
    backgroundColor: '#FAF4E5',
    color: '#702832'
  },
  unPublishedCheck: {
    ...defaultStyle,
    backgroundColor: CONFIRMED_HOLD_COLOR,
    color: CONFIRMED_HOLD_TEXT
  },
  unSubmittedChanges: {
    ...defaultStyle,
    backgroundColor: '#D32F2F',
    color: '#FFFFFF',
    textTransform: 'none',
    fontSize: 13
  },
  changesSubmitted: {
    ...defaultStyle,
    backgroundColor: FIRST_HOLD_COLOR,
    color: FIRST_HOLD_TEXT
  },
  // tags
  curationTags: {
    ...defaultStyle,
    backgroundColor: '#FFF1F1',
    color: '#A05454'
  },
  creditTag: {
    ...defaultStyle,
    backgroundColor: '#FFF9E7',
    color: '#B35A04'
  },

  // hold types
  confirmedholds: {
    ...defaultStyle,
    backgroundColor: CONFIRMED_HOLD_COLOR,
    color: CONFIRMED_HOLD_TEXT
  },
  firstHold: {
    ...defaultStyle,
    backgroundColor: FIRST_HOLD_COLOR,
    color: FIRST_HOLD_TEXT
  },
  secondHold: {
    ...defaultStyle,
    backgroundColor: SECOND_HOLD_COLOR,
    color: SECOND_HOLD_TEXT
  },
  secondHolds: {
    ...defaultStyle,
    backgroundColor: SECOND_HOLD_COLOR,
    color: SECOND_HOLD_TEXT
  },
  todo: {
    ...defaultStyle,
    backgroundColor: DEFAULT_CHIP_COLOR,
    color: DEFAULT_CHIP_TEXT
  }
};

export const showSmallLabelStyle = { padding: 6, fontSize: 10, height: 16 };
export const paddingNormalStyle = { padding: '3px 10px' };
