import React from 'react';
import { Chip } from '@mui/material';

function PreferredChip(props) {
    const { booking, showSmallLabel } = props;

    const { isPreferredCrewSubmitted, hasDraftPreferredCrews, preferredCrews } = booking;
    if (isPreferredCrewSubmitted) {
        return <Chip label="Submitted" size={showSmallLabel ? 'small' : 'medium'} color="success" />;
    }

    if (preferredCrews && preferredCrews.length > 0) {
        return <Chip label="To Do" size={showSmallLabel ? 'small' : 'medium'} color="default" />;
    } else if (hasDraftPreferredCrews) {
        return <Chip label="Unsubmitted Changes" size={showSmallLabel ? 'small' : 'medium'} color="default" />;
    } else if (isPreferredCrewSubmitted) {
        return <Chip label="Submitted" size={showSmallLabel ? 'small' : 'medium'} color="success" />;
    } else {
        return <Chip label="To Do" size={showSmallLabel ? 'small' : 'medium'} color="default" />;
    }
}

export default PreferredChip;
