import {
  RELEASE_STATUS_CONFIRMED,
  RELEASE_STATUS_NONE,
  RELEASE_STATUS_RELEASED,
  RELEASE_STATUS_UNCONFIRMED,
} from '../constants';
import moment from 'moment';

export const getAcceptanceLabel = (label) => {
    console.log('getAcceptanceLabel--->', label);
    if (label === 'bookingConflict') {
        return 'Booking Conflict';
    } else if (label === 'onLeave') {
        return 'On Leave';
    }
    return label;
};

export const getDaysDiffWithDayStringAttached = (
    start_date,
    end_date,
    date_format = 'YYYY-MM-DD'
  ) => {
    console.log(start_date, 'start_date', end_date);
    const getDateAsArray = date => {
      return moment(date.split(/\D+/), date_format);
    };
    const differenceInDays = getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days') + 1;
    return differenceInDays === 1 ? `${differenceInDays} day`: `${differenceInDays} days` 
};

export const getDayLabel = (
    day=1
  ) => {
    return day === 1 ? `${day} day`: `${day} days` 
};

export const getStatusLabel = (bookingDates, member) => {
    console.log('getStatusLabel member--->', member);
    console.log('getStatusLabel member--->', bookingDates);
    //const bookingDates = member.bookingDates;
    let bookingResponseArray = [];

    for (const item of bookingDates) {
        var receiverAcceptance = item.receiverAcceptance;
        if (receiverAcceptance == 'accept') {
            receiverAcceptance = 'Accepted';
        }
        bookingResponseArray.push(receiverAcceptance);
    }

    bookingResponseArray = [...new Set(bookingResponseArray)];

    let label =
        bookingResponseArray.length > 1 ? 'Mixed Response' : getBookingDatesResponseLabel(bookingResponseArray[0]);

    if (
        label == 'Mixed Response' &&
        !bookingResponseArray.includes('Awaiting Approval') &&
        member.hasBookerSubmittedDateChanges
    ) {
        return 'Mixed Response';
    }
    if (
        label == 'Mixed Response' &&
        bookingResponseArray.includes('Awaiting Approval') &&
        !member.hasBookerSubmittedDateChanges &&
        !member.hasCrewSubmittedDateChanges
    ) {
        return 'AWAITING APPROVAL';
    }
    if (member && member.hasBookerSubmittedDateChanges && member.isPublished) {
        if (bookingResponseArray.includes('Awaiting Approval')) {
            label = 'CHANGES SUBMITTED';
        }
    }

    console.log('label--->', label);
    if (!label) {
        return 'AWAITING APPROVAL';
    }
    if (label.includes('Unpublished')) {
        if (label == 'Unpublished') {
            return 'AWAITING APPROVAL';
        } else {
            label = 'AWAITING APPROVAL';
        }
    }
    return label;
};

const isAllConflicted = (bookingResponseArray) => {
    let res = true;
    for (const item of bookingResponseArray) {
        if (item !== 'Booking Conflict' && item !== 'bookingconflict') {
            res = false;
            break;
        }
    }

    return res;
};

export const getStatusLabelBookie = (bookingDates, member) => {
    console.log('getStatusLabel member--->', member);
    console.log('getStatusLabel member--->', member.productionInfo.title);
    //const bookingDates = member.bookingDates;
    console.log('here 1');
    if (member.isReleased || (member.releaseStatus && member.releaseStatus !== RELEASE_STATUS_NONE)) {
        return getReleaseLabelForCrew(member.releaseStatus);
    }
    console.log('here 2');

    let bookingResponseArray = [];

    for (const item of bookingDates) {
        var receiverAcceptance = item.receiverAcceptance;
        if (receiverAcceptance == 'accept') {
            receiverAcceptance = 'Accepted';
        }
        bookingResponseArray.push(receiverAcceptance);
    }
    bookingResponseArray = [...new Set(bookingResponseArray)];
    let label =
        bookingResponseArray.length > 1 ? 'Mixed Response' : getBookingDatesResponseLabel(bookingResponseArray[0]);

    if (isAllConflicted(bookingResponseArray)) {
        label = 'Booking Conflict';
    }

    console.log('label-1-->', label);
    if (
        label == 'Mixed Response' &&
        bookingResponseArray.includes('Awaiting Approval') &&
        !member.hasBookerSubmittedDateChanges &&
        !member.hasCrewSubmittedDateChanges
    ) {
        return 'TO DO';
    }

    if (label == 'Mixed Response') {
        if (member.isPublished && !bookingResponseArray.includes('Awaiting Approval')) {
        } else {
            if (member.hasBookerSubmittedDateChanges) {
                label = 'CHANGES RECEIVED';
            }
        }
    }
    if (label == 'Accepted' && !member.hasBookerSubmittedDateChanges) {
        return label;
    }

    if (!label) {
        return 'TO DO';
    }
    if (label == 'Awaiting Approval' && member.isPublished) {
        if (member.hasBookerSubmittedDateChanges) {
            label = 'CHANGES RECEIVED';
        } else {
            label = 'TO DO';
        }
    }
    if (label == 'Awaiting Approval' && !member.hasBookerSubmittedDateChanges) {
        label = 'TO DO';
    }
    if (label.includes('Unpublished')) {
        if (label == 'Unpublished') {
            return 'TO DO';
        } else {
            label = 'TO DO';
        }
    }
    console.log('returnLabel',label);
    return label;
};

export const getStatusLabelColor = (bookingDates, label) => {
    console.log(label);
    if (
        label === 'CHANGES SUBMITTED' ||
        label === 'CHANGES RECEIVED' ||
        label === 'Mixed Response'
        // label === 'AWAITING APPROVAL'
    ) {
        return 'warning';
    } else if (
        (label && label.toLowerCase() === 'awaiting approval') ||
        label === 'DECLINED' ||
        label === 'Booking Conflict'
    ) {
        return 'error';
    } else if (bookingDates.length === 0 || label === 'To Do') {
        return 'default';
    } else if (label && label.toLowerCase() === 'unpublished') {
        return 'error';
    } else if (label === 'accept' || label === 'Accepted' || label === 'accepted') {
        return 'success';
    } else if (label && (label.toLowerCase() === 'decline' || label.toLowerCase() === 'declined')) {
        return 'error';
    } else if (label && ( label.toLowerCase() === 'bookingconflict' || label.toLowerCase() === 'onleave')) {
        return 'error';
    } else if (
        label &&
        (label.toLowerCase() === 'released' ||
            label.toLowerCase() === 'release confirmed' ||
            label.toLowerCase() === 'release unconfirmed')
    ) {
        return 'error';
    } else {
        return 'default';
    }
};

export const getBookingDatesResponseLabel = (label) => {
    if (label === 'bookingConflict') {
        return 'Booking Conflict';
    } else if (label === 'onLeave') {
        return 'On Leave';
    } else if (label === 'accept') {
        return 'Accepted';
    } else if (label === 'decline') {
        return 'Declined';
    }
    return label;
};

export const getReleaseLabelForCrew = (releaseStatus) => {
    if (releaseStatus === RELEASE_STATUS_RELEASED) {
        return 'RELEASED';
    } else if (releaseStatus === RELEASE_STATUS_CONFIRMED) {
        return 'RELEASED';
    } else if (releaseStatus === RELEASE_STATUS_UNCONFIRMED) {
        return 'RELEASED';
    }
};

export const processPhoneNumber = phoneNumber => {
    const firstChar = phoneNumber.charAt(0);
    if (firstChar === '+') {
        return phoneNumber;
    }
    return `+${phoneNumber}`;
};
