/* eslint-disable no-unused-vars */
import React, {useRef, useState} from 'react';
import {AppBar, Box, Grid, IconButton, Stack, Toolbar, useTheme} from '@mui/material';
import {ReactComponent as Logo} from '../../assets/images/logos/logo-white.svg';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector';
import SelectAccount from './SelectAccount';
import SignUpForm from './SignUpForm';
import {postRecord} from '../../services/Axios';
import {AUTHENTICATION} from '../../constants/ApiEndPoint';
import SnackBarWithTimer from '../../components/Common/SnackBarWithTimer';
import {AUTH_ROUTES} from '../../constants/SiteRoutes';
import SimpleBackdrop from '../../components/Common/SimpleBackdrop';
import {useNavigate} from 'react-router-dom';
import {accountLogin} from "../../services/Authorize";
import {Mixpanel} from "../../services/MixPanelService";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active &&
        {
            //color: '#784af4',
        }),
    '& .QontoStepIcon-completedIcon': {
        //color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const steps = ['Account Type', 'Your Details'];

function SignUp() {
    const [activeStep, setActiveStep] = useState(0);

    const theme = useTheme();
    const formRef = useRef();
    const navigate = useNavigate();
    const [selectedUserType, setSelectedUserType] = useState('freelancer');
    const [selectedWhatBestDescribes, setSelectedWhatBestDescribes] = useState('getBooked');
    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        email: '',
        address: '',
        companyName: '',
    });
    const [lat, setLat] = useState(0);
    const [long, setLong] = useState(0);
    const [loading, setLoading] = useState(false);
    const [terms, setTerms] = useState(false);
    const [message, setMessage] = useState({});
    const [showSnackbar, setShowSnackbar] = useState(false);

    const handleChange = (event) => {
        setSelectedUserType(event.target.value);
    };

    const handleWhatBestDescribesChange = (event) => {
        setSelectedWhatBestDescribes(event.target.value);
    };

    const handleNext = () => {
        setActiveStep((activeStep) => activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep((activeStep) => activeStep - 1);
    };

    const handleUserDetailsChange = (field, value) => {
        setUserDetails((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
    };

    const handleSubmit = async () => {
        formRef.current.isFormValid(false).then(async (isValid) => {
            if (isValid) {
                setLoading(true);
                let user = { ...userDetails };
                delete user.confirmPassword;
                user.accountType = selectedUserType;
                user.whatBestDescribesYou = selectedWhatBestDescribes;
                user.location = {
                    coordinates: [long, lat],
                };
                let response = await postRecord(AUTHENTICATION.REGISTER, user);
                if (response) {
                    if (response.status === 200) {
                        await accountLogin(response.data);
                        user._id = response.data._id;
                        Mixpanel.MobileRegisterEvent.fire(user);
                        navigate(AUTH_ROUTES.VERIFY_PHONE_NUMBER,{
                            state: {
                                userId: response.data._id,
                            }
                        });

                    } else {
                        setMessage({ type: 'error', message: response.message });
                        setShowSnackbar(true);
                    }
                    setLoading(false);
                }
            }
        });
    };

    return (
        <>
            {loading && <SimpleBackdrop />}
            {showSnackbar && (
                <SnackBarWithTimer
                    open={showSnackbar}
                    onClose={() => setShowSnackbar(false)}
                    timer={6000}
                    message={message}
                />
            )}
            <AppBar
                position="sticky"
                elevation={0}
                sx={{ backgroundColor: theme.palette.text.primary, minHeight: '109px' }}
            >
                <Toolbar>
                    <Stack sx={{ width: '100%' }} spacing={0}>
                        <Grid container>
                            <Grid item xs={3}></Grid>
                            <Grid container item xs={6} alignItems="center" justifyContent="center">
                                <Box sx={{ marginTop: '25px' }}>
                                    <Logo width="100px" height="auto" />
                                </Box>
                            </Grid>
                            <Grid item container xs={3} justifyContent="flex-end" alignItems="flex-start">
                                <IconButton
                                    size="large"
                                    edge="end"
                                    color="inherit"
                                    aria-label="close button"
                                    onClick={() => navigate(-1)}
                                >
                                    <CloseIcon fontSize="large" sx={{ color: theme.palette.common.white }} />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>
                </Toolbar>
            </AppBar>
            {activeStep === 0 ? (
                <SelectAccount
                    selectedValue={selectedUserType}
                    handleChange={handleChange}
                    handleWhatBestDescribesChange={handleWhatBestDescribesChange}
                    handleNext={handleNext}
                    selectedWhatBestDescribes={selectedWhatBestDescribes}
                />
            ) : (
                <SignUpForm
                    formRef={formRef}
                    handleBack={handleBack}
                    userDetails={userDetails}
                    handleUserDetailsChange={handleUserDetailsChange}
                    lat={lat}
                    setLat={setLat}
                    long={long}
                    setLong={setLong}
                    terms={terms}
                    setTerms={setTerms}
                    handleSubmit={handleSubmit}
                    selectedUserType={selectedUserType}
                />
            )}
        </>
    );
}

export default SignUp;
