import React, { useEffect } from 'react';

import { Box, Card, CardContent, CardHeader, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PreferredChip from '../BookingList/PreferredChip';
import BookingStatusChip from '../BookingList/BookingStatusChip';
import RatesStatusChip from '../BookingList/RatesStatusChip';

function Stepper({ booking, openDates, openRates, openPrefs }) {
    useEffect(() => {
        console.log('booking====>Stepper', booking);
    }, []);

    return (
        <Stack direction="column" spacing={3}>
            <Card elevation={0}>
                <CardHeader title="NEXT STEPS" />
                <CardContent sx={{pb: '21px!important'}}>
                    <Grid container px={2} my={'17px'}>
                        <Grid item xs={11}>
                            <Stack direction="row" spacing={1.2} flex="1" alignItems="flex-start">
                                <Typography variant="h1" sx={{ width: '35px', flexShrink: '0', flexGrow: '0' }}>01</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                                        <Typography variant="subtitle2" sx={{ lineHeight: '1.3rem' }}>
                                            Accept Booking Dates
                                        </Typography>
                                        <Box sx={{ pt: '.429rem', pr: '0.286rem', display: 'flex' }} alignItems="start">
                                            {booking && (
                                                <BookingStatusChip
                                                    booking={booking}
                                                    forStepperStatus={true}
                                                    showSmallLabel={true}
                                                />
                                            )}
                                        </Box>
                                    </Stack>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Typography variant="body2">
                                                View and accept your booking dates, change your response at any time.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid container item xs={1}>
                            <IconButton
                                onClick={() => openDates()}
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="back button"
                                sx={{ mt: '-.25rem', alignSelf: 'flex-start' }}
                            >
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {/* <Divider sx={{ mt: 2, mb: 1.2 }} style={{ display: booking.showMemberRates ? 'block' : 'none' }} />
                    <Grid container px={2} style={{ display: booking.showMemberRates ? 'flex' : 'none' }}>
                        <Grid container item xs={11}>
                            <Stack direction="row" spacing={1.2} flex="1">
                                <Typography variant="h1">02</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                                        <Typography variant="subtitle2" sx={{ lineHeight: '1.3rem' }}>
                                            Confirm Rates
                                        </Typography>
                                        <Box sx={{ pt: '.429rem', pr: '0.286rem', display: 'flex' }} alignItems="start">
                                            {booking && (
                                                <RatesStatusChip
                                                    forStepperStatus={true}
                                                    showSmallLabel={true}
                                                    booking={booking}
                                                />
                                            )}
                                        </Box>
                                    </Stack>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Typography variant="body2">
                                                Submit your rates for approval, or confirm a proposed rate.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid container item xs={1} alignItems="start">
                            <IconButton
                                onClick={() => openRates()}
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="back button"
                                sx={{ mt: '-.25rem', alignSelf: 'flex-start' }}
                            >
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Grid>
                    </Grid> */}
                    <Divider
                        sx={{ mt: 2, mb: 1.625 }}
                        style={{ display: booking.provideCrewPreferences ? 'block' : 'none' }}
                    />
                    <Grid container px={2} style={{ display: booking.provideCrewPreferences ? 'flex' : 'none' }}>
                        <Grid container item xs={11}>
                            <Stack direction="row" spacing={1.2} flex="1">
                                {/* <Typography variant="h1">{booking.showMemberRates ? '03' : '02'}</Typography> */}
                                <Typography variant="h1" sx={{ width: '35px', flexShrink: '0', flexGrow: '0' }}>{'02'}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                                        <Typography variant="subtitle2" sx={{ lineHeight: '1.3rem' }}>
                                            Submit Preferred Crew
                                        </Typography>
                                        <Box sx={{ pt: '.429rem', pr: '0.286rem', display: 'flex' }} alignItems="start">
                                            {booking && (
                                                <PreferredChip
                                                    forStepperStatus={true}
                                                    showSmallLabel={true}
                                                    booking={booking}
                                                />
                                            )}
                                        </Box>
                                    </Stack>
                                    <Grid container>
                                        <Grid item xs={8}>
                                            <Typography variant="body2">
                                                Submit your list of Crew preferences.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid container item xs={1} alignItems="start">
                            <IconButton
                                onClick={() => openPrefs()}
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="back button"
                                sx={{ mt: '-.25rem', alignSelf: 'flex-start' }}
                            >
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Stack>
    );
}

export default Stepper;
