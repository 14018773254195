import { BottomNavigation, Box, Button, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getBookingDatesResponseLabel, getStatusLabel } from '../../services/GeneralService';

import { BOOKING_REQUEST } from '../../constants/ApiEndPoint';
import Backdrop from '@mui/material/Backdrop';
import BookingDatesItem from './BookingDatesItem';
import BookingDescriptions from './BookingDescriptions';
import { Calendar } from '../Calendar';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useDispatch } from 'react-redux';

function a11yProps(index: number) {
    return {
        id: `simple-tab-hold-dates${index}`,
        'aria-controls': `simple-tabpanel-hold-dates${index}`,
    };
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-hold-dates${index}`}
            aria-labelledby={`simple-tab-hold-dates${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function BookingDates(props) {
    const { booking } = props;
    console.log('foo booking', booking.bookingDates);
    console.log('foo production', props);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            {loading && (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}

            <Box>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs" className="tabsPill">
                    <Tab label="List View" {...a11yProps(0)} sx={{ minHeight: '28px!important' }} />
                    <Tab label="Calender View" {...a11yProps(1)} sx={{ minHeight: '28px!important' }} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                {booking.bookingDates && booking.bookingDates.map((item, index) => <BookingDatesItem item={item} />)}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Calendar hideHeader />
            </CustomTabPanel>
        </Box>
    );
}

export default BookingDates;
