import React, {useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import {makeStyles} from '@mui/styles';
import BookingExpenseView from './BookingExpenseView';

const TAX_RATE = 0.07;

function ccyFormat(num) {
    return `${num.toFixed(2)}`;
}

// function priceRow(qty: number, unit: number) {
//   return qty * unit;
// }

// function createRow(desc: string, qty: number, unit: number) {
//   const price = priceRow(qty, unit);
//   return { desc, qty, unit, price };
// }

const invoiceSubtotal = 2010;
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;

const useStyles = makeStyles((theme) => ({
    // root: {
    //     width: '100%',
    //     maxWidth: 500,
    // },
    // cartHeader: {
    //     padding: theme.spacing(2, 0),
    // },
    // option: {
    //     display: 'flex',
    //     alignItems: 'flex-start',
    //     //padding: theme.spacing(2),
    //     '& + &': {
    //         marginTop: '8px',
    //     },
    // },

    // headingWrap: {
    //     color: '#6e6969 !important',
    //     fontSize: '12px !important',
    //     fontWeight: '400 !important',
    //     padding: '10px !important',
    //     // width: '85px'
    // },
    // titleWrap: {
    //     padding: '16px 16px 16px 10px',
    //     width: '120px',
    //     color: '#6e6969 !important',
    //     fontSize: '12px !important',
    //     fontWeight: '400 !important',
    // },
    // tableWrap: {
    //     height: '400px',
    // },
}));

export default function BookingExpenses(props) {
    const classes = useStyles();
    const { expenses, onAddKeyDates, removeKeyDates, loading, handleFieldChange, onExpenseValueChange, emptyValues } =
        props;

    useEffect(() => {
        console.log('expenses-->', expenses);
    }, []);

    return (
        <TableContainer component={Paper} className={classes.tableWrap}>
            <Table aria-label="spanning table">
                <TableHead></TableHead>
                <TableBody>
                    {expenses.map((row, index) => (
                        <>
                            {/* <TableRow>
                                <TableCell className={classes.titleWrap} variant="overline" component="div" sx={{ mb: '0.357rem' }}>ITEM</TableCell>
                                <TableCell className={classes.headingWrap} variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                                    QTY
                                </TableCell>
                                <TableCell className={classes.headingWrap} variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                                    RATE
                                </TableCell>
                                <TableCell className={classes.headingWrap} variant="overline" component="div" sx={{ mb: '0.357rem' }}>
                                    TOTAL
                                </TableCell>
                            </TableRow> */}
                            <BookingExpenseView
                                item={row}
                                remove={removeKeyDates}
                                onExpenseValueChange={onExpenseValueChange}
                                handleFieldChange={handleFieldChange}
                                index={index}
                                emptyValues={emptyValues}
                            />
                        </>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
