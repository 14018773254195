/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';

import {Button, Stack, Typography} from '@mui/material';
import {AUTH_ROUTES} from '../../constants/SiteRoutes';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {Link as RouterLink, useParams} from 'react-router-dom';
// import components
import Header from '../../components/Header';
import SnackBarWithTimer from '../../components/Common/SnackBarWithTimer';
import {fetchRecord} from "../../services/Axios";
import {AUTHENTICATION} from "../../constants/ApiEndPoint";

function VerifyEmail(props) {
  const theme = useTheme();
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = React.useState(false);
  const [resend, setResend] = React.useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState({});
  const [serverResponse, setServerMessage] = useState('');
  const {code} = useParams();

  async function getVerificationStatus() {
    const response = await fetchRecord(AUTHENTICATION.VERIFY_EMAIL, code);

    setServerMessage(response ? response.message : 'Invalid verification link. ');
    setStatus(response ? response.status : status);
  }

  useEffect(() => {
    getVerificationStatus().then((message) => console.log(message));
  }, []);

  return (
    <>
      {loading && (
        <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={loading}>
          <CircularProgress color="inherit"/>
        </Backdrop>
      )}
      {showSnackbar && (
        <SnackBarWithTimer
          open={showSnackbar}
          timer={6000}
          message={message}
          onClose={() => setShowSnackbar(false)}
        />
      )}
      <Header type="general"/>
      <Stack direction="column" spacing={3} bgcolor={theme.palette.background.paper} px={2} pt={2.5} pb={4}>
        <Typography variant="h3" component={'div'} textAlign="center">
          Email Verification
        </Typography>
        <Stack direction="column" spacing={2.6}>
          <Stack direction="column" spacing={0} textAlign="center">
            <Typography gutterBottom component="h1" variant="h6">
              {serverResponse}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={0.8}>
            {status === 200 &&
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                to={AUTH_ROUTES.LOGIN}
              >
                Login
              </Button>
            }
            {status === 401 &&
              <Button
                variant="contained"
                color="secondary"
                component={RouterLink}
                to={AUTH_ROUTES.RESEND_VERIFICATION_EMAIL}
              >
                Resend Verification Email
              </Button>
            }
            <></>

          </Stack>
        </Stack>
      </Stack>

    </>
  );
}

export default VerifyEmail;
