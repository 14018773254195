import { Chip, Divider, Grid, Stack, TextField, Typography } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
/* eslint-disable no-unused-vars */
import React from 'react';
// import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
// import Avatar from '@mui/material/Avatar';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router';
// import PreferredChip from './PreferredChip';
// import BookingStatusChip from './BookingList/BookingStatusChip';
// import RatesStatusChip from './RatesStatusChip';
import { useTheme } from '@mui/material/styles';

const FIRST_HOLD = '1st Holds';
const SECOND_HOLD = '2nd Holds';

function BookingDatesItem(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const { item } = props;

    const getDaysDiff = (start_date, end_date, date_format = 'YYYY-MM-DD') => {
        const getDateAsArray = (date) => {
            return moment(date.split(/\D+/), date_format);
        };
        return getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days') + 1;
    };

    const getFullHoldTypeName = (title) => {
        return `${title.substring(0, title.indexOf('('))} — ${title.substring(
            title.indexOf('(') + 1,
            title.indexOf(')'),
        )}`;
    };

    const getHoldTypeColor = (title) => {
        if (title.includes('Confirm')) {
            return 'success';
        } else if (title.includes('2nd')) {
            return 'info';
        } else {
            return 'warning';
        }
    };

    return (
        <>
            <Stack
                spacing={1}
                sx={{ mt: '0!important', mb: '0!important', backgroundColor: theme.palette.background.paper }}
            >
                <Grid container px={2.25} pt={0.5} pb={3} rowSpacing={0.5}>
                    <Grid item xs={12}>
                        {item.isFlexible ? (
                            <Typography
                                variant="h1"
                                sx={{ fontSize: '1.714rem', lineHeight: '2.287rem', textTransform: 'capitalize' }}
                            >
                                {item.taskTypeInfo?.title} — {item.bookingDateCount}&nbsp;
                                {item.bookingDateCountType}
                            </Typography>
                        ) : (
                            <Typography variant="h1" sx={{ fontSize: '1.714rem', lineHeight: '2.287rem' }}>
                                {item.taskTypeInfo?.title} —{' '}
                                {getDaysDiff(
                                    moment(item.startDate).format('YYYY-MM-DD'),
                                    moment(item.endDate).format('YYYY-MM-DD'),
                                )}
                                {getDaysDiff(
                                    moment(item.startDate).format('YYYY-MM-DD'),
                                    moment(item.endDate).format('YYYY-MM-DD'),
                                ) == 1
                                    ? ' Day'
                                    : ' Days'}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Chip
                            label={item.holdTypeInfo.title ? getFullHoldTypeName(item.holdTypeInfo.title) : ''}
                            color={item.holdTypeInfo.title ? getHoldTypeColor(item.holdTypeInfo.title) : 'default'}
                            sx={{ height: '20px!important', marginBottom: '3px' }}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="caption"
                            component="p"
                            sx={{ lineHeight: '1.143rem', marginBottom: '2px!important' }}
                        >
                            {item.holdTypeInfo.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {item.isFlexible ? (
                            <Typography
                                variant="overline"
                                sx={{
                                    color: '#141414',
                                    fontSize: '12px',
                                    marginBottom: '2px',
                                }}
                            >
                                FLEXIBLE BLOCK
                            </Typography>
                        ) : (
                            <Typography
                                variant="overline"
                                sx={{
                                    color: '#141414',
                                    fontSize: '12px',
                                }}
                            >
                                <span>{moment(item.startDate).format('ddd, Do MMMM')}</span> <span>—</span>{' '}
                                <span>{moment(item.endDate).format('ddd, Do MMMM')}</span>
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ margin: '0 0 13px!important' }} />
            </Stack>
        </>
    );
}

export default BookingDatesItem;
