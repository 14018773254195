import {makeStyles} from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import React, {useState} from 'react';

import BookingTimeForm from './BookingTimeForm';

const useStyles = makeStyles((theme) => ({
    // root: {
    //     width: '100%',
    //     maxWidth: 500,
    // },
    // cartHeader: {
    //     padding: theme.spacing(2, 0),
    // },
    // option: {
    //     display: 'flex',
    //     alignItems: 'flex-start',
    //     //padding: theme.spacing(2),
    //     '& + &': {
    //         marginTop: '8px',
    //     },
    // },
    // headingWrap: {
    //     color: '#6e6969 !important',
    //     fontSize: '12px !important',
    //     fontWeight: '400 !important',
    //     padding: '5px !important',
    // },
    // titleWrap: {
    //     padding: '16px 16px 16px 10px',
    //     color: '#6e6969 !important',
    //     fontSize: '12px !important',
    //     fontWeight: '400 !important',
    // },
    // tableWrap: {
    //     // height: '400px',
    //     overflowX: 'hidden !important',
    //     overflowY: 'hidden',
    //     '& .MuiTable-root': {
    //         '& .MuiTableRow-root': {
    //             display: 'grid',
    //             gridTemplateColumns: '15% 12% 10% 10% 10% 13% 7% 8% 15%',
    //             width: '100%',
    //         },
    //     },
    //     boxShadow: 'none !important',
    // },
}));

export default function BookingTime(props) {
    const classes = useStyles();
    const { loading, items } = props;

    const [tasks, setTasks] = useState([]);
    const hoursList = [
        { label: '8 hr day', value: '8' },
        { label: '10 hr day', value: '10' },
        { label: '12 hr day', value: '12' },
    ];
    const otRateList = [
        { label: '(@1.5x)', value: '1.5' },
        { label: '(@2.0x)', value: '2.0' },
    ];

    return (
        <>
            <TableContainer component={Paper} className={classes.tableWrap}>
                <Table aria-label="spanning table">
                    {/* <TableHead>
                        <TableRow>
                            <TableCell className={classes.titleWrap}>ITEM</TableCell>
                            <TableCell align="right" className={classes.headingWrap}>
                                DATE
                            </TableCell>
                            <TableCell align="center" className={classes.headingWrap} colSpan={2}>
                                RATE
                            </TableCell>
                            <TableCell className={classes.headingWrap}>PER</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" className={classes.headingWrap}>
                                HOURS
                            </TableCell>
                            <TableCell className={classes.headingWrap}>OT P/H RATE</TableCell>
                            <TableCell align="center" className={classes.headingWrap}>
                                OT
                            </TableCell>
                            <TableCell className={classes.headingWrap}>TAX</TableCell>
                            <TableCell className={classes.headingWrap}>TOTAL</TableCell>
                        </TableRow>
                    </TableHead> */}
                    <TableBody>{items && items.map((row, index) => <BookingTimeForm item={row} />)}</TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
