/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {useTheme} from '@mui/material/styles';
import {useNavigate} from 'react-router';

// import validate from 'validate.js';
import {useDispatch} from 'react-redux';
// import { makeStyles } from '@mui/styles';
import {Button, Link, Stack, Typography} from '@mui/material';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {AUTH_ROUTES, BOOKING_ROUTES} from '../../constants/SiteRoutes';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {login} from '../../services/Authorize';
import {Link as RouterLink} from 'react-router-dom';
// import components
import Header from '../../components/Header';
import SnackBarWithTimer from '../../components/Common/SnackBarWithTimer';
import {Mixpanel} from "../../services/MixPanelService";

function Login() {
    //const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [resend, setResend] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [message, setMessage] = useState({});

    async function handleSubmit(event) {
        const user = { email: email, password: password };
        setLoading(true);
        let response = await login(user);
        console.log('multiple choice-->', response);

        if (response) {
            if (response.status === 402) {
                setMessage({ type: 'error', message: response.message });
                setShowSnackbar(true);
                setResend(true);
                setLoading(false);
            } else if (response.status === 200) {
                if (response.data.multipleProfile) {
                    navigate(AUTH_ROUTES.ACCOUNT_CHOICE);
                } else {
                    Mixpanel.MobileLoginEvent.fire(response.data.userInfo);
                    window.location.href = BOOKING_ROUTES.INDEX;
                }
            } else {
                setMessage({ type: 'error', message: response.message });
                setShowSnackbar(true);
                setLoading(false);
            }
        }
        setLoading(false);
    }

    return (
        <>
            {loading && (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            {showSnackbar && (
                <SnackBarWithTimer
                    open={showSnackbar}
                    timer={6000}
                    message={message}
                    onClose={() => setShowSnackbar(false)}
                />
            )}
            <Header type="general" />

            <ValidatorForm onSubmit={handleSubmit}>
                <Stack direction="column" spacing={3} bgcolor={theme.palette.background.paper} px={2} pt={2.5} pb={4}>
                    <Typography variant="h3" component={'div'} textAlign="center">
                        Login to Crews
                    </Typography>
                    <Stack direction="column" spacing={2.6}>
                        <TextValidator
                            label="Your Email Address"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Your Email Address"
                            helperText=""
                            fullWidth
                            name="email"
                            type="text"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={email}
                            validators={['required', 'isEmail']}
                            errorMessages={['Email field is required.', 'Must be a valid Email.']}
                            variant="outlined"
                            autoComplete="email"
                            autoFocus
                            size="medium"
                        />
                        <TextValidator
                            label="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter Password"
                            helperText=""
                            fullWidth
                            name="password"
                            type="password"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={password}
                            validators={['required']}
                            errorMessages={['Password is required.']}
                            variant="outlined"
                            size="medium"
                        />
                        <Stack direction="column" spacing={0.8}>
                            <Button color="primary" size="large" type="submit" variant="contained" disabled={loading}>
                                Login
                            </Button>
                            {resend ? (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    component={RouterLink}
                                    to={AUTH_ROUTES.RESEND_VERIFICATION_EMAIL}
                                >
                                    Resend Verification Email
                                </Button>
                            ) : (
                                /* <Link href="/auth/resend-verification-email" variant="body2" color={"secondary"}>
                      Resend verification email
                      </Link>*/ ''
                            )}
                            <Stack direction="column" spacing={0} textAlign="center">
                                <Link to="/signup" component={RouterLink} variant="body2">
                                    Don’t have an account?
                                </Link>
                                <Link
                                  component={RouterLink}
                                  to={AUTH_ROUTES.FORGOT_PASSWORD}
                                  variant="body2">
                                    Forgot your password?
                                </Link>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </ValidatorForm>
        </>
    );
}

export default Login;
