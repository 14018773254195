import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import React, { useEffect } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@mui/material/styles';
import { PRODUCTION_TYPES_OBJECT } from 'src/constants';
import moment from 'moment';

function BookerAndCrew(props) {
    const { booking, production } = props;
    const { address, companyTitle, contactTitle, email, phoneNumber } = booking.bookerDetails;
    const { shootStartDate, shootEndDate, productionType = [] } = production;
    const type = PRODUCTION_TYPES_OBJECT[productionType[0]];
    const theme = useTheme();

    return (
        <Stack direction="column" spacing={3} px={0} pt={0}>
            <Card elevation={0} sx={{ p: '14px 16px 17px' }}>
                <CardContent sx={{ pb: '0!important' }}>
                    <Stack direction="column" columnSpacing={3} pt={0} pb={0}>
                        <Grid container rowSpacing={0.75} columnSpacing={1}>
                            <Grid item xs={6}>
                                <Typography variant="h4" sx={{ mb: '0.214rem' }}>
                                    Production Name
                                </Typography>
                                <Typography variant="body1">{production.title}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4" sx={{ mb: '0.214rem' }}>
                                    Client
                                </Typography>
                                <Typography variant="body1">
                                    {props.production.client ? props.production.client.title : ''}
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="h4" sx={{ mb: '0.214rem' }}>
                                    Production Start End
                                </Typography>
                                <Typography variant="body1">{moment(shootStartDate).format('YYYY-MM-DD')} to {moment(shootEndDate).format('YYYY-MM-DD')}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="h4" sx={{ mb: '0.214rem' }}>
                                    Agency
                                </Typography>
                                <Typography variant="body1">{production.agency || 'N/A'}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="h4" sx={{ mb: '0.214rem' }}>
                                    Location
                                </Typography>
                                <Typography variant="body1">{address}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="h4" sx={{ mb: '0.214rem' }}>
                                    Production Type
                                </Typography>
                                <Typography variant="body1">{type || 'N/A'}</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ mt: '1.107rem!important', mb: '.857rem!important' }} />
                        <Grid
                            container
                            rowSpacing={0.75}
                            columnSpacing={1}
                            sx={{ mt: '0!important', mb: '0!important' }}
                            px={0}
                        >
                            <Grid item xs={6}>
                                <Typography variant="h4" sx={{ mb: '0.214rem' }}>
                                    Production Company
                                </Typography>
                                <Typography variant="body1">{companyTitle}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4" sx={{ mb: '0.214rem' }}>
                                    Production Contact
                                </Typography>
                                <Typography variant="body1">{contactTitle}</Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="h4" sx={{ mb: '0.214rem' }}>
                                    Phone Number
                                </Typography>
                                <Typography variant="body1">{phoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h4" sx={{ mb: '0.214rem' }}>
                                    Email Address
                                </Typography>
                                <Typography variant="body1">{email}</Typography>
                            </Grid>
                        </Grid>

                        {booking && booking.userKeyMembersInfo.length > 0 && (
                            <>
                                <Divider sx={{ mt: '1.143rem!important', mb: '1.286rem!important' }} />
                                <Stack direction="column" spacing={2} px={0}>
                                    <Typography variant="h4" sx={{ mb: '0.071rem' }}>
                                        Key Crew Members
                                    </Typography>
                                    <Stack direction="column" spacing={0.625} pb="0.5rem">
                                        {booking.userKeyMembersInfo.map((item, index) => (
                                            <Stack direction="row" spacing={2} key={new Date() + index}>
                                                <Avatar
                                                    sx={{
                                                        w: '40px',
                                                        h: '40px',
                                                    }}
                                                    src={item.photoUri}
                                                />
                                                <Box>
                                                    <Typography variant="h4" sx={{ mb: '0.214rem', lineHeight: '1.1' }}>
                                                        {item.name}
                                                    </Typography>
                                                    <Typography variant="body1">{item.role}</Typography>
                                                </Box>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                            </>
                        )}
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    );
}

export default BookerAndCrew;
