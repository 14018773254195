import {
    Backdrop,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CircularProgress,
    List,
    ListItem,
    Stack,
    Typography,
    colors,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import BookerAndCrew from './BookerAndCrew';
import { ReactComponent as DownloadIcon } from '../../assets/images/icons/navigation-down-circle.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { PRODUCTIONS } from 'src/constants/ApiEndPoint';
import bytesToSize from 'src/utils/bytesToSize';
import clsx from 'clsx';
import { download } from '../../services/Axios';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    // root: {
    //     boxShadow: 'none',
    //     height: '100%',
    //     display: 'flex',
    //     flexWrap: 'wrap',
    //     position: 'relative',
    //     paddingBottom: 77,
    //     width: '100%',
    // },
    // contentWrapper: {
    //     width: '100%',
    // },
    // media: {
    //     height: 240,
    //     width: '100%',
    // },
    // placeholder: {
    //     height: 240,
    //     backgroundColor: colors.blueGrey[50],
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    // },
    // insertDriveFileIcon: {
    //     height: theme.spacing(6),
    //     width: theme.spacing(6),
    //     fontSize: theme.spacing(6),
    // },
    // content: {
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     flexWrap: 'wrap',
    //     paddingRight: 0,
    //     width: '100%',
    // },
    // actionsWrapper: {
    //     position: 'absolute',
    //     left: 0,
    //     bottom: 0,
    //     width: '100%',
    // },
    // downloadIcon: {
    //     color: theme.palette.common.white,
    //     fontSize: 22,
    //     marginRight: theme.spacing(1),
    // },
    // fileName: {
    //     width: 'calc(100% - 50px)',
    //     wordBreak: 'break-all',
    //     padding: '24px 16px',
    // },
}));

function BookingDescriptions({ booking, production }) {
    const [loading, setLoading] = useState(false);
    const { attachments, productionSchedule } = production;
    const classes = useStyles();

    const theme = useTheme();

    const downloadFile = async (file, fileName) => {
        setLoading(true);

        const response = await download(PRODUCTIONS.DOWNLOAD_ATTACHMENT, {
            fileName: file.file.name,
            uri: file.fileUri,
        });
        setLoading(false);
    };

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            {loading && (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="subtitle2">Production Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {production && <BookerAndCrew production={production} booking={booking} />}
                </AccordionDetails>
            </Accordion>
            {/* <Accordion expanded={true}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant="subtitle2">Agency</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {production && <Typography style={{ padding: '12px' }}>{production.agency || 'N/A'}</Typography>}
                </AccordionDetails>
            </Accordion> */}
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography variant="subtitle2">Description</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {booking?.allowToViewOverview && (
                        <Box
                            sx={{
                                marginTop: '0!important',
                                padding: '1rem 16px 22px',
                                fontSize: '1rem',
                                lineHeight: '1.714',
                            }}
                            dangerouslySetInnerHTML={{
                                __html: production.description || '',
                            }}
                        />
                    )}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                    <Typography variant="subtitle2">Production Schedule</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List sx={{ p: '0 0 39px!important' }}>
                        {productionSchedule &&
                            productionSchedule.map((schedule) => {
                                return (
                                    <ListItem
                                        disablePadding
                                        sx={{ flexDirection: 'column', alignItems: 'flex-start', gap: '1px' }}
                                    >
                                        <Typography variant="body2">
                                            {schedule.description} / {schedule.dateTime}
                                        </Typography>
                                        <Typography variant="subtitle1">{schedule.address}</Typography>
                                    </ListItem>
                                );
                            })}
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
                    <Typography variant="subtitle2">Attachments</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {attachments.length > 0 && (
                        <Stack
                            direction="column"
                            gap={3}
                            bgcolor={theme.palette.background.paper}
                            px={2}
                            pt={2.5}
                            pb={4}
                            mt={1}
                        >
                            {attachments &&
                                attachments.map((file) => (
                                    <Card variant="outlined" sx={{ borderRadius: '6px' }}>
                                        <>
                                            {file.file.type && file.file.type.includes('image/') ? (
                                                <CardMedia image={file.fileUri} sx={{ height: '198px' }} />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        height: '198px',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        backgroundColor: '#eee',
                                                    }}
                                                >
                                                    <InsertDriveFileIcon className={classes.insertDriveFileIcon} />
                                                </Box>
                                            )}
                                        </>
                                        <CardContent>
                                            <Box sx={{ p: '23px 35px 20px' }}>
                                                <Typography variant="h6">{file.file.name}</Typography>
                                                <Typography variant="caption">{bytesToSize(file.file.size)}</Typography>
                                            </Box>
                                        </CardContent>
                                        <CardActions sx={{ justifyContent: 'center', p: '19px 35px' }}>
                                            <Button
                                                onClick={(event) => downloadFile(file)}
                                                variant="contained"
                                                size="large"
                                                color="grey"
                                                className="buttonRounded"
                                                // sx={{
                                                //     backgroundColor: '#999999',
                                                //     color: 'white',
                                                //     borderRadius: '50px',
                                                //     paddingBottom: '9px',
                                                //     textTransform: 'none',
                                                //     paddingTop: '7px',
                                                //     lineHeight: 1.25,
                                                //     marginBottom: '7px',
                                                // }}
                                                startIcon={<DownloadIcon />}
                                            >
                                                Download
                                            </Button>
                                        </CardActions>
                                    </Card>
                                ))}
                        </Stack>
                    )}
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default BookingDescriptions;
